import Axios from "axios";

const getDefaultState = () => {
  return {
    token: "",
    user: ""
  };
};
export default {
  state: getDefaultState(),
  getters: {
    isLoggedIn: state => {
      return state.token;
    },
    getUser: state => {
      return state.user;
    }
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
      localStorage.setItem("Auth", token);
      Axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    },
    SET_USER: (state, user) => {
      const id = user.id;
      state.user = id;
      localStorage.setItem("user", id);
      var t = JSON.stringify(user);
      localStorage.setItem("credentials", t);
    },

    RESET: state => {
      Object.assign(state, getDefaultState());
    },
    MAIN_RESET: state => {
      state.users = "";
    }
  },
  actions: {
    login: ({ commit }, { token, user }) => {
      setTimeout(() => {
        commit("SET_TOKEN", token);
        commit("SET_USER", user);
      }, 500);
    },
    logout: ({ commit }) => {
      commit("RESET", "");
      commit("MAIN_RESET");
      localStorage.removeItem("Auth");
      localStorage.removeItem("credentials");
      localStorage.removeItem("user");
    }
  }
};
