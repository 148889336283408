<template>
  <div
    @contextmenu="handler($event)"
    v-observe-visibility="visible"
    class="psr fy-width_100p fy-height_100p"
  >
    <video
      @ended="_ended"
      :id="id"
      :src="link"
      class="fy-chat-media_width"
    ></video>
    <button
      @click="play_gif"
      v-if="play === false"
      class="psa   fy-border-50 fy-width_50px fy-height_50px fy-beep"
      style="top:calc(50% - 25px); left:calc(50% - 25px)"
    >
      <span
        class="fy-font-20 fy-text-4 fy_font_w600"
        style="font-family:monospace"
        >GIF</span
      >
    </button>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: "",
      play: false,
      count: 1
    };
  },
  props: {
    link: {
      type: String,
      default() {
        return {};
      }
    }
  },
  created() {
    this.id = Math.random();
  },
  mounted() {
    //const _video = document.getElementById(this.id);
  },
  methods: {
    handler: function(e) {
      e.preventDefault();
    },
    _ended() {
      if (this.count < 5) {
        this.play_gif();
        this.count = this.count + 1;
      } else {
        this.play = false;
        this.count = 0;
      }
    },
    play_gif() {
      const video = document.getElementById(this.id);
      video.play();
      this.play = true;
    },
    pause_gif() {
      const video = document.getElementById(this.id);
      video.pause();
      this.play = false;
    },
    visible(isVisible) {
      if (isVisible === false && this.play === true) {
        this.pause_gif();
        this.count = 0;
      }
    }
  }
};
</script>
