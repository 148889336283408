import Vue from "vue";
import VueMeta from "vue-meta";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import Axios from "axios";

import { vueRipple } from "@/js/ripple";
import { breakless, editor, fyText } from "@/js/breakless";
//import * as io from "socket.io-client";

import { obConstruction } from "@/js/searching.js";
import { ObserveVisibility } from "vue-observe-visibility";
import VueInsProgressBar from "vue-ins-progress-bar";
import contenteditableDirective from "vue-contenteditable-directive";
import fyvideo from "@/slots/video";
import flowerLoading from "@/components/loading/flower";
import simplelogo from "@/slots/simplelogo";
import webmeta from "@/components/webmeta/index.vue";
import logo from "@/slots/logo";
import addressShow from "@/slots/addressShow";
import logodark from "@/slots/logodark";
import btnForm from "@/slots/btnform";
import writing from "@/slots/writing";
import circleloading from "@/slots/circleloading";
import dotLoading from "@/slots/dotloading";
import resultLabel from "@/components/nothing/resultLabel";
import specialtext from "@/slots/specialtext";
import icons from "@/slots/icons";
import tenor_gif from "@/slots/tenor_gif";
import grewtale_image from "@/slots/image";
import grewtale from "@/slots/grewtale/index";
import Croppa from "vue-croppa";
import AudioVisual from "vue-audio-visual";
import VEmojiPicker from "v-emoji-picker";
import { _console } from "@/js/console";
//import vue3GoogleLogin from 'vue3-google-login'

//import Resetter from 'vue-reset-data';
if (store.getters.GETscreen === "desktop") {
  var h = "2px";
} else {
  h = "4px";
}
const options = {
  position: "fixed",
  show: true,
  height: h
};

const shared = store.getters.getReqLength;
shared.install = function() {
  Object.defineProperty(Vue.prototype, "reqItemLength", {
    get() {
      return shared;
    }
  });
};
Vue.use(shared);
Vue.use(AudioVisual);
Vue.use(VueInsProgressBar, options);
Vue.use(contenteditableDirective);
Vue.config.productionTip = false;
Vue.use(VueMeta);
Vue.use(obConstruction);

Vue.use(Croppa);
Vue.use(VEmojiPicker);

Vue.component("fyvideo", fyvideo);

Vue.component("flowerLoading", flowerLoading);
Vue.component("simplelogo", simplelogo);
Vue.component("logo", logo);
Vue.component("addressShow", addressShow);
Vue.component("logodark", logodark);
Vue.component("writing", writing);
Vue.component("icons", icons);
Vue.component("btnForm", btnForm);
Vue.component("circleloading", circleloading);
Vue.component("dotLoading", dotLoading);
Vue.component("specialtext", specialtext);
Vue.component("grewtale_image", grewtale_image);
Vue.component("grewtale", grewtale);
Vue.component("webmetaex", webmeta);
Vue.component("tenor_gif", tenor_gif);
Vue.component("resultLabel", resultLabel);
Vue.directive("observe-visibility", ObserveVisibility);

Vue.directive("ripple", vueRipple);
Vue.directive("breakless", breakless);
Vue.directive("editor", editor);
Vue.directive("fy-text", fyText);
Vue.mixin({
  methods: {
    console(g) {
      _console(g);
    }
  }
});
const y = localStorage.getItem("Auth");
var u = "";
if (y !== undefined) {
  u = y;
}
Axios.defaults.headers.common["Authorization"] = `Bearer ${u}`;
//console.log(socket);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#main_mount");

//...
