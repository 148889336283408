<template
  ><svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    style="margin: auto;  display: block; shape-rendering: auto;"
    :width="`${dimension}px`"
    :height="`${dimension}px`"
    viewBox="0 0 100 100"
    :fill="`${color}`"
    preserveAspectRatio="xMidYMid"
  >
    <g transform="rotate(0 50 50)">
      <rect x="46.5" y="20.5" rx="3.5" ry="9.120000000000001">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.0204081632653061s"
          begin="-0.9070294784580498s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(40 50 50)">
      <rect x="46.5" y="20.5" rx="3.5" ry="9.120000000000001">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.0204081632653061s"
          begin="-0.7936507936507936s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(80 50 50)">
      <rect x="46.5" y="20.5" rx="3.5" ry="9.120000000000001">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.0204081632653061s"
          begin="-0.6802721088435374s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(120 50 50)">
      <rect x="46.5" y="20.5" rx="3.5" ry="9.120000000000001">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.0204081632653061s"
          begin="-0.5668934240362812s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(160 50 50)">
      <rect x="46.5" y="20.5" rx="3.5" ry="9.120000000000001">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.0204081632653061s"
          begin="-0.4535147392290249s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(200 50 50)">
      <rect x="46.5" y="20.5" rx="3.5" ry="9.120000000000001">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.0204081632653061s"
          begin="-0.3401360544217687s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(240 50 50)">
      <rect x="46.5" y="20.5" rx="3.5" ry="9.120000000000001">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.0204081632653061s"
          begin="-0.22675736961451246s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(280 50 50)">
      <rect x="46.5" y="20.5" rx="3.5" ry="9.120000000000001">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.0204081632653061s"
          begin="-0.11337868480725623s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(320 50 50)">
      <rect x="46.5" y="20.5" rx="3.5" ry="9.120000000000001">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.0204081632653061s"
          begin="0s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <!-- [ldio] generated by https://loading.io/ -->
  </svg>
</template>
<style scoped>
rect {
  width: 6px;
  height: 18px;
}
</style>
<script>
export default {
  props: {
    dimension: {
      type: String,
      default() {
        return {};
      }
    },
    color: {
      type: String,
      default() {
        return {};
      }
    }
  }
};
</script>
