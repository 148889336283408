import config from "@/api.js";
import Axios from "axios";
import {
  grewtaleDataFormation,
  grewtaleMeasureAction,
  grewtaleContentSet
} from "@/js/grewtale.js";
const getDefaultState = () => {
  return {
    username: "",
    user: null,
    loading: true,
    error: false,
    notuser: false,
    subject: [],
    subjectnm: false,
    grewtales: [],
    grewtalesNext: null,
    grewtalelength: 0,
    plotter_msg: null,
    file_show: null,
    file_window: false,
    usergrewn: false,
    erroruser: false,
    u1: false,
    u2: false,
    bug: ""
  };
};
const user = {
  state: getDefaultState(),
  mutations: {
    SET_User(state, data) {
      if (data === "not") {
        state.notuser = true;
      } else if (data === "Error") {
        state.erroruser = true;
      } else {
        state.user = data;
      }
    },
    SET_Error(state, data) {
      state.error = data;
    },
    SET_l(state) {
      state.loading = false;
    },
    SET_subject(state, data) {
      if (data == "nothing") {
        state.subjectnm = true;
      } else {
        for (var i = 0; i < data.length; i++) {
          var id = data[i].id;
          var name = data[i].name;
          var meaning = data[i].meaning;
          var type = data[i].type;
          var file = data[i].file;
          var file_type = data[i].file_type;
          var username = data[i].username;
          state.subject.push({
            id: id,
            name: name,
            meaning: meaning,
            type: type,
            file: file,
            file_type: file_type,
            username: username
          });
        }
      }
    },

    // grewtale

    GET_uGrewtales(state, data) {
      if (data.data === "nothing") {
        state.usergrewn = true;
      } else {
        state.grewtalelength = state.grewtalelength + data.data.length;
        if (data.next === null) state.usergrewn = true;
        state.grewtalesNext = data.next;
        data = data.data;
        for (var i = 0; i < data.length; i++) {
          var obj = grewtaleDataFormation(data[i]);
          state.grewtales.push(obj);
        }
      }
    },
    GET_Grewtalesu1(state, data) {
      var local = window.localStorage.user;
      var actual = JSON.parse(local);

      if (state.user === null) {
        state.bug = true;
      } else if (state.user.user_id == actual) {
        state.grewtalelength = state.grewtalelength + data.length;
        for (var i = 0; i < data.length; i++) {
          var obj = grewtaleDataFormation(data[i].data);
          state.grewtales.unshift(obj);
        }
      }
    },
    SET_Plotters_inside(state, { id, count, process }) {
      grewtaleMeasureAction(state, id, count, "grewtales", process);
    },
    setGrewtaleContent(state, data) {
      grewtaleContentSet(state, data, "grewtales");
    },

    SET_Plotters(state, data) {
      state.plotter_msg = data;
    },
    image_ushow(state, { data }) {
      state.file_show = data;
      state.file_window = true;
    },
    file_uwindow_dismiss(state) {
      state.file_window = false;
      state.file_show = null;
    },

    // end
    user_reset(state) {
      state.loading = true;
      state.user = null;
      state.notuser = false;
      state.subject = [];
      state.grewtales = [];
      state.grewtalesNext = null;
      state.usergrewn = false;
      state.grewtalelength = 0;
      state.erroruser = false;
    },
    SETuser_relation_peg(state, data) {
      state.user.relation.relation_status = data;
    },
    SETuser_relation_redux(state, data) {
      (state.user.relation.accept_at = data), (state.user.relation.id = data);
      state.user.relation.notification_seen = data;
      state.user.relation.notification_status = data;
      state.user.relation.propose_at = data;
      state.user.relation.relation_value = data;
      state.user.relation.status = data;
      state.user.relation.user_1 = data;
      state.user.relation.user_2 = data;
    },
    Setfile_active2(state, _data) {
      state.grewtales[_data.dynamic].file_active = true;
    },

    SETusgredel(state, data) {
      for (var i = 0; i < state.grewtales.length; i++) {
        if (state.grewtales[i].id === data) {
          state.grewtales.splice(i, 1);
          break;
        }
      }
    },
    SETusername(state, data) {
      state.username = data;
    },
    su1(state, data) {
      state.u1 = data;
    },
    su2(state, data) {
      state.u2 = data;
    },
    RESET: state => {
      Object.assign(state, getDefaultState());
    }
  },
  actions: {
    loaduser({ state, commit }, { username }) {
      commit("SETusername", { username });
      if (state.user == null) {
        Axios.post(`${config.publicPath}` + "profile", { username })
          .then(response => {
            commit("SET_User", response.data);
          })
          .catch(error => console.log(error))
          .finally(() => {
            commit("SET_l");
          });
      }
    },
    LOADusersubject({ commit, state }, { username }) {
      if (state.u2 === false) {
        commit("su2", true);
        Axios.post(`${config.publicPath}` + "get/subject", { username })
          .then(response => {
            commit("SET_subject", response.data);
          })
          .catch(error => console.log(error))
          .finally(() => {
            commit("su2", false);
          });
      }
    },

    loaduserGrewtales({ commit, state }, { items, username }) {
      if (state.u1 === false) {
        commit("su1", true);
        var length = 8,
          next = state.grewtalesNext;
        Axios.post(`${config.publicPath}` + "/user/grewtale", {
          items,
          username,
          length,
          next
        })
          .then(response => {
            commit("GET_uGrewtales", response.data);
          })
          .catch(error => console.log(error))
          .finally(() => {
            commit("su1", false);
          });
      }
    },

    userplotters({ commit }, { index, id, count, active }) {
      Axios.post(`${config.publicPath}` + "plotter/inside", {
        index,
        id,
        count,
        active
      })
        .then(response => {
          commit("SET_Plotters", response.data);
          commit("SETmessage", response.data.msg);
          commit("SET_userPlotters_inside", { index, id, count });
          setTimeout(() => {
            commit("SET_Plotters", "");
            commit("SETmessage", "");
          }, 1500);
        })
        .catch(error => console.log(error));
    },
    delete_userplotters({ commit }, { index, id, count, active }) {
      Axios.post(`${config.publicPath}` + "plotter/remove", {
        index,
        id,
        count,
        active
      })
        .then(response => {
          commit("SET_Plotters", response.data);
          commit("SETmessage", response.data.msg);
          commit("SET_userPlotters_insideout", { index, id, count });
          setTimeout(() => {
            commit("SET_Plotters", "");
            commit("SETmessage", "");
          }, 1500);
        })
        .catch(error => console.log(error));
    },
    SETgsplice({ commit }, { index }) {
      commit("setagaingsplice", { index });
    }
  },
  getters: {
    getuser: state => {
      return state.user;
    },
    getuserloading: state => {
      return state.loading;
    },
    getusernot: state => {
      return state.notuser;
    },
    getusererror: state => {
      return state.erroruser;
    },
    getusersubject: state => {
      return state.subject;
    },
    getusergrewtale: state => {
      return state.grewtales;
    },
    getusergrewtalelength: state => {
      return state.grewtalelength;
    },
    getuserplottermsg: state => {
      return state.plotter_msg;
    },
    getusergrewn: state => {
      return state.usergrewn;
    },
    getfile_ushow: state => {
      return state.file_show;
    },
    getfile_uwindow: state => {
      return state.file_window;
    },
    getpusername: state => {
      return state.username;
    }
  }
};
export default user;
