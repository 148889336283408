import config from "@/api.js";
import Axios from "axios";
import { lifelineAction } from "@/js/grewtale.js";
const getDefaultState = () => {
  return {
    past: [],
    pastNext: null,
    present: [],
    presentNext: null,
    future: [],
    futureNext: null,
    subject: [],
    subjectNext: null,
    msg: "",
    pastnm: false,
    presentnm: false,
    futurenm: false,
    n_request: false,
    n_accept: false,
    connectorNothing: false,
    connectors: [],
    connectorNext: null,
    LifelinePlotterLoading: false,
    username: "",
    msg_p_b: "",
    ua1: false,
    ua2: false,
    ua3: false,
    ua4: false
  };
};
const user_assets = {
  state: getDefaultState(),
  mutations: {
    SET_msg(state, data) {
      state.msg = data;
    },
    SET_past(state, data) {
      if (data == "nothing") {
        state.pastnm = true;
      } else {
        if (data.next === null) state.pastnm = true;
        state.pastNext = data.next;
        data = data.data;
        for (var i = 0; i < data.length; i++) {
          state.past.push({
            id: data[i].id,
            name: data[i].name,
            user_id: data[i].user_id,
            button: data[i].button,
            active: data[i].active,
            type: data[i].type,
            file: data[i].file,
            file_type: data[i].file,
            username: data[i].username,
            created_at: data[i].created_at
          });
        }
      }
    },
    SET_present(state, data) {
      if (data == "nothing") {
        state.presentnm = true;
      } else {
        if (data.next === null) state.presentnm = true;
        state.presentNext = data.next;
        data = data.data;
        for (var i = 0; i < data.length; i++) {
          state.present.push({
            id: data[i].id,
            name: data[i].name,
            user_id: data[i].user_id,
            button: data[i].button,
            active: data[i].active,
            type: data[i].type,
            file: data[i].file,
            file_type: data[i].file,
            username: data[i].username,
            created_at: data[i].created_at
          });
        }
      }
    },
    SET_future(state, data) {
      if (data == "nothing") {
        state.futurenm = true;
      } else {
        if (data.next === null) state.futurenm = true;
        state.futureNext = data.next;
        data = data.data;
        for (var i = 0; i < data.length; i++) {
          state.future.push({
            id: data[i].id,
            name: data[i].name,
            user_id: data[i].user_id,
            button: data[i].button,
            active: data[i].active,
            type: data[i].type,
            file: data[i].file,
            file_type: data[i].file,
            username: data[i].username,
            created_at: data[i].created_at
          });
        }
      }
    },

    resetuserpast(state, data) {
      var username = data.username;

      if (state.past != []) {
        if (username !== state.past[0].username) {
          state.past = [];
        }
      }
    },
    resetuserpresent(state, data) {
      var username = data.username;
      if (state.present != []) {
        if (username !== state.present[0].username) {
          state.present = [];
        }
      }
    },
    resetuserfuture(state, data) {
      var username = data.username;
      if (state.future != []) {
        if (username !== state.future[0].username) {
          state.future = [];
        }
      }
    },

    //connectors

    SET_uconnectors(state, data) {
      state.username = data.username;
      if (data === "nothing" || data === "") {
        state.connectorNothing = true;
      } else {
        if (data.next === null) state.connectorNothing = true;
        state.connectorNext = data.next;
        data = data.data;

        for (let i = 0; i < data.length; i++) {
          state.connectors.push({
            first_name: data[i].first_name,
            last_name: data[i].last_name,
            profilepic: data[i].profilepic,
            id: data[i].id,
            relation_value: data[i].relation_value,
            username: data[i].username
          });
        }
      }
    },
    resetuserconnectors(state, data) {
      var username = data.username;
      if (state.connectors != []) {
        if (username !== state.username) {
          state.connectors = [];
          state.connectorNext = null;
          state.connectorNothing = false;
        }
      }
    },
    DeleteLifeline(state, data) {
      state[data.type].splice(data.index, 1);
    },

    SET_lifeline_b_on(state, data) {
      lifelineAction(data, state);
    },
    SET_lifeline_b_off(state, data) {
      lifelineAction(data, state);
    },

    SET_plt_b_msg(state, data) {
      state.msg_p_b = data;
    },
    sua1(state, data) {
      state.ua1 = data;
    },
    sua2(state, data) {
      state.ua2 = data;
    },
    sua3(state, data) {
      state.ua3 = data;
    },
    sua4(state, data) {
      state.ua4 = data;
    },
    RESET: state => {
      Object.assign(state, getDefaultState());
    }
  },
  actions: {
    LOADuserpast({ commit, state }, { length, username }) {
      if (state.ua1 === false) {
        commit("sua1", true);
        var next = state.pastNext;
        length = 8;
        Axios.post(`${config.publicPath}` + "get/past", {
          length,
          username,
          next
        })
          .then(response => {
            commit("SET_past", response.data);

            commit("sua1", false);
          })
          .catch(error => console.log(error))
          .finally(() => {
            commit("sua1", false);
          });
      }
    },
    LOADuserpresent({ commit, state }, { length, username }) {
      if (state.ua2 === false) {
        commit("sua2", true);
        var next = state.presentNext;
        length = 8;
        Axios.post(`${config.publicPath}` + "get/present", {
          length,
          username,
          next
        })
          .then(response => {
            commit("SET_present", response.data);
          })
          .catch(error => console.log(error))
          .finally(() => {
            commit("sua2", false);
          });
      }
    },
    LOADuserfuture({ commit, state }, { length, username }) {
      if (state.ua3 === false) {
        commit("sua3", true);
        var next = state.futureNext;
        length = 8;
        Axios.post(`${config.publicPath}` + "get/future", {
          length,
          username,
          next
        })
          .then(response => {
            commit("SET_future", response.data);
          })
          .catch(error => console.log(error))
          .finally(() => {
            commit("sua3", false);
          });
      }
    },
    LOADuserconnectors({ commit, state }, { id }) {
      if (state.ua4 === false) {
        commit("sua4", true);
        var length = 10,
          next = state.connectorNext;

        Axios.post(`${config.publicPath}` + "user/connectors", {
          id,
          length,
          next
        })
          .then(response => {
            commit("SET_uconnectors", response.data);
          })
          .catch(error => console.log(error))
          .finally(() => {
            commit("sua4", false);
          });
      }
    },
    lifeline_button_on(
      { commit, state },
      { index, user_id, id, button, active, plotter_id, type }
    ) {
      state.LifelinePlotterLoading = true;
      Axios.post(`${config.publicPath}` + "past/plotter/inside", {
        user_id,
        id,
        button,
        active,
        plotter_id,
        type
      })
        .then(response => {
          if (
            response.data.msg === "Updated" ||
            response.data.msg === "Inserted"
          ) {
            var plotterValueId = response.data.plotterValueId;
            commit("SET_lifeline_b_on", {
              index,
              user_id,
              id,
              button,
              active,
              plotterValueId,
              type
            });
            state.LifelinePlotterLoading = false;
          }
        })
        .catch(error => console.log(error));
    },
    lifeline_button_off(
      { commit, state },
      { index, user_id, id, button, active, plotter_id, type }
    ) {
      state.LifelinePlotterLoading = true;
      Axios.post(`${config.publicPath}` + "past/plotter/remove", {
        user_id,
        id,
        button,
        active,
        plotter_id,
        type
      })
        .then(response => {
          if (response.data.msg === "Deleted") {
            var plotterValueId = response.data.plotterValueId;
            commit("SET_lifeline_b_off", {
              index,
              user_id,
              id,
              button,
              active,
              plotterValueId,
              type
            });
          }
          state.LifelinePlotterLoading = false;
        })
        .catch(error => console.log(error));
    },

    SETprsplice({ commit }, { index }) {
      commit("setagainprsplice", { index });
    },
    SETpasplice({ commit }, { index }) {
      commit("setagainpasplice", { index });
    },
    SETfusplice({ commit }, { index }) {
      commit("setagainfusplice", { index });
    }
  },
  getters: {
    getuserpast: state => {
      return state.past;
    },
    getuserpresent: state => {
      return state.present;
    },
    getuserfuture: state => {
      return state.future;
    },

    getuserpastnm: state => {
      return state.pastnm;
    },
    getuserpresentnm: state => {
      return state.presentnm;
    },
    getuserfuturenm: state => {
      return state.futurenm;
    },
    getuserconnectors: state => {
      return state.connectors;
    },
    getuserconnectorNothing: state => {
      return state.connectorNothing;
    },

    getLifelineplottermsg: state => {
      return state.msg_p_b;
    },
    getLifelinePlotterLoading: state => {
      return state.LifelinePlotterLoading;
    }
  }
};
export default user_assets;
