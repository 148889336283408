//import { turn } from "core-js/core/array";
//deleteContentBackward
//insertText

function free(a, b) {
  //  console.log(b, a.inputType)
  if (
    b.children[0] === undefined &&
    (a.inputType === "deleteContentBackward" ||
      a.inputType === "deleteContentForward")
  ) {
    setChild(b);
  } else {
    var c = b.children[0].children[0].children[0];

    c.setAttribute("dir", "ltr");
    if (c.innerText.length === 1 && a.inputType === "insertText") {
      const newNode = document.createElement("div");
      var __nominy = c.childNodes,
        nominy = __nominy[__nominy.length - 1].data;
      const textNode = document.createTextNode(nominy);
      newNode.appendChild(textNode);
      var n = __nominy[0];

      c.insertBefore(newNode, n);
      c.removeChild(__nominy[1]);
    }
  }
}
function setChild(a) {
  var html =
    "<div class='fy-margin-0' data-editor='true' ><div class='fy-margin_0' data-offset='true' data-key='09.67.04'><p class='fy-right_0 fy-top_0 fy-fit_height fy-top_0 fy-right_0 fy-width_100p tal fy-margin-0' ><br>";
  a.innerHTML = html;
}
function set2(a, b) {
  // var v = bi.value;
  var expes = /(<br>+)/g;
  var t = b.replace(expes, "&nbsp;");

  a.innerHTML = t;
}

const breakless = {
  bind: function(el, bi) {
    set2(el, bi.value);
  },
  unbind: function(el, bi) {
    set2(el, bi.value);
  },
  update: function(el, bi) {
    set2(el, bi.value);
  }
};

const editor = {
  bind: function(el, bi, vn) {
    el.contentEditable = true;
    el.setAttribute("data-fyish-editor", "true");
    setChild(el);
    el.addEventListener("input", function(e) {
      free(e, el);
      //    console.log(bi.modifiers.text);
      if (bi.modifiers.text === true) vn.context[bi.arg] = el.innerText;
      else
        vn.context[bi.arg] = el.children[0].children[0].children[0].innerHTML;
    });
  },
  componentUpdated: function(el, bi, vn) {
    var u = vn.context[bi.arg];
    if (u === "") {
      setChild(el);
    } else {
      //  console.log(u, el.innerText, bi , vn);
      //   if (u !== el.innerText)
      // el.children[0].children[0].children[0].innerText = u;
      var _t = el.innerText,
        holder = el.children[0].children[0].children[0];
      if (u.length !== _t.length) {
        console.log(JSON.stringify(_t));

        var t = u.split("\n\n");
        console.log(t);
        if (t === null) {
          holder.innerHTML = `<div>${u}</div>`;
        } else {
          for (let x in t) {
            if (t[x] === "") {
              var f = "<br>";
            } else {
              f = t[x];
            }
            var html = `<div>${f}</div>`;
            holder.innerHTML = html;
          }
        }
      } //else if(u.length > _t.length && _t.length === 0){
      //  u
      // }
    }
    el.focus();
  }
};

export { breakless, editor };
