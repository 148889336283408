import store from "@/store";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    component: () =>
      import(
        /* webpackChunkName:  "/aW1wb3J0cw/registration" */ "../registration/signin.vue"
      ),

    children: [
      {
        name: "Login",
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "/aW1wb3J0cw/signup" */ "../registration/signin4.vue"
          )
      },
      {
        name: "Signin",
        path: "/signup",
        component: () =>
          import(
            /* webpackChunkName: "/aW1wb3J0cw/signin" */ "../registration/signin2.vue"
          )
      }
    ]
  },

  {
    path: "*",
    redirect: "/"
  },
  {
    path: "/blocked",
    component: () =>
      import(
        /* webpackChunkName: "/aW1wb3J0cw/blocked" */ "../components/error/blocked.vue"
      )
  },

  {
    path: "/",

    component: () =>
      import(
        /* webpackChunkName: "/aW1wb3J0cw/masterbar" */ "../views/master.vue"
      ),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "/home",
        component: () =>
          import(
            /* webpackChunkName: "/aW1wb3J0cw/Grewtale/just" */ "../views/grewtalejust.vue"
          )
      },
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "/aW1wb3J0cw/rightsidebar" */ "../views/rightsidebar.vue"
          ),

        children: [
          {
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "/aW1wb3J0cw/home" */ "../views/Home.vue"
              )
          },
          {
            path: "/single",
            component: () =>
              import(
                /* webpackChunkName: "/aW1wb3J0cw/single" */ "../views/single.vue"
              )
          },

          {
            path: "/search/",
            component: () =>
              import(
                /* webpackChunkName: "/aW1wb3J0cw/explore" */ "../views/explore/main.vue"
              ),

            children: [
              {
                name: "Search",
                path: "",
                component: () =>
                  import(
                    /* webpackChunkName: "/aW1wb3J0cw/all" */ "../views/explore/inner/all.vue"
                  ),
                props: route => ({ query: route.query.q })
              },
              {
                path: "past",
                component: () =>
                  import(
                    /* webpackChunkName: "/aW1wb3J0cw/past" */ "../views/explore/inner/past.vue"
                  ),
                props: route => ({ query: route.query.q })
              },
              {
                path: "present",
                component: () =>
                  import(
                    /* webpackChunkName: "/aW1wb3J0cw/present" */ "../views/explore/inner/present.vue"
                  ),
                props: route => ({ query: route.query.q })
              },
              {
                path: "future",
                component: () =>
                  import(
                    /* webpackChunkName: "/aW1wb3J0cw/future" */ "../views/explore/inner/future.vue"
                  ),
                props: route => ({ query: route.query.q })
              },
              {
                path: "grewtale",
                component: () =>
                  import(
                    /* webpackChunkName: "/aW1wb3J0cw/grewtale" */ "../views/explore/inner/grewtale.vue"
                  ),
                props: route => ({ query: route.query.q })
              },
              {
                path: "cs",
                component: () =>
                  import(
                    /* webpackChunkName: "/aW1wb3J0cw/cs" */ "../views/explore/inner/cs.vue"
                  ),
                props: route => ({ query: route.query.q })
              },
              {
                path: "users",
                component: () =>
                  import(
                    /* webpackChunkName: "/aW1wb3J0cw/user" */ "../views/explore/inner/user.vue"
                  ),
                props: route => ({ query5: route.query.q })
              }
            ]
          },

          {
            meta: { animate: false },
            path: "/grewtale",
            component: () =>
              import(
                /* webpackChunkName: "/aW1wb3J0cw/grewtale" */ "../views/grewtale.vue"
              ),
            props: route => ({ query: route.query })
          },
          {
            meta: { animate: false },
            path: "/lifeline",
            component: () =>
              import(
                /* webpackChunkName: "/aW1wb3J0cw/p/lifeline" */ "../views/lifeline/main.vue"
              )
          },
          {
            meta: { animate: false },
            path: "/feedback",
            component: () =>
              import(
                /* webpackChunkName: "/aW1wb3J0cw/feedback" */ "../views/feedback.vue"
              )
          },

          {
            path: "/notification/",
            component: () =>
              import(
                /* webpackChunkName: "/aW1wb3J0cw/notification" */ "../views/notification.vue"
              ),
            children: [
              {
                path: "activity",
                component: () =>
                  import(
                    /* webpackChunkName: "/aW1wb3J0cwnotification/activity" */ "../views/notification/activity.vue"
                  )
              },
              {
                path: "request",
                component: () =>
                  import(
                    /* webpackChunkName: "/aW1wb3J0cw/notification/request" */ "../views/notification/request.vue"
                  )
              }
            ]
          },
          {
            path: "/edit/profile",
            component: () =>
              import(
                /* webpackChunkName: "/aW1wb3J0cw/edit" */ "../views/edit/main.vue"
              )
          },
          {
            path: "/pic/upload",
            component: () =>
              import(
                /* webpackChunkName: "/aW1wb3J0cw/testscript" */ "../views/edit/inner/profile_inner/image.vue"
              )
          }
        ]
      },
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "/aW1wb3J0cw/talk" */ "../views/talk/talk.vue"
          )
      },
      {
        path: "/discussion",
        component: () =>
          import(
            /* webpackChunkName: "/aW1wb3J0cw/discusssion" */ "../components/signattach.vue"
          )
      },
      {
        path: "/focus",
        component: () =>
          import(
            /* webpackChunkName: "/aW1wb3J0cw/signattach" */ "../components/signattach.vue"
          )
      },
      {
        path: "/message",
        component: () =>
          import(
            /* webpackChunkName: "/aW1wb3J0cw/talk" */ "../views/talk/talk.vue"
          ),
        children: [
          {
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "/aW1wb3J0cw/talk/2" */ "../views/talk/inner/talk2.vue"
              )
          },
          {
            path: ":id",
            component: () =>
              import(
                /* webpackChunkName: "/aW1wb3J0cw/talk/id" */ "../views/talk/inner/talkid.vue"
              ),
            props: route => ({ id: route.params.id })
          }
        ]
      },

      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "/aW1wb3J0cw/setting" */ "../views/settings/index.vue"
          ),

        children: [
          {
            path: "/setting",
            component: () =>
              import(
                /* webpackChunkName: "/aW1wb3J0cw/setting_ini" */ "../views/settings/inner_settings/initial.vue"
              )
          },
          {
            path: "/setting/username",
            component: () =>
              import(
                /* webpackChunkName: "/aW1wb3J0cw/setting_username" */ "../views/settings/inner_settings/username_st.vue"
              )
          },
          {
            path: "/setting/privacy",
            component: () =>
              import(
                /* webpackChunkName: "/aW1wb3J0cw/setting_privacy" */ "../views/settings/inner_settings/privacy.vue"
              )
          },
          {
            path: "/setting/search",
            component: () =>
              import(
                /* webpackChunkName: "/aW1wb3J0cw/setting_searchme" */ "../views/settings/inner_settings/search.vue"
              )
          },
          {
            path: "/setting/password",
            component: () =>
              import(
                /* webpackChunkName: "/aW1wb3J0cw/password" */ "../views/settings/inner_settings/password.vue"
              )
          },
          {
            path: "/setting/account",
            component: () =>
              import(
                /* webpackChunkName: "/aW1wb3J0cw/setting_account" */ "../views/settings/inner_settings/account.vue"
              )
          }
        ]
      },
      {
        path: "/lo",

        component: () =>
          import(
            /* webpackChunkName: "/aW1wb3J0cw/testscript" */ "../views/74558.55914.vue"
          )
      },
      {
        path: "/image/editing",
        component: () =>
          import(
            /* webpackChunkName: "/aW1wb3J0cw/imageditor" */ "../views/imageditor.vue"
          )
      },
      {
        path: "/menu",
        component: () =>
          import(/* webpackChunkName: "/aW1wb3J0cw/menu" */ "../views/menu.vue")
      },

      {
        path: "/:username",
        component: () =>
          import(
            /* webpackChunkName: "/aW1wb3J0cw/user/main" */ "../views/user/main.vue"
          ),
        props: route => ({ username: route.params.username }),
        children: [
          {
            name: "User",
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "/aW1wb3J0cw/user/grewtale" */ "../views/user/inner/grewtale.vue"
              )
          },
          {
            path: "connectors",
            component: () =>
              import(
                /* webpackChunkName: "/aW1wb3J0cw/user/connectors" */ "../views/user/inner/connectors.vue"
              ),
            props: true
          },
          {
            path: "past",
            component: () =>
              import(
                /* webpackChunkName: "/aW1wb3J0cw/user/past" */ "../views/user/inner/past.vue"
              ),
            props: true
          },
          {
            path: "present",
            component: () =>
              import(
                /* webpackChunkName: "/aW1wb3J0cw/user/present" */ "../views/user/inner/present.vue"
              ),
            props: true
          },
          {
            path: "future",
            component: () =>
              import(
                /* webpackChunkName: "/aW1wb3J0cw/user/future" */ "../views/user/inner/future.vue"
              ),
            props: true
          }
        ]
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const y = localStorage.getItem("Auth");
    if (y !== null) {
      store.commit("SET_TOKEN", y);

      next();
    } else {
      next("/login");
    }
  } else {
    next();
  }
});

export default router;
