<template>
  <div hidden>
    <div class="fy-width_100p disflex fy-height_auto">
      <div v-if="grewtale.accelerator != 0">
        <router-link :to="`/single?id=` + grewtale.accelerator">
          <div
            class="  fy-font-10 fy-text-4 fy-margin-l-10 fy-bg_mid_bl  "
            style="padding: 1px 7px 1px 6px; border-radius:5px;"
          >
            Competed
          </div>
        </router-link>
      </div>
      <div
        v-if="grewtale.standard > 0"
        class=" fy-font-14 badgeg  fy-width_20px fy-height_20px fy-border-50 fy-portlet-active--bs tac fy-margin-l-10"
      >
        <span v-if="grewtale.standard === 1">L</span>
        <span v-if="grewtale.standard === 2">M</span>
        <span v-if="grewtale.standard === 3">H</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    grewtale: null
  }
};
</script>
