import Vue from "vue";
import Vuex from "vuex";

import registration from "./modules/registration.js";
import api from "./modules/api.js";
import account from "./modules/profile.js";
import last_login from "./modules/last_login.js";
import Auth from "./modules/Auth.js";
import dates from "./modules/dates.js";
import hsearch from "./modules/search_history.js";
import grewtale from "./modules/grewtale.js";
import grewtale_c from "./modules/grewtale_c.js";
import plotters from "./modules/plotters.js";
import connectors from "./modules/connectors.js";
import notification from "./modules/notification.js";
import framework from "./modules/framework.js";
import links from "./modules/links.js";
import report from "./modules/report.js";
import message from "./modules/message.js";
import settings from "./modules/settings.js";
import searching from "./modules/searching.js";
import talk from "./modules/talk.js";
import lifeline_subject from "./modules/lifeline&subject.js";
import post from "./modules/post.js";
//other_user
import img_editor from "./modules/imged.js";
import user from "./modules/user.js";
import comment from "./modules/comment.js";
import user_assets from "./modules/user_assets.js";
import user_delete from "./modules/user_delete.js";
import feedback from "./modules/feedback.js";
Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  plugins: [],

  modules: {
    registration,
    api,
    Auth,
    account,
    dates,
    report,
    hsearch,
    grewtale,
    grewtale_c,
    plotters,
    connectors,
    notification,
    framework,
    settings,
    talk,
    searching,
    lifeline_subject,
    user,
    post,
    comment,
    user_assets,
    user_delete,
    links,
    last_login,
    feedback,
    message,
    img_editor
  }
});
