import config from "@/api.js";
import Axios from "axios";
const getDefaultState = () => {
  return {
    msgder: "",
    msgdell: ""
  };
};
const user_delete = {
  state: getDefaultState(),
  mutations: {
    r_panel(state, data) {
      state.msgder = data;
    },
    SET_dmsg(state, data) {
      state.msgdell = data;
    }
  },
  actions: {
    DELETEuserLifeline({ commit }, { id, type, index }) {
      Axios.post(`${config.publicPath}` + "past/delete", { id, type })
        .then(response => {
          if (response.data === "Deleted") {
            commit("DeleteLifeline", { type, index });
          }
          commit("SETmessage", response.data);
          setTimeout(() => {
            commit("SETmessage", "");
          }, 1500);
        })
        .catch(error => console.log(error));
    },

    DELETEusergrewtale({ commit }, { id }) {
      Axios.post(`${config.publicPath}` + "grewtale/delete", { id })
        .then(response => {
          if (response.data === "Deleted") {
            commit("SEThogredel", id);
            commit("SEThogredel_c", id);
            commit("SETusgredel", id);
          }
          commit("SETmessage", response.data);
          commit("r_panel", response.data);
          setTimeout(() => {
            commit("SETmessage", "");
            commit("r_panel", "");
          }, 1500);
        })
        .catch(error => console.log(error));
    },
    RESET: state => {
      Object.assign(state, getDefaultState());
    }
  },
  getters: {
    getmsgde: state => {
      return state.msgder;
    },
    getmsgder: state => {
      return state.msgdell;
    }
  }
};
export default user_delete;
