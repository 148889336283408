<template>
  <div
    v-if="GEThover.access === true"
    class="psf fy-fade-in fy-zi_4 fy-transition fy-width_100px fy-height_30px fy-border_r8 fy-padding-5 tac fy-hover_bg"
    :style="
      'top:' +
        top +
        'px; left:' +
        right +
        'px; box-shadow:0px 0px 6px #00000068;'
    "
  >
    <div class="fy-text-11 fy-font-15">{{ this.GEThover.message }}</div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      right: 0,
      top: 0,
      bug1: false
    };
  },
  computed: {
    ...mapGetters(["GEThover"])
  },
  watch: {
    GEThover() {
      let a = this.GEThover;
      if (this.bug1 === false) {
        this.bug1 = true;
        this.right = (a.a.right + a.a.left) / 2 - 50;
        this.top = a.a.top - 55;
        setTimeout(() => {
          this.top = a.a.top - 50;
        }, 50);
      }
      if (a.access === false) {
        this.bug1 = false;
      }
    }
  }
};
</script>
