import config from "@/api.js";
import Axios from "axios";
const getDefaultState = () => {
  return {
    search_history: []
  };
};
const hsearch = {
  state: getDefaultState(),
  mutations: {
    SET_HISTORY(state, data) {
      state.search_history = data;
    },
    RESET: state => {
      Object.assign(state, getDefaultState());
    }
    //set_element(state, data){

    //}
  },
  actions: {
    loadhsearch({ commit }) {
      Axios.get(`${config.publicPath}` + "search/history/get")
        .then(response => {
          commit("SET_HISTORY", response.data);
        })
        .catch(error => console.log(error));
    }
  },
  getters: {
    getsearch_h: state => {
      return state.search_history;
    }
  }
};
export default hsearch;
