<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      id="svg"
      :width="options.width"
      :height="options.height"
      viewBox="0, 0, 400,400"
    >
      <g id="svgg">
        <path
          id="path0"
          d="M53.525 9.067 C 36.269 13.262,21.729 27.948,17.166 45.789 C 15.088 53.911,14.741 341.347,16.796 351.172 C 20.294 367.889,33.854 383.027,50.097 388.347 L 55.859 390.234 171.875 390.234 L 287.891 390.234 293.359 388.502 C 308.017 383.857,319.733 372.520,325.685 357.220 L 327.734 351.953 327.963 311.790 L 328.192 271.626 320.830 278.664 C 300.555 298.047,287.903 304.091,254.210 310.487 C 221.874 316.625,210.652 313.810,199.221 296.693 C 191.406 284.989,191.306 279.301,198.369 248.306 C 208.046 205.838,205.419 209.667,266.948 148.364 C 312.158 103.321,316.226 99.650,325.987 95.096 L 329.708 93.359 329.483 69.922 C 329.262 46.817,329.230 46.410,327.229 41.218 C 321.390 26.068,310.091 14.996,295.313 9.940 L 290.234 8.203 174.219 8.067 C 74.996 7.950,57.526 8.095,53.525 9.067 M251.586 64.922 C 265.670 67.942,267.907 87.471,254.922 94.046 C 250.739 96.164,91.660 95.925,86.966 93.794 C 75.080 88.398,77.261 69.869,90.332 65.210 C 94.289 63.800,245.095 63.530,251.586 64.922 M343.053 123.501 C 332.749 125.525,332.786 125.493,285.424 172.891 C 231.044 227.312,236.012 220.197,227.745 255.511 C 220.703 285.593,219.917 284.905,252.675 277.348 C 274.510 272.311,279.156 270.596,288.502 264.120 C 296.081 258.869,378.045 176.782,380.390 172.094 C 393.481 145.923,371.900 117.836,343.053 123.501 M188.834 130.864 C 201.567 134.645,203.244 153.336,191.392 159.382 C 186.478 161.889,92.113 161.797,86.914 159.280 C 75.058 153.541,77.041 134.881,89.934 130.860 C 95.251 129.202,183.249 129.205,188.834 130.864 "
          stroke="none"
          fill="#000000"
          fill-rule="evenodd"
        />
      </g>
    </svg>
  </div>
</template>
<script>
export default {
  props: {
    options: {}
  }
};
</script>
