var numeric = 0;
function ru(d, u, g, h) {
  var r = "",
    c = "";
  "logo" === g
    ? ((r = "data-blue-ripple"), (c = "fy-blue_ripple"))
    : ((r = "data-theme-ripple"), (c = "fy-theme_ripple"));
  var element = document.querySelector(`[${r} = "${u}"]`);
  const circle = document.createElement("div");
  const x = d.layerX;
  const y = d.layerY;

  circle.classList.add(c);
  circle.classList.add(`fy-ripple_${h}`);
  circle.style.left = `${x}px`;
  circle.style.top = `${y}px`;

  element.appendChild(circle);
}

const vueRipple = {
  bind: function(el, bi) {
    numeric = numeric + 1;
    var u = "OBSR__" + numeric;
    bi.args = u;
    var t = "logo" === bi.value.type ? "data-blue-ripple" : "data-theme-ripple";
    el.setAttribute(t, u);

    el.addEventListener("click", e => {
      ru(e, u, bi.value.type, bi.value.size);
    });
  },
  unbind: function(el) {
    numeric = numeric - 1;
    el.removeEventListener("click", () => {});
  }
};
export function install(Vue) {
  Vue.directive("ripple", vueRipple);
  /* -- Add more components here -- */
}

export { vueRipple };
