<template>
  <button ref="btn" :disabled="disable === true" class="btn btn-info">
    <div v-if="b_loading === true" class="psr fy-height_25px  fy-width_100p">
      <div class="fy-submit_loading"></div>
    </div>
    <span v-else><slot></slot></span>
  </button>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    disable: {
      type: Boolean,
      default() {
        return {};
      }
    }
  }
};
</script>
