import config from "@/api.js";
import Axios from "axios";
const getDefaultState = () => {
  return {
    global_comment: [],
    gcNext: null,
    global_idea: [],
    giNext: null,
    global_qa: [],
    gqNext: null,
    reply_active: "",
    reply_index: null,
    reply_type: "",
    commentprogress: "",
    reflectedid: "",
    reflectedstatus: false,
    ga1: false,
    ga2: false,
    ga3: false,
    ga4: false,
    getqa: false,
    getidea: false,
    qalength: 0,
    idealength: 0,
    comment_length: 0,
    nomorecomment: false,
    nomoreqa: false,
    nomoreideas: false
  };
};
const comment = {
  state: getDefaultState(),
  mutations: {
    SET_comments(state, data) {
      console.log(data);
      const mess = {
        comment: data.comment,
        blobs: data.blobs,
        iso: data.iso,
        point: data.point,
        grewtale: data.grewtale,
        type: data.type,
        first_time: true,
        progress: "",
        primitive_id: data.primitive_id,
        sended: false,
        id: "",
        error: false,
        user_id: data.user_id,
        u_status: "0",
        message: data.message,
        first_name: data.first_name,
        last_name: data.last_name,
        profilepic: data.profilepic,
        time: "now"
      };
      if (state.reply_index === null || state.reply_type === "grewtale") {
        state.global_comment.push(mess);

        if (mess.type === 2) {
          state.global_idea.push(mess);
        }
        if (mess.type === 3) {
          state.global_qa.push(mess);
        }
      } else {
        const last_part = state.global_comment.slice(
          state.reply_index.i + 1,
          state.global_comment.length
        );
        state.global_comment.splice(
          Math.round(state.reply_index.i + 1),
          Math.round(state.global_comment.length - state.reply_index.i + 1)
        );
        state.global_comment.push(mess);
        const final_array = state.global_comment.concat(last_part);
        state.global_comment = final_array;

        if (mess.type === 2) {
          const last_part = state.global_idea.slice(
            state.reply_index.j + 1,
            state.global_comment.length
          );
          state.global_idea.splice(
            Math.round(state.reply_index.j + 1),
            Math.round(state.global_idea.length - state.reply_index.j + 1)
          );
          state.global_idea.push(mess);
          const final_array = state.global_idea.concat(last_part);
          state.global_idea = final_array;
        }

        if (mess.type === 3) {
          const last_part = state.global_qa.slice(
            state.reply_index.k + 1,
            state.global_qa.length
          );
          state.global_qa.splice(
            Math.round(state.reply_index.k + 1),
            Math.round(state.global_qa.length - state.reply_index.k + 1)
          );
          state.global_qa.push(mess);
          const final_array = state.global_qa.concat(last_part);
          state.global_qa = final_array;
        }
      }
      state.reply_active = "";
      state.reply_index = null;
    },
    SET_comments2(state, data) {
      if (data === "nothing" || data === "Error") {
        state.nomorecomment = true;
      } else {
        if (data.next === null) state.nomorecomment = true;
        state.gcNext = data.next;
        data = data.data;
        var actual = state.comment_length + data.length;
        state.comment_length = actual;

        for (var n = 0; n < data.length; n++) {
          var comment = data.comment;
          var blobs = [];
          var file = JSON.parse(data[n].file);
          for (var i = 0; i < file.length; i++) {
            var link =
              `${config.cdnPath}` + "comment/media/v1/photo/" + file[i].name;
            blobs.push(link);
          }
          //s data.blobs;
          var message = data[n].message;
          var iso = data[n].iso;
          var point = data[n].point;
          var type = data[n].type;
          var id = data[n].id;
          var u_status = data[n].u_status;
          var user_id = JSON.parse(data[n].person);
          var grewtale = data[n].grewtale;
          var first_name = data[n].first_name;
          var primitive_id = data[n].primitive_id;
          var last_name = data[n].last_name;
          var profilepic = data[n].profilepic;
          // const now = JSON.stringify(data[n].created_at);
          // const time = date.format(now, "YYYY/MM/DD HH:mm:ss");
          //  console.log(time);
          state.global_comment.unshift({
            comment: comment,
            blobs: blobs,
            iso: iso,
            point: point,
            grewtale: grewtale,
            type: type,
            first_time: false,
            progress: "100%",
            sended: true,
            id: id,
            primitive_id: primitive_id,
            error: false,
            user_id: user_id,
            message: message,
            u_status: u_status,
            first_name: first_name,
            last_name: last_name,
            profilepic: profilepic,
            time: data[n].created_at
          });
        }
      }
    },
    SET_reply(state, data) {
      state.reply_active = data.comment;
      state.reply_type = data.type;
      state.reply_index = {
        i: data.i,
        j: data.j,
        k: data.k
      };
      // console.log(state.reply_index);
    },
    SET_commentprogress(state, data) {
      if (data) {
        state.commentprogress = data;
      }
    },
    SET_msgc(state, data) {
      state.reflectedid = data.id;

      if (data.msg === "Commented") {
        state.comment_length = state.comment_length + 1;
        state.reflectedstatus = true;
        for (var i = 0; i < state.global_comment.length; i++) {
          if (!state.global_comment[i].id) {
            state.global_comment[i].id = data.id;
            state.global_comment[i].sended = true;

            break;
          }
        }
      } else if (
        data.msg === "Error" ||
        data.msg === "Nothing" ||
        data.msg === "error"
      ) {
        for (i = 0; i < state.global_comment.length; i++) {
          if (!state.global_comment[i].id) {
            state.global_comment[i].id = "0";
            state.global_comment[i].error = true;

            break;
          }
        }
      } else {
        state.reflectedstatus = false;
      }
      this.getglobal_comment;
    },
    reply_reset(state) {
      state.reply_active = "";
      state.reply_type = "";
      state.reply_index = null;
    },
    ga1(state, data) {
      state.ga1 = data;
    },
    ga2(state, data) {
      state.ga2 = data;
    },
    ga3(state, data) {
      state.ga3 = data;
    },
    ga4(state, data) {
      state.ga4 = data;
    },
    RESETcomment(state) {
      state.global_comment = [];
      state.gqNext = null;
      state.giNext = null;
      state.gcNext = null;
      state.reply_active = "";
      state.reply_index = null;
      state.reply_type = "";
      state.commentprogress = "";
      state.reflectedid = "";
      state.qalength = 0;
      state.idealength = 0;
      state.reflectedstatus = false;
      state.ga1 = false;
      state.ga2 = false;
      state.ga3 = false;
      state.ga4 = false;
      state.getqa = false;
      state.getidea = false;
      state.comment_length = 0;
      state.nomorecomment = false;
      state.nomoreqa = false;
      state.nomoreideas = false;
      state.global_idea = [];
      state.global_qa = [];
    },
    remove_comment(state, data) {
      state.global_comment.splice(data.i, 1);
      state.global_idea.splice(data.j, 1);
      state.global_qa.splice(data.k, 1);
    },
    SET_commentsqa2(state, data) {
      if (data === "nothing") {
        state.nomoreqa = true;
      } else {
        if (data.next === null) state.nomoreqa = true;
        state.gqNext = data.next;
        data = data.data;
        var actual = state.qalength + data.length;
        state.qalength = actual;

        for (var n = 0; n < data.length; n++) {
          var comment = data.comment;
          var blobs = [];
          var file = JSON.parse(data[n].file);
          for (var i = 0; i < file.length; i++) {
            var link =
              `${config.cdnPath}` + "comment/media/v1/photo/" + file[i].name;
            blobs.push(link);
          }
          //s data.blobs;
          var message = data[n].message;
          var iso = data[n].iso;
          var point = data[n].point;
          var type = data[n].type;
          var id = data[n].id;
          var u_status = data[n].u_status;
          var user_id = JSON.parse(data[n].person);
          var grewtale = data[n].grewtale;
          var first_name = data[n].first_name;
          var primitive_id = data[n].primitive_id;
          var last_name = data[n].last_name;
          var profilepic = data[n].profilepic;
          // const now = JSON.stringify(data[n].created_at);
          // const time = date.format(now, "YYYY/MM/DD HH:mm:ss");
          //  console.log(time);
          state.global_qa.unshift({
            comment: comment,
            blobs: blobs,
            iso: iso,
            point: point,
            grewtale: grewtale,
            type: type,
            first_time: false,
            progress: "100%",
            sended: true,
            id: id,
            primitive_id: primitive_id,
            error: false,
            user_id: user_id,
            message: message,
            u_status: u_status,
            first_name: first_name,
            last_name: last_name,
            profilepic: profilepic,
            time: data[n].created_at
          });
        }
      }
    },
    SET_commentsidea2(state, data) {
      if (data === "nothing") {
        state.nomoreideas = true;
      } else {
        if (data.next === null) state.nomoreideas = true;
        state.giNext = data.next;
        data = data.data;
        var actual = state.idealength + data.length;
        state.idealength = actual;

        for (var n = 0; n < data.length; n++) {
          var comment = data.comment;
          var blobs = [];
          var file = JSON.parse(data[n].file);
          for (var i = 0; i < file.length; i++) {
            var link =
              `${config.cdnPath}` + "comment/media/v1/photo/" + file[i].name;
            blobs.push(link);
          }
          //s data.blobs;
          var message = data[n].message;
          var iso = data[n].iso;
          var point = data[n].point;
          var type = data[n].type;
          var id = data[n].id;
          var u_status = data[n].u_status;
          var user_id = JSON.parse(data[n].person);
          var grewtale = data[n].grewtale;
          var first_name = data[n].first_name;
          var primitive_id = data[n].primitive_id;
          var last_name = data[n].last_name;
          var profilepic = data[n].profilepic;
          // const now = JSON.stringify(data[n].created_at);
          // const time = date.format(now, "YYYY/MM/DD HH:mm:ss");
          //  console.log(time);
          state.global_idea.unshift({
            comment: comment,
            blobs: blobs,
            iso: iso,
            point: point,
            grewtale: grewtale,
            type: type,
            first_time: false,
            progress: "100%",
            sended: true,
            id: id,
            primitive_id: primitive_id,
            error: false,
            user_id: user_id,
            message: message,
            u_status: u_status,
            first_name: first_name,
            last_name: last_name,
            profilepic: profilepic,
            time: data[n].created_at
          });
        }
      }
    },
    q_aset(state, data) {
      state.getqa = true;
      state.qalength = state.qalength + data.length;
      for (let i = 0; i < data.length; i++) {
        var d = data[i];
        state.global_qa.push(d);
      }
      var y = state.global_qa;
      state.gqNext = y[y.length - 1].id;
    },
    ideaset(state, data) {
      state.getidea = true;
      state.idealength = state.idealength + data.length;
      for (let i = 0; i < data.length; i++) {
        var d = data[i];
        state.global_idea.push(d);
      }
      var y = state.global_idea;
      state.giNext = y[y.length - 1].id;
    },
    Setallnomorecomment(state, data) {
      state.nomoreideas = data;
      state.nomoreqa = data;
    },
    RESET: state => {
      Object.assign(state, getDefaultState());
    }
  },
  actions: {
    setcommentd({ commit, state }, { route }) {
      if (route === "q_a") {
        var r = [];
        if (state.getqa === false) {
          for (let i = 0; i < state.global_comment.length; i++) {
            if (state.global_comment[i].type === 3) {
              r.push(state.global_comment[i]);
            }
          }
          commit("q_aset", r);
        }
      } else if (route === "suggestions_ideas") {
        var t = [];
        if (state.getidea === false) {
          for (let i = 0; i < state.global_comment.length; i++) {
            if (state.global_comment[i].type === 2) {
              t.push(state.global_comment[i]);
            }
          }
          commit("ideaset", t);
        }
      }
    },
    loadqa({ commit, state }, { primitive_id }) {
      if (state.ga3 === false && state.getqa === true) {
        commit("ga3", true);
        var type = 3,
          length = 15,
          next = state.gqNext;
        Axios.post(`${config.publicPath}` + "g/comment/type", {
          length,
          primitive_id,
          type,
          next
        })
          .then(response => {
            commit("SET_commentsqa2", response.data);
          })
          .catch(error => console.log(error))
          .finally(() => {
            commit("ga3", false);
          });
      }
    },
    loadidea({ commit, state }, { primitive_id }) {
      if (state.ga4 === false && state.getidea === true) {
        commit("ga4", true);
        var type = 2,
          length = 15,
          next = state.giNext;
        Axios.post(`${config.publicPath}` + "g/comment/type", {
          length,
          primitive_id,
          type,
          next
        })
          .then(response => {
            commit("SET_commentsidea2", response.data);
          })
          .catch(error => console.log(error))
          .finally(() => {
            commit("ga4", false);
          });
      }
    },

    //actual free
    setComment({ commit }, { formData }) {
      Axios.post(`${config.cdnPath}` + "p/comment", formData, {
        onUploadProgress: uploadEvent => {
          var progress = Math.round(
            (uploadEvent.loaded / uploadEvent.total) * 100
          );
          commit("SET_commentprogress", progress);

          setTimeout(() => {
            commit("SET_commentprogress", "");
          }, 2000);
        }
      })
        .then(response => {
          commit("SET_msgc", response.data);
        })
        .catch(error => {
          console.log(error);
          var data = {
            msg: "Error"
          };
          commit("SET_msgc", data);
        });
    },
    loadcomments({ commit, state }, { primitive_id }) {
      if (state.ga1 === false) {
        commit("ga1", true);
        var length = 15,
          next = state.gcNext;
        Axios.post(`${config.publicPath}` + "g/comment", {
          length,
          primitive_id,
          next
        })
          .then(response => {
            commit("SET_comments2", response.data);
          })
          .catch(error => console.log(error))
          .finally(() => {
            commit("ga1", false);
          });
      }
    },
    removecomments({ commit, state }, { id, i, j, k }) {
      if (state.ga2 === false) {
        commit("ga2", true);

        Axios.post(`${config.publicPath}` + "d/comment", {
          id
        })
          .then(response => {
            if (response.data === "Deleted") {
              commit("remove_comment", { i, j, k });
            }
          })
          .catch(error => console.log(error))
          .finally(() => {
            commit("ga2", false);
          });
      }
    }
  },
  getters: {
    getglobal_comment: state => {
      return state.global_comment;
    },
    getglobal_idea: state => {
      return state.global_idea;
    },
    getglobal_qa: state => {
      return state.global_qa;
    },
    getreply_comment: state => {
      return state.reply_active;
    },
    getreply_type: state => {
      return state.reply_type;
    },
    getcomment_progress: state => {
      return state.commentprogress;
    },
    getreflectedid: state => {
      return state.reflectedid;
    },
    getreflectedstatus: state => {
      return state.reflectedstatus;
    },
    getcomment_length: state => {
      return state.comment_length;
    },
    getnomorecomment: state => {
      return state.nomorecomment;
    },
    getqalength: state => {
      return state.qalength;
    },
    getnomoreqa: state => {
      return state.nomoreqa;
    },
    getidealength: state => {
      return state.idealength;
    },
    getnomoreideas: state => {
      return state.nomoreideas;
    }
  }
};
export default comment;
