function grewtaleDataFormation(data) {
  var _data = data.data,
    fields = {
      first_name: _data.first_name,
      id: _data.id,
      username: _data.username,
      last_name: _data.last_name,
      wn: _data.w_n,
      type: _data.type,
      profilepic: _data.profilepic,
      title: _data.title,
      privacy: _data.privacy,
      content: _data.content,
      fullContentAvailibility: _data.fullContentAvailibility,
      standard: _data.standard,
      primitive_id: _data.primitive_id,
      accelerator: _data.accelerator,
      file_type: _data.file_type,
      webMeta: _data.webMeta,
      p1: _data.p1,
      p2: _data.p2,
      p3: _data.p3,
      p4: _data.p4,
      p5: _data.p5,
      p6: _data.p6,
      p7: _data.p7,
      p8: _data.p8,
      person: _data.person,
      time: _data.time,
      format: _data.format,

      Active: data.active,
      plotterValueId: _data.plotterValueId,
      file: JSON.parse(_data.file)
    };

  //const now = new Date();
  const plotters = [];
  plotters.push(
    { b: fields.p1 },
    { b: fields.p2 },
    { b: fields.p3 },
    { b: fields.p4 },
    { b: fields.p5 },
    { b: fields.p6 },
    { b: fields.p7 },
    { b: fields.p8 }
  );
  var g = {
    id: fields.id,
    username: fields.username,
    plotters: plotters,
    title: fields.title,
    first_name: fields.first_name,
    last_name: fields.last_name,
    type: fields.type,
    wn: fields.wn,
    profilepic: fields.profilepic,
    file_active: false,
    file: fields.file,
    file_type: fields.file_type,
    webMeta: fields.webMeta,
    content: fields.content,
    fullContentAvailibility: fields.fullContentAvailibility,
    privacy: fields.privacy,
    standard: fields.standard,
    primitive_id: fields.primitive_id,
    accelerator: fields.accelerator,
    time: fields.time,
    format: fields.format,
    person: fields.person,
    Active: fields.Active,
    plotterValueId: fields.plotterValueId
  };

  return g;
}
function grewtaleContentSet(state, data, key) {
  var el = state[key];

  for (var i = 0; i < el.length; i++) {
    if (data.id === el[i].id) {
      el[i].content = data.content;
      el[i].fullContentAvailibility = 1;

      break;
    }
  }
}
function grewtaleMeasureAction(state, id, count, key, process) {
  var array = state[key],
    i = null;

  for (var j = 0; j < array.length; j++) {
    if (id == array[j].id) {
      i = j;
      break;
    }
  }

  if (i !== null) {
    var actual_count = count + 1,
      Active = state[key][i].Active,
      btn = state[key][i].plotters[count].b;
    if (process === "INSERTED") {
      if (Active > 0 && Active < 9) {
        if (state[key][i].Active === actual_count) {
          state[key][i].plotters[count].b = btn - 1;
          state[key][i].Active = 0;
          state[key][i].plotterValueId = 0;
        } else {
          state[key][i].plotters[count].b = btn + 1;
          state[key][i].plotters[Active - 1].b =
            state[key][i].plotters[Active - 1].b - 1;
          state[key][i].Active = actual_count;
        }
      } else {
        state[key][i].plotters[count].b = btn + 1;
        state[key][i].Active = actual_count;
      }
    } else {
      state[key][i].plotters[count].b = btn - 1;
      state[key][i].Active = 0;
    }
  }
}
function lifelineAction(data, state) {
  var active = data.active,
    index = data.index,
    plotterValueId = data.plotterValueId,
    actualState = state[data.type][index],
    button = data.button,
    count = button - 1;
  if (active > 0 && active < 4) {
    if (active === button) {
      actualState.button[count].b = actualState.button[count].b - 1;
      actualState.active.value = null;
      actualState.active.id = null;
    } else {
      actualState.button[count].b = actualState.button[count].b + 1;
      actualState.button[active - 1].b = actualState.button[active - 1].b - 1;
      actualState.active.value = button;
    }
  } else {
    actualState.button[count].b = actualState.button[count].b + 1;
    actualState.active.value = button;
    actualState.active.id = plotterValueId;
  }
}
export {
  grewtaleDataFormation,
  grewtaleMeasureAction,
  lifelineAction,
  grewtaleContentSet
};
