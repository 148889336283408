<template>
  <div
    v-observe-visibility="at"
    :data-setup="`` + grewtale.file_active + ``"
    :data-dynamic="`` + index + ``"
    class=" fy-portlet fy-portlet-active--bs fy-portlet-active--bg fy-padding-t-5"
    :class="{ 'fy-border_r8 fy-max-grewtale': GETscreen === 'desktop' }"
  >
    <div
      :id="`grewtale_${grewtale.id}`"
      class="   fy-ff-wp fy-padding-l-5 fy-padding-r-5 disflex fy-width_100p fy-jc-sb "
    >
      <div class=" disflex">
        <div>
          <router-link v-bind:to="`/` + grewtale.username">
            <div class="fy-height_auto">
              <img
                v-bind:src="
                  GETcdnlink +
                    `user/cus_profile/v1/photo/` +
                    grewtale.profilepic
                "
                class="fy-width_35px fy-height_35px fy-profile_bs fy-border_50"
              /></div
          ></router-link>
        </div>
        <div class="tal fy-margin-l-10 fy-padding-b-5">
          <router-link v-bind:to="`/` + grewtale.username">
            <div class="kut fy-font-13 fy-ts_slow fy-font_w600  fy-text-1 ">
              {{ grewtale.first_name }} {{ grewtale.last_name }}
            </div>
            <div
              class=" fy-font-11 fy-margin-r-5 fy-ts_slow  fy-font_w600 fy-text-7"
            >
              {{ grewtale.time }} {{ grewtale.format }}
            </div>
          </router-link>
        </div>
        <div v-if="grewtale.accelerator != 0">
          <router-link :to="`/single?id=` + grewtale.accelerator">
            <div
              class=" fy-padding-5 fy-border_r8 fy-font-10 fy-text-4 fy-margin-l-10 fy-bg_mid_bl fy-margin-t-5 "
            >
              Competed
            </div>
          </router-link>
        </div>
        <div
          v-if="grewtale.standard > 0"
          class=" fy-font-16 badgeg fy-margin-t-5 fy-width_25px fy-height_25px fy-border-50 fy-portlet-active--bs tac fy-margin-l-15"
        >
          <span v-if="grewtale.standard === 1">L</span>
          <span v-if="grewtale.standard === 2">M</span>
          <span v-if="grewtale.standard === 3">H</span>
        </div>
      </div>
      <div class=" disflex ">
        <div class="psr">
          <button
            v-if="menu"
            @focus="menuControl(true)"
            @blur="menuControl(false)"
            ref="MENU_GREWTALE"
            class=" psa fy-margin-t-25 fy-zi_1 fy-top_0 fy-right_0 fy-portlet-active--bs3 fy-border_r8 "
            style="padding:0!important;background: var(--fy-body-pc);"
          >
            <div class="fy-height_100p fy-width_100p">
              <div
                v-if="grewtale.person == getiusers.profile.id"
                @click="grewtale_delete(grewtale)"
                class="fy-bt_act fy-border_r8 fy-padding-t-7 fy-height_35px fy-cursor_point fy-padding-5 fy-padding-r-10 fy-padding-l-10 fy-font-17 fy-text-7"
              >
                <div class="disflex fy-padding-r-5  fy-padding-l-5">
                  <div class="fy-font-15">
                    <icons
                      class=""
                      excel="delete"
                      routerActive="false"
                      hoverActive="false"
                      message="delete"
                      color="var(--fy-font-applyed-7)"
                      width="20"
                      height="20"
                    />
                  </div>
                  <div class="fy-margin-l-10  fy-font-15 fy-text-1">Delete</div>
                </div>
              </div>
              <div
                @click="report_panel(grewtale)"
                class="fy-bt_act fy-border_r8 fy-padding-t-7 fy-height_35px fy-cursor_point fy-padding-5 fy-padding-r-10 fy-padding-l-10 fy-font-17 fy-text-7"
              >
                <div class="disflex fy-padding-r-5  fy-padding-l-5">
                  <div class="fy-font-15">
                    <icons
                      class=""
                      excel="report"
                      routerActive="false"
                      hoverActive="false"
                      message="report"
                      color="var(--fy-font-applyed-7)"
                      width="20"
                      height="20"
                    />
                  </div>
                  <div class="fy-margin-l-10 fy-font-15 fy-text-1">Report</div>
                </div>
              </div>
            </div>
          </button>
          <div
            @click="grewtale_menu"
            class=" fy-cursor_point fy-width_30px fy-height_30px fy-bt_act fy-border_50   fy-font-15  fy-text-7 tac fy-margin-r-5"
            style="padding-top:3px"
          >
            <span class="">
              <icons
                class="fy-margin-t-5 fy-rotate_q3"
                excel="dots"
                routerActive="false"
                hoverActive="false"
                message="Image"
                color="var(--fy-font-applyed-7)"
                width="16"
                height="16"
            /></span>
          </div>
        </div>
      </div>
    </div>
    <security
      v-if="grewtale.privacy == 2 && grewtale.person != getiusers.profile.id"
    >
    </security>
    <div v-else>
      <sign :grewtale="grewtale" />
      <div v-if="grewtale.file.length > 0 && !grewtale.webMeta">
        <div
          v-if="grewtale.file_active === false && $route.path !== '/single'"
        ></div>
        <grewtaleFiles v-else :grewtale="grewtale" />
        <!--   <div v-else class="">
        <div v-if="grewtale.file_type === 1" class="fy-width_100p">
          <fyvideo
            :data-setup="JSON.stringify({ grewtale: grewtale, index: index })"
            :options="{ grewtale: grewtale, index: index }"
          >
          </fyvideo>
        </div>
        <div v-else>
          <grewtale_image :options="grewtale"  :index ="index" />
        </div>
      </div> -->
      </div>
      <webmeta
        v-if="grewtale.webMeta"
        :data="grewtale.webMeta"
        :img="grewtale.file"
      />
      <div
        v-if="grewtale.title || grewtale.wn || grewtale.content"
        class="fy-padding-5  "
      >
        <div
          v-if="grewtale.title"
          class="ti1 fy-main_grewtale fy_font_w500   fy-margin-l-5 fy-font-20 fy-break_word  fy-ts"
        >
          <span class="fy-padding-l-5 fy-text-n fy-font_w600 fy-font-18">{{
            grewtale.title
          }}</span>
        </div>
        <div
          v-if="grewtale.wn"
          class="whn fy-font-16 fy-text-n fy-margin-l-5 fy-margin-t-2 fy-break_word fy-ts"
        >
          <span
            class="fy-padding-l-5 fy-font_w600"
            :class="{ 'fy-font-16': GETscreen === 'mobile' }"
          >
            {{ grewtale.wn }}
          </span>
        </div>
        <div
          v-if="grewtale.content"
          class="  fy-font-14 fy-ts  fy-margin-t-2 fy-break_word fy-margin-l-10 fy-text-11"
        >
          <span :class="{ 'fy-font-12': GETscreen === 'mobile' }">
            <span><specialtext :strg="grewtale.content"/></span>
            <span
              v-if="grewtale.fullContentAvailibility === 0"
              @click="readall"
              class="fy-main_logo fy-cursor_point fy-margin-l-15"
              ><span v-if="readLoading === false">Read More...</span
              ><span
                v-else
                class="fy-fit_width fy-fit_height psa"
                style="margin-top:-2px"
                ><dotLoading
                  color="var(--fy-main-logo)"
                  height="25px"
                  width="25px"/></span
            ></span>
          </span>
        </div>
      </div>
      <div
        v-if="grewtale.Active > 0 && grewtale.Active < 9"
        class="fy-width_100p  fy-margin-t-2 tar"
      >
        <div class="fy-fit_width">
          <div
            class="fy-height_auto disflex fy-text-5 tae fy-padding-2   fy-width_100p fy-padding-r-5 fy-padding-l-5"
          >
            <measures
              :width="23"
              :height="23"
              :active="grewtale.Active - 1"
              type="Font"
              :point="grewtale.Active"
            />
            <div class="fy-text-7 fy-font-13 fy-font_w600  fy-margin-t-2">
              {{ getplotters[grewtale.Active - 1].sub }}
            </div>
          </div>
        </div>
      </div>
      <!-- measures -->
      <underBar :grewtale="grewtale" :index="index" />
    </div>
  </div>
</template>
<style scoped>
.fy-width_commentmbl {
  width: calc(100% - 60px);
}
.fy-width_130px {
  width: 130px;
}
.fy-bottom_-10 {
  bottom: -10px;
}
</style>
<script>
import measures from "@/slots/grewtale/measures.vue";
import { mapGetters } from "vuex";
import underBar from "./underBar.vue";
import grewtaleFiles from "@/slots/grewtale/filecontrol.vue";
import security from "@/slots/grewtale/security.vue";
import webmeta from "@/components/webmeta";
import config from "@/api.js";
import Axios from "axios";
import sign from "./sign.vue";

export default {
  data() {
    return {
      isEnabled: true,
      sliding: null,
      title_point: "",
      title_point_link1: "",
      title_point_link2: "",
      disable_p: false,
      readLoading: false,
      menu: false,
      widthunder: 0,
      width: 0,
      hidden: true
    };
  },
  props: {
    grewtale: {},
    index: null
  },
  components: {
    grewtaleFiles,
    measures,
    webmeta,
    security,
    sign,
    underBar
    //  comment
  },
  computed: {
    ...mapGetters([
      "getgrewtales",
      "GETcdnlink",
      "getplotters",
      "getplotter_msg",
      "getfile_window",
      "getnotificationcount",
      "getgrewn",
      "getiusers",
      "GETscreen",
      "GETheight",
      "getusergrewtale",
      "getgrewtales_c",
      "GETbaseurl",
      "GETwidth",
      "GETzoom"
    ]),
    left() {
      //  console.log(this.MeasurePanelWidth);
      var w = this.GETscreen === "desktop" ? 422 : this.GETwidth - 20;
      var c = 40,
        t = 0,
        v = (w - c * 8) / 7;
      //   console.log(v);
      if (this.grewtale.Active == 1) {
        var left = 0;
      } else if (this.grewtale.Active == 0) {
        t = 0;
      } else {
        left =
          c * (this.grewtale.Active - 1 + t) + v * (this.grewtale.Active - 1);
      }

      return left;
    },

    active() {
      return this.grewtale.Active;
    },
    path(){
      var p = this.$route.path;
      return p;
    }
  },
  
  mounted() {
    if (this.active == 0) {
      this.hidden = true;
    } else {
      this.hidden = false;
      this.width = 40;
    }
    //  this.MeasurePanelWidth = document.getElementById('MEASURES_SEGMENT').offsetWidth;
  },
  methods: {
    async share_activate() {
      var data = `https://fyish.com/single?id=${this.grewtale.id}`;
      if (this.GETscreen === "mobile" && navigator.share) {
        await navigator.share({
          url: data
        });
      } else {
        this.$store.commit("SETSharePanel", data);
      }
    },
    grewtale_menu() {
      this.menu = !this.menu;
      setTimeout(() => {
        this.$refs.MENU_GREWTALE.focus();
      }, 100);
    },
    menuControl(a) {
      this.menu = a;
    },

    readall() {
      if (this.readLoading === false) {
        this.readLoading = true;

        Axios.get(
          `${config.publicPath}` + `grewtale/content/${this.grewtale.id}`
        )
          .then(r => {
            var d = r.data[0].content;
          
            this.$store.commit("setGrewtaleContent", {
              id: this.grewtale.id,
              content: d
            });
          })
          .finally((this.readLoading = false));
      }
    },

    at(isVisible, index) {
      const _data = index.target.dataset;
      if (isVisible === true) {
        if (_data.setup === "false") {
          if (this.path === "/") {
            this.$store.commit("Setfile_active", _data);
          } else if (this.path === "/home") {
            this.$store.commit("Setfile_active_c", _data);
          } else {
            this.$store.commit("Setfile_active2", _data);
          }
        }
      }
    },

    async plotter_value(count) {
      try {
        this.disable_p = true;

        const id = this.grewtale.id;
        const active = this.grewtale.Active;

        if (active === count + 1) {
          this.$store.dispatch("delete_plotters", {
            id,
            count,
            active
          });
        } else {
          this.$store.dispatch("plotters", { id, count, active });
        }
      } catch (e) {
        console.log(e);
      }
    },

    // full_image(index) {
    // if (this.video_access !== null) {
    //   var video = document.getElementById(`vt` + this.video_access);
    //  video.pause();
    //  this.ended();
    // }
    // var data = index;
    // this.$store.commit("image_show", { data });
    // },

    compete(grewtale) {
      var _t = grewtale.title;
      var _p = grewtale.primitive_id;
      var _a = grewtale.id;
      this.$store.commit("SET_compete", { _t, _p, _a });
      this.$router.push("grewtale?active=1");
    },
    _up() {
      this.$store.commit("SETcomment_panel");
    },
    _idroute(grewtale) {
      if (this.path === "/home") {
        if (this.GETscreen === "mobile") {
          this._up();
        }
        var index = null;
        var type = "grewtale";
        var comment = grewtale;
        this.$store.commit("SET_reply", { index, comment, type });
      } else {
        var id = grewtale.primitive_id;
        this.$router.push("/home?competition=" + id + "&action=comment");
      }
    },
    plotter_active() {
      if (this.getplotter_msg) {
        setTimeout(() => {
          this.disable_p = false;
        }, 1000);
      }
    },
    report_panel() {
      this.$store.commit("LOADreport_panel");
      const status = {
        type: "grewtale",
        id: this.grewtale.id
      };
      this.$store.commit("SETreport_status", status);
    },

    grewtale_delete() {
      var data = this.grewtale;

      this.$store.commit("SETgrewtale_delete", data);
    }
  },
  watch: {
    getplotter_msg() {
      this.plotter_active();
    },
    active() {
      if (this.active != 0) {
        this.width = 20;
        setTimeout(() => {
          this.width = 40;
        }, 300);
      } else {
        this.width = 0;
      }
      this.hidden = this.active == 0 ? true : false;
    }
  }
};
</script>
