<template>
  <span>
    <span v-if="g === 'Address'" class="fy-op8">{{ g }}</span>
    <span v-else>
      {{ g }}
    </span>
  </span>
</template>
<script>
export default {
  data() {
    return {
      g: "Address"
    };
  },
  props: {
    address: {
      type: String,
      default() {
        return;
      }
    }
  },
  created() {
    this.set();
  },
  methods: {
    set() {
      if (this.address) {
        var t = this.address.split(",");
        this.g = `${t[0]}, ${t[t.length - 1]}`;
      }
    }
  },
  watch: {
    address() {
      this.set();
    }
  }
};
</script>
