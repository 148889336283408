<template>
  <div
    class="psr fy-width_100p tac"
    :style="`width:${width}px;height:${height}px;margin-top:${mt}px`"
  >
    <div v-show="open === false">
      <img
        @load="gt($event)"
        class="fy-width_auto fy-height_100p fy-max_w_100p"
        referrerpolicy="strict-origin-when-cross-origin"
        :src="GETcdnlink + `grewtale/media/v1/thumbnails/` + options.p[0].name"
        :style="`width:${width}px;height:${height}px;`"
      />
    </div>
    <button
      v-show="open === false"
      @click="open = true"
      class="fy-width_60px fy-height_60px  fy-beep fy-border_50 psa"
      style="top:calc(50% - 30px); right:calc(50% - 30px); "
    >
      <icons
        style="margin-left:3px"
        class=""
        excel="play"
        routerActive="false"
        hoverActive="false"
        message="Reset"
        color="var(--fy-font-applyed-4)"
        width="30"
        height="30"
      />
    </button>
    <div v-if="open === true">
      <vjs :options="options" :height="height" :width="width" :mt="mt" />
    </div>
  </div>
</template>
<style>
@import "https://vjs.zencdn.net/7.20.1/video-js.css";
</style>
<script>
import vjs from "./innerVideo/vjs.vue";
import { mapGetters } from "vuex";
export default {
  name: "VideoPlayer",
  props: {
    options: {
      type: Object,
      default() {
        return {};
      }
    },
    reqWidth : null,
    bigest: null
  },
  data() {
    return {
      open: false,
      height: 0,
      width: 0,
      mt: 0,
      dmsProv: true
    };
  },
  components: {
    vjs
  },
  computed: {
    ...mapGetters([
      "getgrewtales",
      "GETcdnlink",
      "getplotters",
      "getplotter_msg",
      "getfile_window",
      "getnotificationcount",
      "getgrewn",
      "getiusers",
      "GETscreen",
      "GETheight",
      "GETvideo",
      "GETencrypt",
      "getnumber",
      "GETwidth"
    ])
  },
  created() {
    this.set();
  },
  methods: {
    set() {
      if (this.GETscreen === "desktop") var reqWidth = this.reqWidth;
      else reqWidth = this.GETwidth;
      var _c = this.options.p[0].name.split("?");
      if (_c[1]) {
        var c = _c[1].split("=")[1].split("X");
        var w = JSON.parse(c[0]),
          h = JSON.parse(c[1]);
        if (w > reqWidth) {
          this.height = Math.round((h / w) * reqWidth);
          this.width = reqWidth;
        } else {
          this.height = h;
          this.width = w;
        }

        this.mt = (this.bigest - this.height) / 2;

        if (this.bigest == h && this.bigest != this.height) {
          this.againBigSet(this.height);
        }
      } else {
        this.dmsProv = false;
        this.height = "auto";
        this.width = reqWidth;
      }
    },
    gt(e) {
      if (this.dmsProv === false) {
        var a = e.path[0].clientHeight,
          b = e.path[3].clientHeight;
        console.log(b, a);
        if (b === a) {
          this.mt = (b - a) / 2;
        } else {
          this.againBigSet(a);
        }
      }
    },
    againBigSet(d) {
      this.$emit("checkBigest", d);
    }
  },
  watch: {
    bigest() {
      this.set();
    },
    reqWidth(){
      this.set();
    }
  }
};
</script>
