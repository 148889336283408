import config from "@/api.js";
import Axios from "axios";

const getDefaultState = () => {
  return {
    users: { profile: { profilepic: "1.svg" } },
    address: {},
    my_connectors: [],
    msgu: "",
    userq1: false,
    AlreadyOnline: false,
    loadingPrPic: false
  };
};
const account = {
  state: getDefaultState(),
  mutations: {
    SET_Users(state) {
      var u = localStorage.getItem("credentials");

      if (u) {
        var i = JSON.parse(u);

        state.users.profile = i;
        state.userq1 = true;
      }
    },
    SET_Visited(state, data) {
      state.last_visitors.push(data);
    },

    SET_address(state, data) {
      state.address = data;
    },
    SET_myconnectors(state, data) {
      state.my_connectors = data;
    },

    SET_msgu(state, data) {
      state.msgu = data;
    },
    SET_update(state, data) {
      var _f = data.first_name;
      var _l = data.last_name;
      var _g = data.gender;
      var _r = data.role;
      state.users.profile.first_name = _f;
      state.users.profile.last_name = _l;
      state.users.profile.gender = _g;
      state.users.profile.role = _r;
      var u = state.users.profile;
      localStorage.setItem("credentials", JSON.stringify(u));
    },
    setprofilepicnext(state, data) {
      state.users.profile.profilepic = data;
      var u = state.users.profile;
      localStorage.setItem("credentials", JSON.stringify(u));
    },
    setAlertSeen(state, data) {
      state.users.profile.alertSeen = data;
      var u = state.users.profile;
      localStorage.setItem("credentials", JSON.stringify(u));
    },
    SET_username_again(state, data) {
      state.users.profile.username = data;
      var u = state.users.profile;
      localStorage.setItem("credentials", JSON.stringify(u));
    },
    SET_email_again(state, data) {
      state.users.profile.email = data;
      var u = state.users.profile;
      localStorage.setItem("credentials", JSON.stringify(u));
    },
    SET_phone_again(state, data) {
      state.users.profile.phone = data;
      var u = state.users.profile;
      localStorage.setItem("credentials", JSON.stringify(u));
    },

    SetAlreadyOnline(state, data) {
      state.AlreadyOnline = data;
    },
    SETAddressInside(state, data) {
      state.users.profile.address = data.address;
      state.users.profile.lon = data.lon;
      state.users.profile.lat = data.lat;
      var u = state.users.profile;
      localStorage.setItem("credentials", JSON.stringify(u));
    },
    SetDateOfBirth(state, data) {
      state.users.profile.year = data.year;
      state.users.profile.month = data.month;
      state.users.profile.date = data.date;
      var u = state.users.profile;
      localStorage.setItem("credentials", JSON.stringify(u));
    },
    SETPfPicLoad(state, data) {
      state.loadingPrPic = data;
    },

    RESET: state => {
      Object.assign(state, getDefaultState());
    }
  },
  actions: {
    loadUsers({ commit }) {
      commit("SET_Users");
    },

    load_my_connectors({ commit }) {
      Axios.get(`${config.publicPath}` + "my/connectors")
        .then(response => {
          commit("SET_myconnectors", response.data);
        })
        .catch(error => console.log(error));
    },

    New_user_identities(
      { commit },
      { first_name, last_name, gender, role, full_user }
    ) {
      Axios.post(`${config.publicPath}` + "edit/profile", {
        first_name,
        last_name,
        gender,
        role,
        full_user
      })
        .then(response => {
          commit("SET_msgu", response.data);
          commit("SETmessage", response.data);
          if (response.data === "Updated") {
            commit("SET_update", { first_name, last_name, gender, role });
          }
          setTimeout(() => {
            commit("SET_msgu", "");
          }, 2000);
        })
        .catch(error => console.log(error));
    },
    SETProfilePic({ commit }, fd) {
      commit("SETPfPicLoad", true);
      Axios.post(config.cdnPath + `upload/profilepic`, fd).then(res => {
        var msg = res.data;
        console.log(msg);
        commit("SETmessage", msg.msg);
        if (msg.msg === "Updated") {
          let pic = msg.file;
          commit("setprofilepicnext", pic);
          commit("SETPfPicLoad", false);
        }
      });
    },
    RmvProfilePic({ commit }) {
      commit("SETPfPicLoad", true);
      Axios.post(config.publicPath + `remove/profilepic`).then(res => {
        var msg = res.data;
        console.log(msg);
        commit("SETmessage", msg.msg);
        if (msg.msg === "Removed") {
          commit("setprofilepicnext", "1.svg");
          commit("SETPfPicLoad", false);
        }
      });
    }
  },
  getters: {
    getiusers(state) {
      return state.users;
    },
    getprofile(state) {
      return state.users.profile;
    },
    getilist: state => {
      return state.grewtales;
    },
    getaddress: state => {
      return state.address;
    },

    geticonnectors: state => {
      return state.my_connectors;
    },
    getimsg: state => {
      return state.msgu;
    },
    getuserq1: state => {
      return state.userq1;
    },
    GetAlreadyOnline: state => {
      return state.AlreadyOnline;
    },
    GetLoadingPrPic: state => {
      return state.loadingPrPic;
    }
  }
};
export default account;
