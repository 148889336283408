import config from "@/api.js";
import Axios from "axios";

const getDefaultState = () => {
  return {
    msgr: "",
    msgl: "",
    progressl: ""
  };
};
const registration = {
  state: getDefaultState(),
  mutations: {
    SET_msgr(state, data) {
      state.msgr = data;
    },
    SET_msglog(state, data) {
      state.msgl = data;
    },
    SET_progressl(state, data) {
      state.progressl = data;
    },
    Set_msg_registration(state) {
      state.msgr = "";
    },
    Set_msg_login(state) {
      state.msgl = "";
    },
    RESET: state => {
      Object.assign(state, getDefaultState());
    }
  },
  actions: {
    LOADsignup(
      { commit },
      {
        email,
        first_name,
        last_name,
        password,
        date,
        month,
        year,
        origin,
        verified
      }
    ) {
      Axios.post(`${config.publicPath}` + "signup?request=Self", {
        email,
        first_name,
        last_name,
        password,
        date,
        month,
        year,
        origin,
        verified
      })
        .then(response => {
          if (response.data.true_msg === true) {
        
            var user = response.data.access[0],
              token = response.data.token;
            commit("SET_TOKEN", token);
            commit("SET_USER", user);
          }

          commit("SET_msgr", response.data);
        })
        .catch(error => console.log(error));
    },

    // signin

    LOADsignin({ commit }, { email, password }) {
      Axios.post(
        `${config.publicPath}` + "login",
        { email, password },

        {
          onUploadProgress: uploadEvent => {
            var progress = Math.round(
              (uploadEvent.loaded / uploadEvent.total) * 100
            );
            commit("SET_progressl", progress);

            setTimeout(() => {
              commit("SET_progressl", "");
            }, 2000);
          }
        }
      )
        .then(response => {
          commit("SET_msglog", response.data);
        })
        .catch(error => console.log(error));
    }
  },
  getters: {
    GETmsgr: state => {
      return state.msgr;
    },
    GETmsglog: state => {
      return state.msgl;
    },
    GETprogressl: state => {
      return state.progressl;
    }
  }
};
export default registration;
