const date = require("../node_modules/date-and-time");
const config = require("@/api.js");
const store = require("./store");

function timer(payload2, time, now, a, b, c, d, response) {
  time = new Date(JSON.parse(payload2));
  now = new Date();
  a = date.default.format(time, "ddd, MMM DD YYYY hh:mm A");
  var g = date.default.format(time, "YYYY MM DD ").split(" ");
  b = a.split(" ");

  const f = date.default.isSameDay(time, now);
  const yesterday = date.default.addDays(now, -1);
  const _yesterday = JSON.stringify(yesterday);
  const h = _yesterday.split("T")[0].split("-");
  var i = false;
  const j = h[0].split('"');

  if ((g[0] === j[1] || g[0] === h[0]) && g[1] === h[1] && g[2] === h[2]) {
    i = true;
  }

  if (f === true && i === false) {
    d = "Today";
  } else if (f === false && i === true) {
    d = "Yesterday";
  } else {
    d = b[0] + " " + b[1] + " " + b[2] + " " + b[3];
  }

  response = {
    time: b[4] + " " + b[5],
    day: d
  };

  return {
    response
  };
}

function chat_file(payload) {
  const t = JSON.parse(payload.file);

  if (payload.file_type === 0) {
    var f = "";
  } else if (payload.file_type === 1) {
    f = `${config.cdnPath}` + "chat/media/v1/image/" + t[0].name;
  } else if (payload.file_type === 5) {
    var key = t[0].name.split("//");
    f = "https://media.tenor.com" + key[0];
  } else if (payload.file_type === 6) {
    key = t[0].name.split("//");
    f = "https://img.stipop.io" + key[0];
  } else {
    f = `${config.cdnPath}` + "chat/media/v1/audio/" + t[0].name;
  }

  const output = f;
  return {
    output
  };
}

function _fromself(payload3) {
  const sender = JSON.parse(payload3);
  const id = store.default.getters.getiusers.profile.id;
  if (id) {
    if (sender === id) {
      var fromSelf = true;
    } else {
      fromSelf = false;
    }
  }
  return {
    fromSelf
  };
}
function _seen(payload4) {
  var response = false;
  const seen = JSON.parse(payload4);
  if (seen === 1) {
    response = true;
  }

  return {
    response
  };
}
function replySet(data) {
  if (data !== null) {
    var p = chat_file(data);
    data.file = [p.output];
  }
  return data;
}

module.exports = { chat_file, timer, _fromself, _seen, replySet };
