<template>
  <div  class="psr fy-width_100p fy-contain_cont" style="border-radius:12px">
    <div
      :id="`FILE_COINTAINER_` + grewtale.id"
      class=" fy-auto_scroll"
      scrollbar="off"
      @touchstart="tr"
      @touchend="fa"
      :style="
        `scroll-behavior:smooth; overflow :hidden;  width:` +
          childwidth +
          `px; `
      "
    >
      <div
        :id="`FILE_COINTAINER_SECONDARY_` + grewtale.id"
        class=""
        :style="`width:` + childwidth * grewtale.file.length + `px`"
      >
        <div
          :id="`SLIDE_CONTROLLER_` + grewtale.id"
          class="disflex"
          :style="
            `margin-left:-1px; width:` +
              (childwidth * grewtale.file.length + 1) +
              `px`
          "
        >
          <section
            v-for="(file, i) in grewtale.file"
            :key="i"
            :id="`FILE_LAYER_` + reqiure + i"
            :data-active="i === premit"
            class="fy-width_100p disflex "
          >
            <div
              class="tac"
              :style="
                `width:` +
                  childwidth +
                  `px;${style};height:${bigest}px;background:black`
              "
            >
              <grewtale_image
                v-if="file.t === 1"
                :options="file"
                :all="grewtale.file"
                :premit="premit"
                :index="i"
                :id="grewtale.id"
                :bigest="bigest"
                :length="grewtale.file.length"
                @checkBigest="ft($event)"
                :reqWidth = "childwidth"
              />
              <fyvideo
                v-else-if="file.t === 2"
                :options="file"
                :premit="premit"
                :index="i"
                :bigest="bigest"
                :id="grewtale.id"
                @checkBigest="ft($event)"
                 :reqWidth = "childwidth"
              >
              </fyvideo>
            </div>
          </section>
        </div>
      </div>
    </div>

    <!--  <div v-if = 'grewtale.file.length === 0'
          @click="gt()"
          @scroll="sc"
          :id="`MODEL_SCROLLER_` + grewtale.id"
          scrollbar="off"
          class="psa fy-width_100p fy-height_100p fy-auto_scroll fy-top_0 fy-right_0"
        >
          <div
            class="fy-height_100p"
            :style="` width:` + childwidth * grewtale.file.length + 1 + `px`"
          >
            <button
             @touchstart="pointer = true"
             @touchend="pointer = false"
              :id="`SLIDE_CONTROLLER_` + grewtale.id"
              class="fy-height_100p disflex"
              :style="
                `margin-left:-1px; padding:0!important; width:` +
                  childwidth * grewtale.file.length +
                  `px`
              "
            >
              <div
                v-for="(n, i) in grewtale.file"
                :key="i"
                class="fy-height_100p"
                :style="`width:` + childwidth + `px`"
              ></div>
            </button>
          </div>
        </div> -->
    <div
      v-if="premit !== 0 && grewtale.file.length > 1"
      @click="_previous"
      class="fy-width_30px fy-cursor_point tac fy-height_30px fy-left_5 fy-top_0 psa fy-border_50 fy-beep"
      style="top:calc(50% - 15px)"
    >
      <icons
        class=""
        excel="circleArrow"
        routerActive="false"
        hoverActive="false"
        message="Image"
        color="var(--fy-font-applyed-4)"
        width="30"
        height="30"
      />
    </div>
    <div
      v-if="premit !== grewtale.file.length - 1 && grewtale.file.length > 1"
      @click="_next"
      class="fy-width_30px fy-cursor_point tac fy-height_30px fy-right_5 fy-top_0 psa fy-border_50 fy-beep "
      style="top:calc(50% - 15px)"
    >
      <icons
        class="fy-mirror"
        excel="circleArrow"
        routerActive="false"
        hoverActive="false"
        message="Image"
        color="var(--fy-font-applyed-4)"
        width="30"
        height="30"
      />
    </div>

    <div
      v-if="grewtale.file.length > 1"
      class=" fy-width_100p  psa fy-padding-l-5 fy-padding-r-5 fy-bottom_5 fy-left_0"
    >
      <div
        class=" fy-padding-r-5 fy-padding-l-5 fy-width_100p fy-beep fy-border_r8 "
        style="padding-top:2px; height:6px"
      >
        <div class="fy-photobar psr fy-width_100p fy-height_2px">
          <div
            class="fy-height_2px fy-white_bg psa  fy-left_0 fy-bottom_0"
            :style="
              `width:` + width + `%;transition: .3s; margin-left:` + left + `%`
            "
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.fy-photobar {
  background: rgb(137, 137, 137);
  margin-bottom: 4px;
}
.fy-height_2px {
  height: 2px;
}
.fy-white_bg {
  background: white;
}
</style>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      premit: 0,
      lastpremit: null,
      width: 100,
      left: 0,
      childwidth: 526,
      manager1: false,
      pointer: false,
      move: null,
      reqiure: 0,
      extra: 0,
      baseextra: 0,
      pointsarray: [0],
      bug1: 0,
      height: 620,
      childWidth: 526,
      style: "",
      bigest: 0,
      reqWidth : 156
    };
  },
  props: {
    grewtale: null
  },
  computed: {
    ...mapGetters([
      "GETcdnlink",
      "GETscreen",
      "GETheight",
      "GETencrypt",
      "GETwidth",
      "GETmaxheight",
      "GETwideScreen"
    ])
  },
  created() {
    this.bu();
    this.reqiure = Date.now();
    this.widthset();
    const r = this.grewtale.file.length;
    this.width = 100 / r;
    this.lastpremit = r - 1;
    this.setpoints(r);
    if (this.grewtale.file.length > 1) this.setmaxHeight();
  },
  mounted() {},
  methods: {
    ft(d) {
      this.bigest = d;
    },
    bu() {
      var maxHeight =
        this.GETscreen === "desktop"
          ? this.GETmaxheight - 80
          : this.GETmaxheight - 130;

      var s = this.grewtale.file;
      var bigest = 0;
      if (s) {
        for (let i = 0; i < s.length; i++) {
          if (s[i].t == 2) var n = s[i].p[0].name;
          else n = s[i].name;
          var _d = n.split("?");
          // console.log(_d);
          if (_d[1]) {
            var d = _d[1].split("=")[1].split("X");
            var f = JSON.parse(d[1]);
          } else {
            f = this.GETmaxheight - 130;
          }

          if (f > bigest) {
            bigest = f;
          }
        }
        if (bigest > maxHeight) {
          bigest = maxHeight;
        }
        this.bigest = bigest;
      }
    },
    setmaxHeight() {
      this.height =
        this.GETscreen === "desktop"
          ? this.GETmaxheight - 80
          : this.GETmaxheight - 130;

      this.height = this.bigest;
      if (this.GETscreen === "desktop") {
        if(this.GETwideScreen === true){
          this.childwidth = 526;
        }else{
           this.childwidth = 506;
        }
        
      } else {
        this.childwidth = this.GETwidth;
      }
      this.style =
        this.grewtale.file.length > 1 ? `height:${this.height}px` : "";
    },
    setpoints(a) {
      for (var i = 0; i < a; i++) {
        var b = (i + 1) * this.childwidth;
        this.pointsarray.push(b);
      }
      // console.log(this.pointsarray);
    },
    tr() {
      this.pointer = true;
      this.bug1 = this.bug1 + 1;
    },
    fa() {
      this.pointer = false;
      this.bug1 = this.bug1 + 1;
    },

    gt() {},

    pp(a) {
      if (this.move === "<" && this.lastpremit !== this.premit && a === false) {
        this._next();
      } else if (this.move === ">" && this.premit !== 0 && a === false) {
        this._previous();
      } else if (a === true) {
        var f = this.pointsarray[this.premit];
        this.scrollcontrol(f);
      }
      this.move = null;
    },
    _next() {
      if (this.lastpremit !== this.premit) {
        this.premit = this.premit + 1;
        this.extra = this.pointsarray[this.premit];

        this.left = this.width * this.premit;
        var f = this.pointsarray[this.premit];
        this.scrollcontrol(f);
      }
    },
    _previous() {
      if (this.premit !== 0) {
        this.premit = this.premit - 1;
        this.extra = this.pointsarray[this.premit];

        this.left = this.left - this.width;
        var f = this.pointsarray[this.premit];
        this.scrollcontrol(f);
      }
    },
    scrollcontrol(a) {
      document
        .getElementById("FILE_COINTAINER_" + this.grewtale.id)
        .scrollTo(a, 0);
    },
    widthset() {
      if (this.GETscreen === "desktop") {
       if(this.GETwideScreen === true){
          this.childwidth = 526;
        }else{
       
           this.childwidth = 506;
        }
      } else {
        this.childwidth = this.GETwidth;
      }
    }
  },
  watch: {
    grewtale() {
      // console.log(this.grewtale);
    },
    GETscreen() {
      this.widthset();
    },
    GETwidth() {
      this.pointsarray = [0];
      this.setpoints(this.grewtale.file.length);
    },
    pointer() {
      var y = this.pointsarray.indexOf(this.extra);

      if (y === -1 && this.pointer === false) {
        this.pp(false);
      }
      //this.bug1 = this.bug1 + 1;
    },
    bug1() {
      if (this.bug1 === 0 || this.bug1 % 2 === 0) {
        this.pp(true);
        this.bug1 = 0;
      }
    },
    GETwideScreen(){
     
      this.widthset();
    const r = this.grewtale.file.length;
    this.width = 100 / r;
    this.lastpremit = r - 1;
    this.setpoints(r);
    }
  }
};
</script>
