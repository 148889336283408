<template>
  <div
    class="psf fy-top_0 fy-right_0 fy-width_100p fy-height_100p fy-portlet-active--bg"
  >
    <div class=" fy-portlet__body--fit-y fy-margin-t-30">
      <div class="fy-widget fy-widget--user-profile-1 tac">
        <div class="fy-margin-t-60">
          '
          <img
            class="fy-padding-5 fy-width_150px"
            src="https://cdn-icons-png.flaticon.com/512/5089/5089990.png"
          />
          <div class="fy-margin-t-20" style="font-size:40px">
            Connection problem.
          </div>
          <div class="fy-margin-t-10 fy-font-18">
            May be connection is lost or internet is disconnected.
          </div>
        </div>
        <div
          class="fy-padding-r-60 fy-padding-l-60 fy-padding-t-5 fy-padding-b-5 fy-margin-t-20"
        >
          <div
            class=""
            :class="{
              'fy-width_30p': GETscreen === 'desktop',
              'fy-width_100p': GETscreen === 'mobile'
            }"
          >
            <button
              @click="refresh"
              class="fy-mrgin-t-15 fy-padding-5 fy-text-7 fy-font-16 fy-border_r8 fy-portlet-active--br"
            >
              Refresh
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  methods: {
    refresh() {
      this.$store.dispatch("Checkapi");
    }
  },
  computed: {
    ...mapGetters(["GETapilink", "GETscreen"])
  }
};
</script>
