<template>
  <div class="fy-height_100p">
    <div class=" mrgn tac bsn psr fy-height_100p">
      <div v-if="on === false" class=" psr"></div>

      <img
        @load="gt($event)"
        @click="show(GETcdnlink + `grewtale/media/v1/photo/` + options.name)"
        :id="`IMG_GREWTALE_B` + index"
        class="fy-media_output fy-height_auto "
        :src="src"
        :style="`margin-top:${mt}px;width:${width}px;height:${height}px`"
      />
    </div>
  </div>
</template>
<style scoped>
.loader {
  border: 7px solid transparent; /* Light grey */
  border-top: 7px solid var(--fy-space-rct-100); /* Blue */
  border-radius: 50%;
  width: 90px;
  height: 90px;
  animation: spin 0.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      bgwidth: "100%",
      bgheight: "100%",
      on: false,
      visible: true,
      sc_active: false,
      marginTop: 0,
      marginTopActive: false,
      newStyle: "",
      height: 0,
      width: 0,
      mt: 0,
      src: undefined,
      dmsProv: true
    };
  },

  props: {
    options: {},
    index: null,
    premit: null,
    id: null,
    length: null,
    all: null,
    bigest: null,
    reqWidth : null
  },
  computed: {
    ...mapGetters(["GETcdnlink", "GETscreen", "GETwidth"])
  },

  created() {
    this.set();
  },
  mounted() {
    this.construct();
  },
  methods: {
    set() {
      if (this.GETscreen === "desktop") var reqWidth = this.reqWidth;
      else reqWidth = this.GETwidth;
      var _c = this.options.name.split("?");
      if (_c[1]) {
        var c = _c[1].split("=")[1].split("X");
        var w = JSON.parse(c[0]),
          h = JSON.parse(c[1]);
        if (w > reqWidth) {
          this.height = Math.round((h / w) * reqWidth);
          this.width = reqWidth;
        } else {
          this.height = h;
          this.width = w;
        }

        this.mt = (this.bigest - this.height) / 2;

        if (this.bigest == h && this.bigest != this.height) {
          this.againBigSet(this.height);
        }
      } else {
        this.dmsProv = false;
        this.height = "auto";
        this.width = reqWidth;
      }
    },
    gt(e) {
      if (this.dmsProv === false) {
        var a = e.path[0].offsetHeight,
          b = e.path[1].offsetHeight;
        this.mt = (b - a) / 2;
      }
    },
    againBigSet(d) {
      this.$emit("checkBigest", d);
    },
    setStyle(d) {
      if (d.t === "Set") this.newStyle = `height:${d.h}px;`;
      else this.newStyle = `height:auto;`;
    },

    construct() {
      if (
        this.premit == this.index &&
        this.on === false &&
        this.sc_active === false
      ) {
        //this.bgdimension();
        this.src =
          this.GETcdnlink + `grewtale/media/v1/photo/` + this.options.name;
      }
    },

    bg(h, w) {
      this.bgwidth = w;
      this.bgheight = h;
    },
    ht() {
      // }
    }
  },
  watch: {
    maxHeight() {
      this.bgdimsheight();
      //if (this.marginTopActive === true) //this.ht();
    },
    bigest() {
      this.set();
    },

    premit() {
      this.construct();
      if (
        this.sc_active === true &&
        this.on === true &&
        this.premit == this.index
      ) {
        this.ht();
      }
    }
  }
};
</script>
