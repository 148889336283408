<template>
  <span class="fy-ta_auto fy-width_100p fy-fit_height">
    <span v-if="simple" v-html="simple"></span>
    <span class="fy-fit_width" v-else>
      <span class="fy-fit_width" v-for="(c, i) in msg" :key="i">
        <span class="fy-fit_width" v-if="typeof c === 'object'">
          <span v-for="(t, j) in c" :key="j">
            <span v-html="t.initial"></span
            ><span class="fy-main_logo fy-cursor_point"
              ><span v-if="t.link.charAt(0) === '@'"
                ><router-link
                  class="fy-width_100p fy-height_100p"
                  :to="'/' + t.link.slice(1, t.link.length)"
                  >{{ t.link }}</router-link
                ></span
              ><span v-else
                ><a
                  class="fy-width_100p fy-height_100p"
                  :href="t.link"
                  target="_blank"
                  ><span v-html="t.link"></span></a></span
            ></span>
          </span>
        </span>
        <span class="fy-fit_width" v-else-if="c === ''"><br /></span>
        <span class="fy-fit_width" v-else v-html="c"></span>
      </span>
    </span>
  </span>
</template>

<script>
export default {
  data() {
    return {
      simple: "",
      msg: [],
      f: false,
      webmeta: ""
    };
  },
  props: {
    strg: null
  },
  components: {
    //  webmeta
  },
  created() {
    this.pre();
  },

  methods: {
    pre() {
      var n = [];
      const el = document.createElement("div");
      el.innerText = this.strg;
      var s = el.innerHTML;
      // console.log(s);
      var expes = /(((https?:\/\/)|(www\.)|(http?:`\/\/)|(@))[^\s|^<]+)/g;
      const p = s.match(expes),
        expes2 = /(<br>)/,
        t = s.split(expes2);

      if (p === null && t === null) {
        this.msg = s;
      } else {
        for (let x = 0; x < t.length; x++) {
          if (t[x] !== "") {
            if (t[x] === "<br>" && t[x] === undefined) n.push("");
            else n.push(this.ft(t[x]));
          }
        }

        this.msg = n;
      }
    },
    ft(d) {
      var strngarray = [];
      ///&(nbsp;|amp|quot|lt|gt|)
      var expes = /(((https?:\/\/)|(www\.)|(http?:`\/\/)|(@))[^\s|^<]+)/g;
      const p = d.match(expes);

      if (p === null) {
        strngarray = d;
      } else {
        for (var i = 0; i < p.length; i++) {
          var block = d.indexOf(p[i]);
          var block2 = block + p[i].length;
          var link = d.slice(block, block2);
          if (i === 0) {
            var initial = d.slice(0, block);
          } else {
            var _block2 = d.indexOf(p[i - 1]) + p[i - 1].length;
            initial = d.slice(_block2, block);
          }

          strngarray.push({ initial, link });
          if (p.length - 1 === i && block2 !== d.length) {
            initial = d.slice(block2);
            link = "";
            strngarray.push({ initial, link });
          }

          if (link && this.webmeta === "" && link.charAt(0) !== "@") {
            this.webmeta = link;
          }
        }
      }
      return strngarray;
    },
    link(data) {
      //   console.log(data);
      var spect = data.slice(0, 1);
      if (spect === "@") {
        this.$router.push(`/` + data.slice(1));
      } else if (spect === "#") {
        this.$router.push(`/` + data.slice(1));
      } else {
        var newexpes = /(((https?:\/\/)|(http?:\/\/))[^\s]+)/g;
        const check = data.match(newexpes);
        if (check === null) {
          data = "http://" + data;
        }
        window.open(data, "_blank");
      }
    }
  },
  watch: {
    strg() {
      this.pre();
    }
  }
};
</script>
