<template>
  <div
    class="psr fy-width_100p"
    :style="`width:${width}px;height:${height}px;margin-top:${mt}px`"
  >
    <button
      v-if="videoAccess === false"
      @click="_player.play()"
      class="fy-width_60px fy-height_60px fy-zi_1 fy-beep fy-border_50 psa"
      style="top:calc(50% - 30px); right:calc(50% - 30px); "
    >
      <icons
        style="margin-left:3px"
        class=""
        excel="play"
        routerActive="false"
        hoverActive="false"
        message="Reset"
        color="var(--fy-font-applyed-4)"
        width="30"
        height="30"
      />
    </button>
    <video
      @play="playToggle(true)"
      @pause="playToggle(false)"
      v-observe-visibility="videovisibility"
      ref="videoPlayer"
      class="video-js"
      :id="`GREWTALE_PLAYER_` + id_maker"
      referrerpolicy="strict-origin-when-cross-origin"
      :poster="GETcdnlink + `grewtale/media/v1/thumbnails/` + options.p[0].name"
      :style="`width:${width}px;height:${height}px;`"
      data-setup='{"playbackRates":[0.5, 1, 1.5, 2]}'
    ></video>
  </div>
</template>
<style>
.video-js {
  width: 100%;
}
.video-js .vjs-play-progress {
  background-color: var(--fy-main-logo) !important;
}
.video-js .vjs-control-bar {
  background-color: transparent !important;
}
.vjs-big-play-button {
  display: none !important;
}
picture.vjs-poster {
  display: none;
}
</style>
<script>
import videojs from "video.js";
import { mapGetters } from "vuex";
export default {
  name: "VideoPlayer",
  props: {
    options: { 
      type: Object,
      default() {
        return {};
      }
    },
    height: null,
    width: null,
    mt: null
  },
  data() {
    return {
      videoAccess: true,
      id_maker: "",
      videoOptions: {
        autoplay: true,
        playbackRates: [0.5, 1, 1.5, 2],
        isAudio: false,
        controls: true,
        controlBar: {
          playToggle: true,
          pictureInPictureToggle: false,
          progressControl: {
            seekBar: true
          },
          fullscreenToggle: true
        },
        sources: [
          {
            src: "",
            type: "video/mp4"
          }
        ],
        playerinplayer: false,
        language: "en"
      }
    };
  },
  computed: {
    ...mapGetters([
      "getgrewtales",
      "GETcdnlink",
      "getplotters",
      "getplotter_msg",
      "getfile_window",
      "getnotificationcount",
      "getgrewn",
      "getiusers",
      "GETscreen",
      "GETheight",
      "GETvideo",
      "GETencrypt",
      "getnumber"
    ]),
    _player() {
      return this.$refs.videoPlayer;
    }
  },
  created() {
    this.id_maker = Date.now();
  },

  mounted() {
    var options = this.source();
    this.player = videojs(this._player, options, () => {});
    this._player;
  },
  methods: {
    playToggle(a) {
      this.videoAccess = a;
      if (a === true) {
        this.$store.commit(
          "SETvideo",
          `GREWTALE_PLAYER_` + this.id_maker + `_html5_api`
        );
      } else {
        if (this.GETvideo === `GREWTALE_PLAYER_` + this.id_maker + `_html5_api`)
          this.$store.commit("SETvideo", "");
      }
    },
    source() {
      var options = this.videoOptions;
      this.videoOptions.sources[0].src =
        this.GETcdnlink + `grewtale/media/v1/videos/` + this.options.name;
      this.videoOptions.poster =
        this.GETcdnlink +
        `grewtale/media/v1/thumbnails/` +
        this.options.p[0].name;
      if (this.GETscreen === "mobile") {
        options.controlBar.fullscreenToggle = false;
        options.playbackRates = [];
      }
      return options;
    },
    videovisibility(entry) {
      //var id = html.target.id;
      if (
        entry === false &&
        this.GETvideo === `GREWTALE_PLAYER_` + this.id_maker + `_html5_api`
      ) {
        this._player.pause();
      }
    }
  },
  beforeUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  },
  watch: {
    GETvideo() {
      if (
        this.GETvideo !== `GREWTALE_PLAYER_` + this.id_maker + `_html5_api` &&
        this.videoAccess === true
      )
        this._player.pause();
    }
  }
};
</script>
