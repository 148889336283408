const getDefaultState = () => {
  return {
    screen: "desktop",
    Search_panel: false,
    screen_width: 0,
    screen_height: 0,
    maxHeight: 0,
    slide: false,
    logout: false,
    internetConnection: true,
    relation_panel: false,
    relation_panelr: false,
    address_panel: false,
    header_stress: false,
    icon_position: true,
    into1: false,
    into2: false,
    comment_panel: false,
    report: false,
    about_sub: "",
    grewtale_delete: "",
    zoom: 100,
    hover: "",
    sharePanel: "",
    comment_panel_bottom: 42,
    comment_panel_bottom2: 42,
    encrypt: "",
    video: "",
    message_path: "/message",
    gif_msg: "",
    baseurl: "",
    mousePosition: "",
    mouseClick: "",
    externalInput: "",
    externalInputData: "",
    externalInputBackController: 0,
    fade: false,
    Click: null,
    routeHistory: {
      fullPath: "/",
      matched: [{ path: "" }, { path: "/" }, { path: "/" }]
    },
    mobileMenuBottom: true,
    onlineRtl: false,
    wideScreen: false,
    grewtaleUnderbarType : null
  };
};
const framework = {
  state: getDefaultState(),

  mutations: {
    SET_base_url(state) {
      state.baseurl = window.location.origin + "/";
    },
    SET_Search_panel: state => {
      state.Search_panel = !state.Search_panel;
    },
    SET_logout: state => {
      state.logout = !state.logout;
    },
    SETslide(state, data) {
      state.slide = data;
    },
    LOADreport_panel(state) {
      state.report = !state.report;
    },
    SETcomment_panel(state) {
      state.comment_panel = !state.comment_panel;
    },
    SETcomment_panel_bootom(state, data) {
      state.comment_panel_bottom = data;
    },
    SETcomment_panel_bootom2(state, data) {
      state.comment_panel_bottom2 = data;
    },
    Set_msg_path(state, data) {
      state.message_path = data;
    },

    screendetector(state) {
      //const screen = new ScreenSizeDetector();
      state.screen_width = window.innerWidth;
      state.screen_height = window.innerHeight;
      state.maxHeight = window.outerHeight;

      if (screen.width > 1024 && window.innerWidth > 1024) {
        state.screen = "desktop";
      } else {
        state.screen = "mobile";
      }
      state.zoom = Math.round((screen.width / window.innerWidth) * 100);
    },
    LOADrelation_panel(state) {
      state.relation_panel = !state.relation_panel;
    },
    LOADrelation_panelr(state) {
      state.relation_panelr = !state.relation_panelr;
    },
    LOADaddress_panel(state) {
      state.address_panel = !state.address_panel;
    },
    SETheader_stress(state, data) {
      state.header_stress = data;
    },
    set_icon(state, data) {
      state.icon_position = data;
    },
    SETaboutsub(state, data) {
      state.about_sub = data;
    },
    Insertinto1(state) {
      state.into1 = true;
    },
    Insertinto2(state) {
      state.into2 = true;
    },
    SETencrypt(state, data) {
      state.encrypt = data;
    },
    SETvideo(state, data) {
      state.video = data;
    },
    SETSharePanel(state, data) {
      state.sharePanel = data;
    },
    SETgrewtale_delete(state, data) {
      state.grewtale_delete = data;
    },
    //gif_msg
    msg_gif(state, data) {
      state.gif_msg = data;
    },
    fadeController(state, data) {
      state.fade = data.c;
      if (data.c === true) state.routeHistory = data.route;
    },
    HoverOne(state, data) {
      state.hover = data;
    },
    MousePositionSet(state, data) {
      state.mousePosition = data;
    },
    MouseClickSet(state, data) {
      state.mouseClick = data;
    },
    SETexternalInput(state, data) {
      state.externalInput = data;
    },
    SETexternalInputData(state, data) {
      state.externalInputData = data;
    },
    SETexternalInputBackController(state) {
      state.externalInputBackController = state.externalInputBackController + 1;
    },
    SETmobileMenuBottom(state, data) {
      state.mobileMenuBottom = data;
    },
    SETinternetConnection(state, data) {
      state.internetConnection = data;
    },
    SETClick(state, data) {
      state.Click = data;
    },
    SetonlineRtl(state, data) {
      state.onlineRtl = data;
    },
    SETwideScreen(state, data){
      state.wideScreen = data;
    },
    SetGrewtaleUnderbarType(state, data){
      state.grewtaleUnderbarType = data;
    }
  }, 
  actions: {},
  getters: {
    GETbaseurl: state => {
      return state.baseurl;
    },
    GETSearchpanel: state => {
      return state.Search_panel;
    },
    GETscreen: state => {
      return state.screen;
    },
    GETwidth: state => {
      return state.screen_width;
    },
    GETzoom: state => {
      return state.zoom;
    },
    GETheight: state => {
      return state.screen_height;
    },
    GETmaxheight: state => {
      return state.maxHeight;
    },
    GETslide: state => {
      return state.slide;
    },
    GETlogout: state => {
      return state.logout;
    },
    GETrelation_panel: state => {
      return state.relation_panel;
    },
    GETrelation_panelr: state => {
      return state.relation_panelr;
    },
    GETaddress_panel: state => {
      return state.address_panel;
    },
    GETheader_stress: state => {
      return state.header_stress;
    },
    GETicon_position: state => {
      return state.icon_position;
    },
    GETinto1: state => {
      return state.into1;
    },
    GETinto2: state => {
      return state.into2;
    },
    GETreport_panel: state => {
      return state.report;
    },
    GETsubabout: state => {
      return state.about_sub;
    },
    GETencrypt: state => {
      return state.encrypt;
    },
    GETvideo: state => {
      return state.video;
    },
    GETcomment_panel: state => {
      return state.comment_panel;
    },
    GETgrewtale_delete: state => {
      return state.grewtale_delete;
    },
    GETcomment_panel_bottom: state => {
      return state.comment_panel_bottom;
    },
    GETcomment_panel_bottom2: state => {
      return state.comment_panel_bottom2;
    },
    GETmessage_path: state => {
      return state.message_path;
    },
    GETgif_msg: state => {
      return state.gif_msg;
    },
    GETfade: state => {
      return state.fade;
    },
    GETrouteHistory: state => {
      return state.routeHistory;
    },
    GEThover: state => {
      return state.hover;
    },
    GETmousePosition: state => {
      return state.mousePosition;
    },
    GETmouseClick: state => {
      return state.mouseClick;
    },
    GETexternalInput: state => {
      return state.externalInput;
    },
    GETexternalInputData: state => {
      return state.externalInputData;
    },
    GETexternalInputBackController: state => {
      return state.externalInputBackController;
    },
    GETmobileMenuBottom: state => {
      return state.mobileMenuBottom;
    },
    GETinternetConnection: state => {
      return state.internetConnection;
    },
    GETSharePanel: state => {
      return state.sharePanel;
    },
    GETClick: state => {
      return state.Click;
    },
    GETonlineRtl(state) {
      return state.onlineRtl;
    },
    GETwideScreen(state) {
      return state.wideScreen;
    },
    GETgrewtaleUnderbarType(state){
      return state.grewtaleUnderbarType;
    },
  }
};
export default framework;
