const getDefaultState = () => {
  return {
    active: false,
    link: "",
    route: "",
    final_file: "",
    index: "",
    grewtaleData: ""
  };
};
const img_editor = {
  state: getDefaultState(),
  mutations: {
    SETimgedi(state, data) {
      state.active = true;
      state.link = data.link;
      state.route = data.route;
      state.index = data.index;
    },
    SETimgff(state, data) {
      state.final_file = data;
    },
    SETgrewtaleData(state, data) {
      state.grewtaleData = data;
    },
    RESETimgedi(state) {
      state.link = "";
    },
    RESETimgediwl(state) {
      state.active = false;
    },
    RESETimgff(state) {
      state.route = "";
      state.final_file = "";
      state.link = "";
    },
    RESET: state => {
      Object.assign(state, getDefaultState());
    }
  },

  getters: {
    getieactive: state => {
      return state.active;
    },
    getielink: state => {
      return state.link;
    },
    getieroute: state => {
      return state.route;
    },
    getiefinal_file: state => {
      return state.final_file;
    },
    getieindex: state => {
      return state.index;
    },
    getgrewtaleData: state => {
      return state.grewtaleData;
    }
  }
};
export default img_editor;
