import config from "@/api.js";
import Axios from "axios";
const getDefaultState = () => {
  return {
    users: [],
    usersNext: null,
    past: [],
    pastNext: null,
    present: [],
    presentNext: null,
    future: [],
    futureNext: null,
    word: [],
    wordNext: null,
    grewtale: [],
    grewtaleNext: null,
    user_e: false,
    past_e: false,
    present_e: false,
    future_e: false,
    word_e: false,
    grewtale_e: false,
    user_l: false,
    past_l: false,
    present_l: false,
    future_l: false,
    word_l: false,
    grewtale_l: false,
    user_n: false,
    past_n: false,
    present_n: false,
    future_n: false,
    word_n: false,
    grewtale_n: false,
    user_m: false,
    past_m: false,
    present_m: false,
    future_m: false,
    word_m: false,
    grewtale_m: false,
    s1: false,
    s2: false,
    s3: false,
    s4: false,
    s5: false,
    s6: false
  };
};
const searching = {
  state: getDefaultState(),
  mutations: {
    // users +# 3

    user_s(state, data) {
      console.log(data);
      if (data.data === "nothing" && state.users.length === 0) {
        state.user_n = true;
      } else if (data.data === "nothing" && state.users.length > 0) {
        state.user_m = true;
      } else {
        if (data.next === null) state.user_m = true;
        data = data.data;
        for (var i = 0; i < data.length; i++) {
          state.users.push({
            id: data[i].id,
            first_name: data[i].first_name,
            last_name: data[i].last_name,
            profilepic: data[i].profilepic,
            username: data[i].username,
            role: data[i].role,
            address: data[i].address
          });
        }
      }
    },

    // past +# 3

    past_s(state, data) {
      console.log(data);
      if (data.data === "nothing" && state.past.length === 0) {
        state.past_n = true;
      } else if (data.data === "nothing" && state.past.length > 0) {
        state.past_m = true;
      } else {
        if (data.next === null) state.past_m = true;
        data = data.data;
        for (var i = 0; i < data.length; i++) {
          var fields = {
            first_name: data[i].first_name,
            last_name: data[i].last_name,
            profilepic: data[i].profilepic,
            username: data[i].username,
            name: data[i].name,
            type: data[i].type,
            file: data[i].file,
            file_type: data[i].file_type,
            _date: data[i].created_at
          };

          state.past.push({
            id: fields.id,
            first_name: fields.first_name,
            last_name: fields.last_name,
            profilepic: fields.profilepic,
            username: fields.username,
            name: fields.name,
            type: fields.type,
            file: fields.file,
            file_type: fields.file_type,
            created_at: fields._date
          });
        }
      }
    },

    // present +# 3

    present_s(state, data) {
      if (data.data === "nothing" && state.present.length === 0) {
        state.present_n = true;
      } else if (data.data === "nothing" && state.present.length > 0) {
        state.present_m = true;
      } else {
        if (data.next === null) state.present_m = true;
        data = data.data;
        for (var i = 0; i < data.length; i++) {
          var fields = {
            first_name: data[i].first_name,
            last_name: data[i].last_name,
            profilepic: data[i].profilepic,
            username: data[i].username,
            name: data[i].name,
            type: data[i].type,
            file: data[i].file,
            file_type: data[i].file_type,
            _date: data[i].created_at
          };

          state.present.push({
            id: fields.id,
            first_name: fields.first_name,
            last_name: fields.last_name,
            profilepic: fields.profilepic,
            username: fields.username,
            name: fields.name,
            type: fields.type,
            file: fields.file,
            file_type: fields.file_type,
            created_at: fields._date
          });
        }
      }
    },

    // future +# 3

    future_s(state, data) {
      if (data.data === "nothing" && state.future.length === 0) {
        state.future_n = true;
      } else if (data.data === "nothing" && state.future.length > 0) {
        state.future_m = true;
      } else {
        if (data.next === null) state.future_m = true;
        state.wordNext = data.next;
        data = data.data;
        for (var i = 0; i < data.length; i++) {
          var fields = {
            first_name: data[i].first_name,
            last_name: data[i].last_name,
            profilepic: data[i].profilepic,
            username: data[i].username,
            name: data[i].name,
            type: data[i].type,
            file: data[i].file,
            file_type: data[i].file_type,
            _date: data[i].created_at
          };

          state.future.push({
            id: fields.id,
            first_name: fields.first_name,
            last_name: fields.last_name,
            profilepic: fields.profilepic,
            username: fields.username,
            name: fields.name,
            type: fields.type,
            file: fields.file,
            file_type: fields.file_type,
            created_at: fields._date
          });
        }
      }
    },

    // word +# 3

    word_s(state, data) {
      if (data.data === "nothing" && state.word.length === 0) {
        state.word_n = true;
      } else if (data.data === "nothing" && state.word.length > 0) {
        state.word_m = true;
      } else {
        if (data.next === null) state.word_m = true;
        data = data.data;
        for (var i = 0; i < data.length; i++) {
          var fields = {
            first_name: data[i].first_name,
            last_name: data[i].last_name,
            profilepic: data[i].profilepic,
            username: data[i].username,
            name: data[i].name,
            meaning: data[i].meaning,
            type: data[i].type,
            file: data[i].file,
            file_type: data[i].file_type,
            _date: data[i].created_at
          };

          state.word.push({
            id: fields.id,
            first_name: fields.first_name,
            last_name: fields.last_name,
            profilepic: fields.profilepic,
            username: fields.username,
            name: fields.name,
            meaning: fields.meaning,
            type: fields.type,
            file: fields.file,
            file_type: fields.file_type,
            created_at: fields._date
          });
        }
      }
    },

    // grewtale +# 3

    grewtale_s(state, data) {
      if (data.content_ob === "nothing" && state.grewtale.length === 0) {
        state.grewtale_n = true;
      } else if (data.content_ob === "nothing" && state.grewtale.length > 0) {
        state.grewtale_m = true;
      } else {
        if (data.next === null) state.grewtale_m = true;
        state.grewtaleNext = data.next;
        data = data.data;

        for (var i = 0; i < data.length; i++) {
          var fields = {
            id: data[i].id,
            who: data[i].who,
            title: data[i].title,
            w_n: data[i].w_n,
            type: data[i].type,
            content: data[i].content,
            primitive_id: data[i].primitive_id,
            created_at: data[i].created_at,
            file: data[i].file,
            file_type: data[i].file_type
          };

          state.grewtale.push({
            id: fields.id,
            primitive_id: fields.primitive_id,
            who: fields.who,
            title: fields.title,
            w_n: fields.w_n,
            type: fields.type,
            content: fields.content,
            created_at: fields.created_at,
            file: fields.file,
            file_type: fields.file_type
          });
        }
      }
    },

    // reset +#3

    reset_u(state) {
      state.users = [];
      state.past = [];
      state.present = [];
      state.future = [];
      state.word = [];
      state.grewtale = [];
      (state.usersNext = null),
        (state.pastNext = null),
        (state.presentNext = null),
        (state.futureNext = null),
        (state.wordNext = null),
        (state.grewtaleNext = null),
        (state.user_e = false);
      state.past_e = false;
      state.present_e = false;
      state.future_e = false;
      state.word_e = false;
      state.grewtale_e = false;
      state.user_l = false;
      state.past_l = false;
      state.present_l = false;
      state.future_l = false;
      state.word_l = false;
      state.grewtale_l = false;
      state.user_n = false;
      state.past_n = false;
      state.present_n = false;
      state.future_n = false;
      state.word_n = false;
      state.grewtale_n = false;
      state.user_m = false;
      state.past_m = false;
      state.present_m = false;
      state.future_m = false;
      state.word_m = false;
      state.grewtale_m = false;
    },
    // errors
    user_e(state) {
      state.user_e = true;
    },
    past_e(state) {
      state.past_e = true;
    },
    present_e(state) {
      state.present_e = true;
    },

    future_e(state) {
      state.future_e = true;
    },
    word_e(state) {
      state.word_e = true;
    },
    grewtale_e(state) {
      state.grewtale_e = true;
    },

    // loading
    user_l(state) {
      state.user_l = true;
    },
    past_l(state) {
      state.past_l = true;
    },
    present_l(state) {
      state.present_l = true;
    },
    future_l(state) {
      state.future_l = true;
    },
    word_l(state) {
      state.word_l = true;
    },
    grewtale_l(state) {
      state.grewtale_l = true;
    },
    ss1(state, data) {
      state.s1 = data;
    },
    ss2(state, data) {
      state.s2 = data;
    },
    ss3(state, data) {
      state.s3 = data;
    },
    ss4(state, data) {
      state.s4 = data;
    },
    ss5(state, data) {
      state.s5 = data;
    },
    ss6(state, data) {
      state.s6 = data;
    },
    RESET: state => {
      Object.assign(state, getDefaultState());
    }
  },
  actions: {
    loadUser_s({ commit, state }, { query }) {
      if (state.s1 === false) {
        commit("ss1", true);
        var next = state.usersNext,
          length = 15;

        Axios.post(`${config.publicPath}` + "search/users", {
          query,
          length,
          next
        })
          .then(response => {
            commit("user_s", response.data);
          })
          .catch(error => {
            commit("user_e", error);
          })
          .finally(() => {
            commit("user_l");
            commit("ss1", false);
          });
      }
    },
    loadpast_s({ commit, state }, { query, length }) {
      if (state.s2 === false) {
        commit("ss2", true);
        var next = state.pastNext;
        length = 10;
        Axios.post(`${config.publicPath}` + "search/past", {
          query,
          length,
          next
        })
          .then(response => {
            commit("past_s", response.data);
          })
          .catch(error => {
            commit("past_e", error);
          })
          .finally(() => {
            commit("past_l");
            commit("ss2", false);
          });
      }
    },
    loadpresent_s({ commit, state }, { query, length }) {
      if (state.s3 === false) {
        commit("ss3", true);
        var next = state.presentNext;
        length = 10;
        Axios.post(`${config.publicPath}` + "search/present", {
          query,
          length,
          next
        })
          .then(response => {
            commit("present_s", response.data);
          })
          .catch(error => {
            commit("present_e", error);
          })
          .finally(() => {
            commit("present_l");
            commit("ss3", false);
          });
      }
    },
    loadfuture_s({ commit, state }, { query, length }) {
      if (state.s4 === false) {
        commit("ss4", true);
        var next = state.futureNext;
        length = 10;
        Axios.post(`${config.publicPath}` + "search/future", {
          query,
          length,
          next
        })
          .then(response => {
            commit("future_s", response.data);
          })
          .catch(error => {
            commit("future_e", error);
          })
          .finally(() => {
            commit("future_l");
            commit("ss4", false);
          });
      }
    },
    loadword_s({ commit, state }, { query, length }) {
      if (state.s5 === false) {
        commit("ss5", true);
        var next = state.wordNext;
        length = 10;
        Axios.post(`${config.publicPath}` + "search/subject", {
          query,
          length,
          next
        })
          .then(response => {
            commit("word_s", response.data);
          })
          .catch(error => {
            commit("word_e", error);
          })
          .finally(() => {
            commit("word_l");
            commit("ss5", false);
          });
      }
    },

    loadgrewtale_s({ commit, state }, { query, length }) {
      if (state.s6 === false) {
        commit("ss6", true);
        var next = state.grewtaleNext;
        length = 10;
        Axios.post(`${config.publicPath}` + "search/grewtale", {
          query,
          length,
          next
        })
          .then(response => {
            commit("grewtale_s", response.data);
          })
          .catch(error => {
            commit("grewtale_e", error);
          })
          .finally(() => {
            commit("grewtale_l");
            commit("ss6", false);
          });
      }
    }
  },
  getters: {
    get_s_users: state => {
      return state.users;
    },
    get_s_past: state => {
      return state.past;
    },
    get_s_present: state => {
      return state.present;
    },
    get_s_future: state => {
      return state.future;
    },
    get_s_word: state => {
      return state.word;
    },
    get_s_grewtale: state => {
      return state.grewtale;
    },
    get_e_user: state => {
      return state.user_e;
    },
    get_e_past: state => {
      return state.past_e;
    },
    get_e_present: state => {
      return state.present_e;
    },
    get_e_future: state => {
      return state.future_e;
    },
    get_e_word: state => {
      return state.word_e;
    },
    get_e_grewtale: state => {
      return state.grewtale_e;
    },
    get_l_user: state => {
      return state.user_l;
    },
    get_l_past: state => {
      return state.past_l;
    },
    get_l_present: state => {
      return state.present_l;
    },
    get_l_future: state => {
      return state.future_l;
    },
    get_l_word: state => {
      return state.word_l;
    },
    get_l_grewtale: state => {
      return state.grewtale_l;
    },
    get_n_user: state => {
      return state.user_n;
    },
    get_n_past: state => {
      return state.past_n;
    },
    get_n_present: state => {
      return state.present_n;
    },
    get_n_future: state => {
      return state.future_n;
    },
    get_n_word: state => {
      return state.word_n;
    },
    get_n_grewtale: state => {
      return state.grewtale_n;
    },
    get_m_user: state => {
      return state.user_m;
    },
    get_m_past: state => {
      return state.past_m;
    },
    get_m_present: state => {
      return state.present_m;
    },
    get_m_future: state => {
      return state.future_m;
    },
    get_m_word: state => {
      return state.word_m;
    },
    get_m_grewtale: state => {
      return state.grewtale_m;
    }
  }
};
export default searching;
