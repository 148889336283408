import config from "@/api.js";
import Axios from "axios";
const getDefaultState = () => {
  return {
    feedback: [],
    feedbackl: true,
    feedbacknm: false,
    fmsg: ""
  };
};

const feedback = {
  state: getDefaultState(),
  mutations: {
    SET_fl(state) {
      state.feedbackl = false;
    },
    SET_feedback(state, data) {
      if (data == "nothing") {
        state.feedbacknm = true;
      } else {
        for (var i = 0; i < data.length; i++) {
          var id = data[i].id;
          var name = data[i].name;
          var email = data[i].email;
          var message = data[i].message;
          var reply = data[i].reply;
          var submmited_at = data[i].submmited_at;

          state.feedback.push({
            id: id,
            name: name,
            email: email,
            message: message,
            reply: reply,

            submmited_at: submmited_at
          });
        }
      }
    },
    SET_feedback_msg(state, index) {
      state.feedback[index].reply = 1;
    },
    SET_feedback_msg1(state, data) {
      state.fmsg = data;
      //setTimeout(() => {
      //    state.fmsg = '';
      // }, 2000);
    },
    RESET: state => {
      Object.assign(state, getDefaultState());
    }
  },
  actions: {
    LOADfeedback_g({ commit }, { length }) {
      Axios.post(`${config.publicPath}` + "feedback/g", { length })
        .then(response => {
          commit("SET_feedback", response.data);
        })
        .catch(error => console.log(error))
        .finally(() => {
          commit("SET_fl");
        });
    },
    Loadfreply({ commit }, { id, index }) {
      Axios.post(`${config.publicPath}` + "feedback/u", { id })
        .then(() => {
          commit("SET_feedback_msg", index);
        })
        .catch(error => console.log(error));
    },
    Loadfentry({ commit }, { name, email, message }) {
      Axios.post(`${config.publicPath}` + "feedback/s", {
        name,
        email,
        message
      })
        .then(response => {
          commit("SET_feedback_msg1", response.data);
          commit("SETmessage", response.data);
          setTimeout(() => {
            commit("SET_feedback_msg1", "");
            commit("SETmessage", "");
          }, 2000);
        })
        .catch(error => console.log(error));
    }
  },
  getters: {
    getfeedback: state => {
      return state.feedback;
    },
    getfeedbacknm: state => {
      return state.feedbacknm;
    },
    getfeedbackl: state => {
      return state.loading;
    },
    getfeedbackm: state => {
      return state.fmsg;
    }
  }
};
export default feedback;
