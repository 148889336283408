<template>
  <div>
    <div @click="browse(load.u)" class="fy_dis_bl fy-width_100p fy-height_100p">
      <div v-if="load" class="fy-width_100p " style="padding:0px 2px">
        <div
          class="fy-width_100p fy-bt_act fy-contain_cont   fy-border_r8"
          :style="
            `background:var(--fy-space-rve-10); box-shadow:0px 0px 1px #00000068;height:${height}; min-width:200px; `
          "
        >
          <div v-if="load.t === 2" class="fy-width_100p tac psr ">
            <img
              :src="GETcdnlink + `grewtale/media/v1/photo/` + img[0].name"
              style="max-height:300px;max-width:100%;width:auto;height:auto;"
            />
            <img
              v-show="icon"
              v-if="load.icon"
              @load="icon = true"
              :src="load.icon"
              class="fy-fit_width fy-height_25px  psa fy-zi_1 fy-bottom_5 fy-right_5"
            />
            <div
              v-if="load.video"
              class="fy-width_40px fy-height_40px tac fy-border_50 psa fy-zi_1 fy-beep"
              style="left:calc(50% - 20px); top :calc(50%  - 20px)"
            >
              <icons
                style="margin-left:3px"
                class="fy-margin-t-10"
                excel="play"
                routerActive="false"
                hoverActive="false"
                message="Reset"
                color="var(--fy-font-applyed-4)"
                width="19"
                height="19"
              />
            </div>
          </div>
          <div class="disflex">
            <div
              v-if="load.t === 1"
              class="fy-width_100px fy-height_100px fy-contain_cont tac"
            >
              <img
                :src="GETcdnlink + `grewtale/media/v1/photo/` + img[0].name"
                class="fy-height_100px"
              />
            </div>
            <div style="padding:2px">
              <div
                if="url"
                class="fy-font-14 fy-font_w700 fy-text-6 fy-ats fy-wc1"
              >
                {{ url }}
              </div>
              <div
                class="fy-font-13 fy-font_w600 fy-text-1 fy-ats"
                :class="{ 'fy-wc1': load.t === 1, 'fy-wc2': load.t === 2 }"
              >
                {{ load.T }}
              </div>
              <div class="fy-font-11 fy-font_w500 fy-text-1 ">
                <div
                  class="fy-ats"
                  :class="{ 'fy-wc3': load.t === 1, 'fy-wc2': load.t === 2 }"
                >
                  {{ load.d }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.fy-ats {
  display: -webkit-box;

  -webkit-box-orient: vertical;
  overflow: hidden;
}
.fy-wc4 {
  -webkit-line-clamp: 4;
}
.fy-wc3 {
  -webkit-line-clamp: 3;
}
.fy-wc2 {
  -webkit-line-clamp: 2;
}
.fy-wc1 {
  -webkit-line-clamp: 1;
}
</style>
<script>
import { mapGetters } from "vuex";
//import {browse} from '@/device/browser.js'
export default {
  data() {
    return {
      icon: false,

      sessionGet: false,
      url: "",
      load: ""
    };
  },
  props: {
    data: null,
    img: null
  },
  created() {},
  computed: {
    ...mapGetters(["GETapilink", "GETcdnlink", "GETheight"]),

    height() {
      if (this.load.t === 2) {
        var h = "";
      } else {
        h = "104px";
      }
      return h;
    }
  },
  mounted() {
    this.setLoad();
  },
  methods: {
    browse(a) {
      window.open(a, "_blank");
    },
    setLoad() {
      var y = `${this.data}`;

      if (y !== "undefined" && y !== undefined && y !== null && y !== "") {
        this.load = JSON.parse(y);

        var t = this.load.u;
        if (t) {
          var i = t.split("//")[1];
          if (i) {
            var o = i.split("/")[0];
            this.url = o.toUpperCase();
          }
        }
      }
    }
  },
  watch: {}
};
</script>
