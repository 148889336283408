<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="options.width"
      :height="options.height"
      viewBox="0 0 975 425"
    >
      #simple_logo Designed by Fyissh.Inc Tushar Rajput
      <g
        transform="translate(0.000000,407.000000) scale(0.100000,-0.100000)"
        fill="var(--fy-main-logo)"
        stroke="none"
      >
        <path
          d="M7790 2565 l0 -1495 185 0 185 0 0 731 0 731 108 107 c116 116 174
159 261 191 150 57 358 53 480 -10 53 -26 104 -89 126 -153 24 -69 32 -349 15
-542 -43 -513 -26 -747 70 -978 l32 -77 183 0 182 0 -35 93 c-19 50 -46 135
-60 187 -26 92 -26 103 -27 390 0 169 6 340 13 400 6 58 12 200 13 315 1 184
-1 220 -20 288 -72 270 -291 430 -624 456 -263 21 -474 -35 -654 -175 l-58
-45 -3 540 -2 541 -185 0 -185 0 0 -1495z"
        />
        <path
          d="M5238 4030 c-106 -19 -186 -112 -195 -224 -7 -84 13 -140 72 -200 64
-67 142 -82 234 -47 69 26 114 63 145 122 96 175 -59 385 -256 349z"
        />
        <path
          d="M427 3880 c-229 -29 -389 -217 -421 -492 l-6 -48 185 0 185 0 0 28
c0 52 29 129 55 146 59 39 55 131 55 -1214 l0 -1230 195 0 195 0 0 650 0 650
550 0 550 0 0 180 0 180 -550 0 -550 0 0 400 0 400 795 0 795 0 0 180 0 180
-987 -1 c-544 -1 -1014 -5 -1046 -9z"
        />
        <path
          d="M5080 2135 l0 -1065 185 0 185 0 0 1065 0 1065 -185 0 -185 0 0
-1065z"
        />
        <path
          d="M6539 3160 c-172 -19 -311 -76 -408 -165 -115 -107 -162 -215 -162
-370 0 -265 165 -442 530 -569 458 -159 561 -235 561 -412 0 -170 -124 -267
-360 -281 -211 -12 -413 43 -637 176 l-52 31 -72 -103 c-40 -56 -83 -116 -96
-133 l-23 -31 54 -36 c263 -175 563 -253 865 -227 147 13 248 41 370 101 169
83 275 208 311 364 17 71 14 229 -4 298 -61 226 -212 345 -652 512 -207 78
-288 118 -349 167 -89 74 -101 214 -24 285 13 12 47 32 74 45 42 19 66 23 155
22 118 -1 229 -25 429 -94 63 -22 116 -38 119 -35 4 4 72 292 72 305 0 13
-156 79 -244 105 -151 43 -321 60 -457 45z"
        />
        <path
          d="M2715 3108 c-82 -22 -154 -43 -158 -48 -13 -12 455 -1564 501 -1664
93 -197 217 -316 395 -378 37 -12 67 -27 67 -33 0 -35 -147 -443 -173 -482
-74 -109 -188 -161 -374 -170 -73 -4 -113 -10 -113 -17 1 -30 47 -306 52 -311
3 -4 88 -2 189 4 268 15 357 45 467 160 98 102 118 153 590 1569 l439 1312
-101 31 c-56 17 -131 40 -168 52 -37 11 -70 18 -73 16 -2 -3 -143 -416 -312
-918 -168 -502 -312 -915 -319 -918 -21 -8 -103 38 -140 79 -73 82 -100 157
-344 968 -204 677 -239 785 -256 787 -10 0 -86 -17 -169 -39z"
        />
      </g>
    </svg>
  </div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'

export default {
  props: {
    options: {}
  }
};
</script>
