import config from "@/api.js";
import Axios from "axios";

const getDefaultState = () => {
  return {
    status: {}
  };
};
const report = {
  state: getDefaultState(),
  mutations: {
    SETreport_status(state, data) {
      state.status = data;
    },
    RESET: state => {
      Object.assign(state, getDefaultState());
    }
  },
  actions: {
    SENDreport({ commit, state }) {
      const status = state.status;
      const id = status.id;
      if (status.type === "grewtale") {
        Axios.post(`${config.publicPath}` + "report/grewtale", id).then(
          response => {
            commit("SETmessage", response.data.msg);
            setTimeout(() => {
              commit("SETmessage", "");
            }, 2000);
          }
        );
      } else {
        Axios.post(`${config.publicPath}` + "report/comment", id).then(
          response => {
            commit("SETmessage", response.data.msg);
            setTimeout(() => {
              commit("SETmessage", "");
            }, 2000);
          }
        );
      }
      commit("SETreport_status", {});
      commit("LOADreport_panel");
    }
  },
  getters: {
    getreportstatus: state => {
      return state.status;
    }
  }
};
export default report;
