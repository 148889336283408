<template>
  <div class="tac">
    <div
      class=" fy-margin-t-5  tac  fy-height_30px disflex fy-cj_b  fy-height_27px fy-width_30px  fy-border_r12 fy-transition "
    >
      <div style="margin-top:1px; margin-left:3px">
        <icons
          class=""
          excel="compete"
          routerActive="false"
          hoverActive="false"
          message="Image"
          color="var(--fy-font-applyed-7)"
          width="22"
          height="22"
        />
      </div>
    </div>
    <div class="fy-font-13 fy-text-7 fy-ff-wp">Compete</div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["GETscreen"])
  }
};
</script>
