<template>
  <div>
    <div class="psr fy-width_100p fy-transition_main"
      :class="{
          'fy-height_50px': simpleBar === true,
          'fy-height_105px': simpleBar === false
        }"
    >
     
        <div
        v-observe-visibility="on"
        @click="activeWideBar"
          class="psa fy-top_5 fy-left_5 fy-cursor_point fy-width_80px fy-padding-t-5 fy-height_40px tac fy-transition_main"
          style="background:var(--fy-reverse-05);border-radius:25px;"
        :class="{
          ' fy-op_1 ': simpleBar === true,
          ' fy-zi_-1 fy-op_0': simpleBar === false
        }"
        >
          <div class="disflex fy-margin-l-5" style="width:65px; margin-top: 2px;
    ">
            <div
              v-for="(p, n) in selectedMeasures"
              :key="n"
              class="fy-width_25px tac fy-border_50 fy-height_25px"
              :style="`background:var(--fy-reverse-05); box-shadow:1px 1px 4px #858585`"
              :class="{ 'fy-margin-l--10': n > 0, 'fy-zi_1' : n == 1, 'fy-zi_2' : n == 0 }"
            >
              <measures
               :class="svgInformation[p].class"
                :width="svgInformation[p].width"
                :height="svgInformation[p].height"
                type="Button"
                :active="p"
                :point="grewtale.Active"
              />
            </div>
          </div>
        </div>
        <div v-if="simpleBar === true" class="psa fy-top_5 fy-right_5 fy-height_40px disflex fy-width_95px">
          <div @click="_idroute(grewtale)" class="fy-cursor_point">
            <div
              class=" fy-margin-t-5  tac   fy-height_30px disflex  fy-height_27px fy-width_30px  fy-transition "
            >
              <div style="margin-top:1px; margin-left:3px">
                <icons
                  class=""
                  excel="comment"
                  routerActive="false"
                  hoverActive="false"
                  message="Image"
                  color="var(--fy-font-applyed-7)"
                  width="22"
                  height="22"
                />
              </div>
            </div>
          </div>
          <div @click="compete(grewtale)" class="fy-cursor_point">
            <div
              class=" fy-margin-t-5  tac   fy-height_30px disflex  fy-height_27px fy-width_30px  fy-transition "
            >
              <div style="margin-top:1px; margin-left:3px">
                <icons
                  class=""
                  excel="compete"
                  routerActive="false"
                  hoverActive="false"
                  message="Image"
                  color="var(--fy-font-applyed-7)"
                  width="22"
                  height="22"
                />
              </div>
            </div>
          </div>
          <div @click="share_activate" class="fy-cursor_point">
            <div
              class=" fy-margin-t-5  tac   fy-height_30px disflex  fy-height_27px fy-width_30px  fy-transition "
            >
              <div style="margin-top:1px; margin-left:3px">
                <icons
                  class=""
                  excel="forward"
                  routerActive="false"
                  hoverActive="false"
                  message="Image"
                  color="var(--fy-font-applyed-7)"
                  width="22"
                  height="22"
                />
              </div>
            </div>
          </div>
        </div>
      
      <div
        class=" psa fy-top_0 fy-fit_height fy-transition_main"
        :class="{
          'fy-left_0 fy-width_80px fy-contain_cont fy-op_0 fy-zi_--1': simpleBar === true,
          'fy-left_0 fy-width_100p fy-op_1': simpleBar === false
        }"
      >
        <div class=" disflex btmr mrgn    fy-height_dst_70">
          <div
            class="fy-margin-l-10 fy-margin-r-10 fy-border_top fy-width_100p fy-padding-t-2"
            :class="{
              'fy-hidden_scroll': GETzoom > 110
            }"
          >
            <div id="MEASURES_SEGMENT" class="fy-width_100p">
              <div
                class="  fy-width_100p fy-cointainer_content  psr fy-jc_sb disflex"
              >
                <div v-if="left !== null"
                  :class="{
                    'fy-bottom_-10': hidden == true,
                    'fy-bottom_0': hidden === false
                  }"
                  class="psa   tac fy-width_40px"
                  :style="`height:3px;transition:.3s;;left:${left}px`"
                >
                  <div
                    class="fy-transition"
                    :style="
                      `width:${width}px;background:var(--fy-main-logo);height:3px`
                    "
                  ></div>
                </div>
                <div
                  v-for="(plotter, count) in getplotters"
                  :key="count"
                  class="divsn tac tooltip3 fy-cursor_point fy-width_40px"
                >
                  <div
                    @click="plotter_value(count)"
                    class="fy-fit_width fy-fit_height"
                  >
                    <button
                      :disabled="disable_p === true"
                      data-toggle="fy-tooltip "
                      :title="plotter.sub"
                      data-placement="bottom"
                    >
                      <measures
                        :width="27"
                        :height="27"
                        type="Button"
                        :active="count"
                        :point="grewtale.Active"
                      />
                      <!-- <img
              loading="lazy"
              class="plwd"
              :src="GETbaseurl + `img/media/plotters/` + plotter.sub + `.svg`"
            /> -->
                    </button>
                    <div class="fy-font-13 fy-text-7 fy-ff-wp">
                      <div
                        :class="{
                          'fy-main_logo': grewtale.Active === count + 1
                        }"
                      >
                        {{ grewtale.plotters[count].b }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="GETscreen === 'desktop'" class="fy-width_20p  tac">
            <button
              @click="compete(grewtale)"
              class="fy-width_auto tac fy-height_auto"
            >
              <compete />
            </button>
          </div>
        </div>
      </div>
      <div
        
        class="disflex psa fy-bottom_0 fy-right_0 fy-left_0 fy-transition_main   btmr mrgn fy-padding-r-5  fy-height_dst_70 fy-transition_main"
        :class="{
          ' fy-op_0 fy-zi_--1': simpleBar === true,
          '  fy-op_1': simpleBar === false
        }"
      >
        <div
          @click="_idroute(grewtale)"
          :class="{
            'fy-width_100p': GETscreen === 'desktop',
            'fy-width_commentmbl fy-margin-t-5': GETscreen === 'mobile'
          }"
        >
          <fycomment :index="index" />
        </div>
        <div
          class="tac"
          :class="{
            'fy-width_80px': GETscreen === 'desktop',
            'fy-width_70px': GETscreen === 'mobile'
          }"
        >
          <div @click="share_activate" class="fy-cursor_point">
            <div
              class=" fy-margin-t-5  tac   fy-height_30px disflex fy-cj_b  fy-height_27px fy-width_30px  fy-border_r12 fy-transition "
            >
              <div style="margin-top:1px; margin-left:3px">
                <icons
                  class=""
                  excel="forward"
                  routerActive="false"
                  hoverActive="false"
                  message="Image"
                  color="var(--fy-font-applyed-7)"
                  width="22"
                  height="22"
                />
              </div>
            </div>
            <div class="fy-font-13 fy-text-7 fy-ff-wp">Share</div>
          </div>
        </div>
        <div
          class="fy-width_55px"
          @click="compete(grewtale)"
          v-if="GETscreen === 'mobile'"
        >
          <compete />
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.fy-width_commentmbl {
  width: calc(100% - 60px);
}
.fy-width_130px {
  width: 130px;
}
.fy-bottom_-10 {
  bottom: -10px;
}
.fy-margin-l--10 {
  margin-left: -10px;
}
.fy-transition_main {
  transition: 0.2s;
   transition-timing-function:linear;
}
.fy-right_-100p {
  right: -100%;
}
.fy-left_-100p {
  left: -100%;
}
.fy-op_0{
  opacity : 0
}
.fy-op_1{
  opacity : 1
}
.fy-height_105px{
  height : 105px;
}
</style>
<script>
import { mapGetters } from "vuex";
import measures from "@/slots/grewtale/measures.vue";
import compete from "@/slots/grewtale/compete.vue";
import fycomment from "@/slots/grewtale/grewtaleComment.vue";

export default {
  components: {
    measures,
    compete,

    fycomment

    //  comment
  },
  data() {
    return {
       svgInformation : [
        {name : 'Creative', width : 21, height : 21, class : 'fy-margin-t-1'},
        {name : 'Demandable', width : 24, height : 24, class : ''},
        {name : 'Excitable', width : 18, height : 18, class : 'fy-margin-t-3'},
        {name : 'Experienceable', width : 22, height : 22, class : 'fy-margin-t-2'},
        {name : 'Futuristic', width : 24, height : 24, class : ''},
        {name : 'Helpful', width : 24, height : 24, class : ''},
        {name : 'Innovative', width : 21, height : 21, class : 'fy-margin-t-1'},
        {name : 'Knowledgeable', width : 22, height : 22, class : ''}
       ],
      isEnabled: true,
    
      sliding: null,
      title_point: "",
      title_point_link1: "",
      title_point_link2: "",
      disable_p: false,

      readLoading: false,
      menu: false,
      widthunder: 0,
      width: 0,
      hidden: true,
      oneTime : false
    };
  },
  props: {
    grewtale: {},
    index: null
  },
  computed: {
    ...mapGetters([
      "getgrewtales",
      "GETcdnlink",
      "getplotters",
      "getplotter_msg",
      "getfile_window",
      "getnotificationcount",
      "getgrewn",
      "getiusers",
      "GETscreen",
      "GETheight",
      "getusergrewtale",
      "getgrewtales_c",
      "GETbaseurl",
      "GETwidth",
      "GETzoom",
      "GETgrewtaleUnderbarType"
    ]),
     simpleBar(){
       if(this.grewtale.id === this.GETgrewtaleUnderbarType){
         return false;
       }else{
         return true;
       }
     },
    left() {
      var left = null;
      var w = this.GETscreen === "desktop" ? 422 : this.GETwidth - 20;
      var c = 40,
        t = 0,
        v = (w - c * 8) / 7;
     
      if (this.grewtale.Active == 1) {
         left = 0;
      } else if (this.grewtale.Active == 0) {
        t = 0;
      } else {
        left =
          c * (this.grewtale.Active - 1 + t) + v * (this.grewtale.Active - 1);
      }

      return left;
    },

    active() {
      return this.grewtale.Active;
    },
    selectedMeasures(){
      
      var y = JSON.parse(JSON.stringify(this.grewtale.plotters)),
        pre = [],
       activeElement = null,
       r = [],
       active = this.grewtale.Active === null ? null : this.grewtale.Active - 1;
      
      for(let i = 0; i < y.length; i++){
        
        if(active != null && active === i){
           activeElement = {b : y[i].b, index : i};       
        }else{
         pre.push({b : y[i].b, index : i})
        }   
        
      }
     
        pre.sort(function(a, b) {
       
          return b.b - a.b
        });
        if(activeElement){
          pre.unshift(activeElement);
        } 
        pre.splice(3, 5);
        for(let i = 0; i < pre.length; i++){
           r.push(pre[i].index);
        }
        
         return r;
    },
    path(){
      var p = this.$route.path;
      return p;
    }
   
  },
  created(){
  
  },
  mounted(){
   this.setActivebarWidth();
  },
  methods: {
    
    on(isEntry) {

      if(isEntry === true){
        if(!this.oneTime){
          this.oneTime = true;
       
        }
      }
     
    
    },
    
    activeWideBar(){
    
      this.$store.commit("SetGrewtaleUnderbarType", this.grewtale.id);
    },
      async share_activate() {
       var data = `https://fyish.com/single?id=${this.grewtale.id}`;
      if (this.GETscreen === "mobile" && navigator.share) {
        await navigator.share({
          url: data
        });
      } else {
        this.$store.commit("SETSharePanel", data);
      }
    },
    async plotter_value(count) {
      try {
        this.disable_p = true;

        const id = this.grewtale.id;
        const active = this.grewtale.Active;

        if (active === count + 1) {
          this.$store.dispatch("delete_plotters", {
            id,
            count,
            active
          });
        } else {
          this.$store.dispatch("plotters", { id, count, active });
        }
      } catch (e) {
        console.log(e);
      }
    },

    // full_image(index) {
    // if (this.video_access !== null) {
    //   var video = document.getElementById(`vt` + this.video_access);
    //  video.pause();
    //  this.ended();
    // }
    // var data = index;
    // this.$store.commit("image_show", { data });
    // },
    setActivebarWidth(){
      if (this.active != 0) {
        this.width = 20;
        setTimeout(() => {
          this.width = 40;
        }, 300);
      } else {
        this.width = 0;
      }
      this.hidden = this.active == 0 ? true : false;
    },
    compete(grewtale) {
           var _t = grewtale.title;
      var _p = grewtale.primitive_id;
      var _a = grewtale.id;
      this.$store.commit("SET_compete", { _t, _p, _a });
      this.$router.push("grewtale?active=1");
    },
    _up() {
      this.$store.commit("SETcomment_panel");
    },
     _idroute(grewtale) {
     
       if (this.path === "/home") {
        if (this.GETscreen === "mobile") {
          this._up();
        }
        var index = null;
        var type = "grewtale";
        var comment = grewtale;
        this.$store.commit("SET_reply", { index, comment, type });
      } else {
        var id = grewtale.primitive_id;
        this.$router.push("/home?competition=" + id + "&action=comment");
      }
    },
    plotter_active() {
      if (this.getplotter_msg) {
        setTimeout(() => {
          this.disable_p = false;
        }, 1000);
      }
    }
  },
  watch: {
   
    getplotter_msg() {
      this.plotter_active();
    },
    active() {
     this.setActivebarWidth();
    }
  }
};
</script>
