const url = window.location.origin;
const parts = url.split("/");

const actual_url = parts[0] + `//` + parts[2];
if (
  actual_url === "https://fyish.com" ||
  actual_url === "https://www.fyish.com"
) {
  var a = "https://api.fyish.com/",
    b = "https://rtl.fyish.com/",
    c = "https://content.fyish.com/";
} else {
  a = "http://localhost:3000/";
  b = "https://rtl.fyish.com/";
  c = "http://localhost:3003/";
}
//192.168.43.252

module.exports = {
  publicPath: a,
  chatPath: b,
  cdnPath: c
};
