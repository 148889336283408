<template>
  <div
    class="psf fy-top_0 fy-right_0 fy-width_100p fy-height_100p fy-portlet-active--bg"
  >
    <div class=" fy-portlet__body--fit-y fy-margin-t-30">
      <div class="fy-widget fy-widget--user-profile-1 tac">
        <div class="fy-margin-t-60">
          <img
            src="https://cdn-icons-png.flaticon.com/512/4233/4233789.png"
            class="fy-padding-5 fy-width_150px "
          />
          <div class="fy-margin-t-20" style="font-size:40px">
            Service in process.
          </div>
          <div class="fy-margin-t-10 fy-font-18">
            Our service is in maintenance process will be resume early.
          </div>
        </div>
        <div
          class="fy-padding-r-60 fy-padding-l-60 fy-padding-t-5 fy-padding-b-5 fy-margin-t-20"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  methods: {},
  computed: {
    ...mapGetters(["GETapilink", "GETscreen"])
  }
};
</script>
