// Fyissh inc. Project

import config from "@/api.js";
import Axios from "axios";
import {
  grewtaleDataFormation,
  grewtaleMeasureAction,
  grewtaleContentSet
} from "@/js/grewtale.js";
//import Date from 'date-and-time';
const getDefaultState = () => {
  return {
    grewtalesNext: null,
    grewtaleType: "TOP",
    grewtales: [],
    grewtalesConnectionNext: null,
    grewtalesConnection: [],
    grewnConnection: false,
    plotter_msgh: null,
    file_show: null,
    grewtalelength: 0,
    grewtaleConnectionlength: 0,
    file_window: false,
    grewn: false,
    a1: false,
    a2: false,
    a3: false,
    a4: false,
    resetanimation: false
  };
};
const grewtale = {
  state: getDefaultState(),
  mutations: {
    setGrewtaleContent(state, data) {
      grewtaleContentSet(state, data, "grewtales");
      grewtaleContentSet(state, data, "grewtalesConnection");
    },
    ResetHome(state) {
      state.resetanimation = true;
      state.grewtales = [];
      state.grewtalelength = 0;
      state.grewtalesNext = null;
      state.grewn = false;
    },
    ResetHomeCon(state) {
      state.resetanimation = true;
      state.grewtalesConnection = [];
      state.grewtaleConnectionlength = 0;
      state.grewtalesConnectionNext = null;
      state.grewnConnection = false;
    },
    GET_Grewtales(state, load) {
      state.grewtalesNext = load.next;
      const data = load.data;
      if (data.next === null) state.grewn = true;
      if (data === "nothing") {
        state.grewn = true;
      } else {
        state.resetanimation = false;
        state.grewtalelength = state.grewtalelength + data.length;
        for (var i = 0; i < data.length; i++) {
          var obj = grewtaleDataFormation(data[i]);
          state.grewtales.push(obj);
        }
      }
    },
    GET_Grewtales_Connection(state, load) {
      state.grewtalesConnectionNext = load.next;

      const data = load.data;

      if (load.next === null) state.grewnConnection = true;
      if (data === "nothing") {
        state.grewnConnection = true;
      } else {
        state.resetanimation = false;
        state.grewtaleConnectionlength =
          state.grewtaleConnectionlength + data.length;
        for (var i = 0; i < data.length; i++) {
          var obj = grewtaleDataFormation(data[i]);
          state.grewtalesConnection.push(obj);
        }
      }
    },

    //SET at First position

    GET_Grewtales1(state, data) {
      if (data !== "nothing") {
        state.grewtalelength = state.grewtalelength + data.length;
        for (var i = 0; i < data.length; i++) {
          var obj = grewtaleDataFormation(data[i].data);
          state.grewtales.unshift(obj);
        }
      }
    },

    SET_Plotters_inside(state, { id, count, process }) {
      grewtaleMeasureAction(state, id, count, "grewtales", process);
      grewtaleMeasureAction(state, id, count, "grewtalesConnection", process);
    },

    SET_Plotters(state, data) {
      state.plotter_msgh = data;
    },
    image_show(state, { data }) {
      state.file_show = data;
      state.file_window = true;
    },
    file_window_dismiss(state) {
      state.file_window = false;
      state.file_show = null;
    },
    RESETghome_p(state) {
      state.grewtales_p = state.grewtales;
      state.plotter_msg = null;
      state.file_show = null;
      state.file_window = false;
      state.grewn = false;
      state.grewtales = [];
      state.grewtalesNext = null;
      state.grewtalelength = 0;
    },
    RESETghome(state) {
      state.grewtales = state.grewtales_p;
      state.plotter_msg = null;

      state.file_show = null;
      state.file_window = false;
      state.grewn = false;
    },
    RESETghome3(state) {
      state.grewtales = [];
      state.grewtalesNext = null;
      //state.grewtales_p = [];
      state.plotter_msg = null;
      state.file_show = null;
      state.file_window = false;
      state.grewn = false;
    },
    Setfile_active(state, _data) {
      if (state.grewtaleType === "TOP") var key = "grewtales";
      else key = "grewtalesConnection";
      state[key][_data.dynamic].file_active = true;
    },
    sa1(state, data) {
      state.a1 = data;
    },
    sa2(state, data) {
      state.a2 = data;
    },
    sa3(state, data) {
      state.a3 = data;
    },
    sa4(state, data) {
      state.a4 = data;
    },
    SETGrewtaleType(state, data) {
      state.grewtaleType = data;
    },
    SEThogredel(state, data) {
      if (state.grewtaleType === "TOP") var key = "grewtales";
      else key = "grewtalesConnection";
      for (var i = 0; i < state[key].length; i++) {
        if (state[key][i].id === data) {
          state[key].splice(i, 1);
          break;
        }
      }
    },
    RESET: state => {
      Object.assign(state, getDefaultState());
    }
  },
  actions: {
    loadGrewtales({ commit, state }, { items }) {
      if (state.a1 === false) {
        commit("sa1", true);
        var next = state.grewtalesNext,
          length = 8;
        Axios.post(`${config.publicPath}` + "/get/grewtale", {
          items,
          next,
          length
        })
          .then(response => {
            commit("GET_Grewtales", response.data);
          })
          .catch(error => console.log(error))
          .finally(() => {
            commit("sa1", false);
          });
      }
    },
    loadGrewtalesConnection({ commit, state }, { items }) {
      if (state.a4 === false) {
        commit("sa4", true);
        var next = state.grewtalesConnectionNext,
          length = 8;
        Axios.post(`${config.publicPath}` + "/get/grewtale/connection", {
          items,
          next,
          length
        })
          .then(response => {
            commit("GET_Grewtales_Connection", response.data);
          })
          .catch(error => console.log(error))
          .finally(() => {
            commit("sa4", false);
          });
      }
    },

    GetLastpost({ commit, state }, id) {
      if (state.a3 === false) {
        commit("sa3", true);
        Axios.post(`${config.publicPath}` + "get/last/post", { id })
          .then(response => {
            commit("GET_Grewtales1", response.data);
            commit("GET_Grewtalesu1", response.data);
          })
          .catch(error => console.log(error))
          .finally(() => {
            commit("sa3", false);
          });
      }
    },
    plotters({ commit }, { id, count, active }) {
      Axios.post(`${config.publicPath}` + "plotter/inside", {
        id,
        count,
        active
      })
        .then(response => {
          commit("SET_Plotters", response.data);
          // commit("SETmessage", response.data.msg);
          var process = "INSERTED";

          commit("SET_Plotters_inside", { id, count, process });

          commit("SET_Plotters_inside_single", { id, count });
          setTimeout(() => {
            commit("SET_Plotters", "");
            //  commit("SETmessage", "");
          }, 1500);
        })
        .catch(error => console.log(error));
    },
    delete_plotters({ commit }, { id, count, active }) {
      Axios.post(`${config.publicPath}` + "plotter/remove", {
        id,
        count,
        active
      })
        .then(response => {
          commit("SET_Plotters", response.data);
          // commit("SETmessage", response.data.msg);
          var process = "DELETED";

          commit("SET_Plotters_inside", { id, count, process });

          commit("SET_Plotters_insideout_single", { id, count });

          setTimeout(() => {
            commit("SET_Plotters", "");
            // commit("SETmessage", "");
          }, 1500);
        })
        .catch(error => console.log(error));
    }
  },
  getters: {
    getgrewtales: state => {
      return state.grewtales;
    },
    getGrewtaleType: state => {
      return state.grewtaleType;
    },
    getGrewtalesConnection: state => {
      return state.grewtalesConnection;
    },
    getgrewtalelength: state => {
      return state.grewtalelength;
    },
    getGrewtaleConnectionlength: state => {
      return state.grewtaleConnectionlength;
    },
    getplotter_msg: state => {
      return state.plotter_msgh;
    },
    getfile_show: state => {
      return state.file_show;
    },
    getfile_window: state => {
      return state.file_window;
    },
    getgrewn: state => {
      return state.grewn;
    },
    getgrewnConnection: state => {
      return state.grewnConnection;
    },
    getresetanimation: state => {
      return state.resetanimation;
    }
  }
};
export default grewtale;
