const getDefaultState = () => {
  return {
    message: ""
  };
};
const message = {
  state: getDefaultState(),
  mutations: {
    SETmessage(state, data) {
      state.message = data;
    },
    RESET: state => {
      Object.assign(state, getDefaultState());
    }
  },

  getters: {
    getmessage: state => {
      return state.message;
    }
  }
};
export default message;
