<template>
  <div
    class=""
    :class="{
      'fy-width_35px fy-height_35px fy-portlet-active--bg fy-border_50 fy-padding-5 tac fy-portlet-active--bs fy-partlet-active--bg':
        GETscreen === 'desktop'
    }"
  >
    <div class="fy-circle_loading"></div>
  </div>
</template>

<style scoped>
.fy-circle_loading {
  width: 25px;
  height: 25px;

  border-radius: 50%;
  border: 2px solid rgba(0, 0, 255, 0.3);
  border-top-color: var(--fy-main-logo);
  animation: 1s spin infinite linear;
}
.fy-circle_loading.multi {
  border-bottom-color: var(--fy-main-logo);
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["GETscreen"])
  }
};
</script>
