import config from "@/api.js";
import Axios from "axios";
const getDefaultState = () => {
  return {
    msgp: null,
    progress: 0,
    speed: 0,
    total: 0,
    compete: ""
  };
};
const post = {
  state: getDefaultState(),
  mutations: {
    SET_msgp(state, data) {
      state.msgp = data;
    },
    SET_compete(state, data) {
      state.compete = data;
    },
    SET_progress(state, data) {
      if (data === null) {
        state.progress = 0;
        state.speed = 0;
        state.total = 0;
      } else {
        state.progress = data.progress;
        state.speed = data.speed;
        state.total = data.total;
      }
    },
    RESET: state => {
      Object.assign(state, getDefaultState());
    }
  },
  actions: {
    SETpost({ commit }, { formData }) {
      var st = Date.now();
      Axios.post(`${config.cdnPath}` + "p/grewtale", formData, {
        onUploadProgress: uploadEvent => {
          var ex = (Date.now() - st) / 1000;
          var progress = Math.round(
            (uploadEvent.loaded / uploadEvent.total) * 100
          );
          var speed = uploadEvent.loaded / ex,
            total = uploadEvent.total;
          //console.log(speed + 'byte/s');
          commit("SET_progress", { progress, speed, total });
          setTimeout(() => {
            commit("SET_progress", null);
          }, 2000);
        }
      })
        .then(response => {
          commit("SET_msgp", response.data);
          commit("SETmessage", response.data.msg);
          setTimeout(() => {
            commit("SET_msgp", "");
            commit("SETmessage", "");
          }, 2000);
        })
        .catch(error => console.log(error));
    },

    SETpost2({ commit }, { formData }) {
      var st = Date.now();
      Axios.post(`${config.cdnPath}` + "p/grewtaleplus", formData, {
        onUploadProgress: uploadEvent => {
          var ex = (Date.now() - st) / 1000;
          var progress = Math.round(
            (uploadEvent.loaded / uploadEvent.total) * 100
          );
          var speed = uploadEvent.loaded / ex,
            total = uploadEvent.total;
          //console.log(speed + 'byte/s');
          commit("SET_progress", { progress, speed, total });
          setTimeout(() => {
            commit("SET_progress", null);
          }, 2000);
        }
      })
        .then(response => {
          commit("SET_msgp", response.data);
          commit("SETmessage", response.data.msg);
          setTimeout(() => {
            commit("SET_msgp", "");
            commit("SETmessage", "");
          }, 2000);
        })
        .catch(error => console.log(error));
    }
  },
  getters: {
    GETpostmsg: state => {
      return state.msgp;
    },
    GETpostprogress: state => {
      return state.progress;
    },
    GETpostspeed: state => {
      return state.speed;
    },
    GETposttotal: state => {
      return state.total;
    },
    GETgpassests: state => {
      return state.compete;
    }
  }
};
export default post;
