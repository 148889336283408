import config from "@/api.js";
import Axios from "axios";
const getDefaultState = () => {
  return {
    notification: [],
    notificationNext: null,
    alert: [],
    alertNext: null,
    alertNot: false,

    msg: "",
    notification_count: 0,
    n_request: false,
    n_accept: false,
    activity_count: 0,
    notificationnot: false,

    message: 0
  };
};
const notification = {
  state: getDefaultState(),
  mutations: {
    SET_notification1(state, data) {
      if (data === "nothing" || data === "") {
        state.notificationnot = true;
      } else {
        if (data.next === null) state.notificationnot = true;
        state.notificationNext = data.next;
        data = data.data;
        for (let i = 0; i < data.length; i++) {
          state.notification.push({
            first_name: data[i].first_name,
            last_name: data[i].last_name,
            profilepic: data[i].profilepic,
            id: data[i].id,
            sender: data[i].sender,
            notification_seen: data[i].notification_seen,
            relation_value: data[i].relation_value,
            relation_status: data[i].relation_status,
            username: data[i].username
          });
        }
      }
    },
    SET_alert(state, data) {
     
      if (data === "nothing" || data === "Error") {
        state.alertNot = true;
      } else {
        if (data.next === null) state.alertNot = true;
        state.alertNext = data.next;
        data = data.data;
        for (let i = 0; i < data.length; i++) {
          state.alert.push(data[i]);
        }
      }
    },
    free_remove(state, data) {
      var index = data;
      state.notification.splice(index, 1);
    },

    remove_notification(state, index) {
      state.notification.splice(index, 1);
    },
    set_msg(state, data) {
      state.msg = data;
    },
    count_notification(state, data) {
      state.notification_count = data[0]._count;
      state.activity_count = data[2]._count;
    },
    remove_notification_count(state, data) {
      state.notification_count = state.notification_count - data.count;
      var seenData = data.seenData;
      for (var i = 0; i < state.notification.length; i++) {
        var g = state.notification[i].id;
        if (seenData.indexOf(g) !== -1) {
          state.notification[i].notification_seen =
            state.notification[i].notification_seen + 1;
        }
      }
    },
    Set_Msg_Count(state, data) {
      var count = JSON.parse(data);
      state.message = state.message + count;
    },
    Set_Msg_Count_Del(state) {
      state.message = 0;
    },
    reduce_msg_count(state) {
      if (state.message > 0) {
        state.message = state.message - 1;
      }
    },
    setCountDestroy(state, data) {
      state.activity_count = Math.round(state.activity_count - data);
    },
    RESET: state => {
      Object.assign(state, getDefaultState());
    }
  },
  actions: {
    loadnotification({ state, commit }) {
      var length = 15,
        next = state.notificationNext;
      Axios.post(`${config.publicPath}` + "notification/get1", {
        length,
        next
      })
        .then(response => {
          commit("SET_notification1", response.data);
        })
        .catch(error => console.info(error));
    },
    loadAlert({ state, commit }) {
      var length = 15,
        next = state.alertNext;
      Axios.post(`${config.publicPath}` + "alert/get", {
        length,
        next
      })
        .then(response => {
          commit("SET_alert", response.data);
        })
        .catch(error => console.info(error));
    },

    removenotification({ commit }, { index, id }) {
      Axios.post(`${config.publicPath}` + "notification/remove1", { id })
        .then(() => {
          commit("remove_notification", index);
        })
        .catch(error => console.info(error));
    },
    countnotification({ commit }, alertSeen) {
      Axios.post(`${config.publicPath}` + "notification/count", { alertSeen })
        .then(response => {
          commit("count_notification", response.data);
          commit("Set_Msg_Count", response.data[1]._count);
        })
        .catch(error => console.info(error));
    },
    countdestroyednotification({ commit }, { from, seenData }) {
      Axios.post(`${config.publicPath}` + "notification/count/destroyed", {
        from
      })
        .then(response => {
          if (response.data !== "Nothing") {
            commit("remove_notification_count", {
              count: response.data,
              seenData: seenData
            });
          }
        })
        .catch(error => console.info(error));
    }
  },
  getters: {
    getnotification: state => {
      return state.notification;
    },
    getAlertNot: state => {
      return state.alertNot;
    },
    getAlert: state => {
      return state.alert;
    },
    getnotificationcount: state => {
      return state.notification_count;
    },
    getactivitycount: state => {
      return state.activity_count;
    },
    getmessagecount: state => {
      return state.message;
    },
    getn_request: state => {
      return state.n_request;
    },
    getn_accept: state => {
      return state.n_accept;
    },
    getdata2len: state => {
      return state.data2len;
    },
    getdata1len: state => {
      return state.data1len;
    },
    getdnotificationnot: state => {
      return state.notificationnot;
    }
  }
};
export default notification;
