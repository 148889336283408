import config from "@/api.js";
import Axios from "axios";
const getDefaultState = () => {
  return {
    search_history: 0,
    privacy: 0,
    msg_set: "",
    username_frame: false,
    email_frame: false,
    phone_frame: false,
    username: null,
    password_check: ""
  };
};
const settings = {
  state: getDefaultState(),

  mutations: {
    SET_privacy: (state, { data }) => {
      state.privacy = data;
    },
    SET_st_search: (state, { data }) => {
      state.search_history = data;
    },
    set_setting_msg: (state, data) => {
      state.msg_set = data;
    },
    SET_username_frame: state => {
      let _value = !state.username_frame;
      state.username_frame = _value;
    },
    SET_email_frame: state => {
      let _value = !state.email_frame;
      state.email_frame = _value;
    },
    SET_phone_frame: state => {
      let _value = !state.phone_frame;
      state.phone_frame = _value;
    },
    set_setting: (state, data) => {
      let privacy = data[0].privacy;
      let search_history = data[0].search_history;
      state.search_history = search_history;
      state.privacy = privacy;
    },
    set_password_check(state, data) {
      state.password_check = data;
    },
    SET_password_check_re(state) {
      state.password_check = "";
    },
    RESET: state => {
      Object.assign(state, getDefaultState());
    }
  },
  actions: {
    load_privacy({ commit }, { data }) {
      Axios.post(`${config.publicPath}` + "setting/privacy", { data })
        .then(response => {
          commit("set_setting_msg", response.data);
          commit("SETmessage", response.data);
          setTimeout(() => {
            commit("set_setting_msg", "");
            commit("SETmessage", "");
          }, 2000);
        })
        .catch(error => console.log(error));

      commit("SET_privacy", { data });
    },
    load_st_search({ commit }, { data }) {
      Axios.post(`${config.publicPath}` + "setting/search", { data })
        .then(response => {
          commit("set_setting_msg", response.data);
          commit("SETmessage", response.data);
          setTimeout(() => {
            commit("set_setting_msg", "");
            commit("SETmessage", "");
          }, 2000);
        })
        .catch(error => console.log(error));

      commit("SET_st_search", { data });
    },
    Load_username({ commit }, { data }) {
      Axios.post(`${config.publicPath}` + "setting/username", { data })
        .then(response => {
          commit("set_setting_msg", response.data);
          commit("SETmessage", response.data);
          setTimeout(() => {
            commit("set_setting_msg", "");
            commit("SETmessage", "");
          }, 2000);
        })
        .catch(error => console.log(error));
    },
    Load_email({ commit }, { data }) {
      Axios.post(`${config.publicPath}` + "setting/email", { data })
        .then(response => {
          commit("set_setting_msg", response.data);
          commit("SETmessage", response.data);
          setTimeout(() => {
            commit("set_setting_msg", "");
            commit("SETmessage", "");
          }, 2000);
        })
        .catch(error => console.log(error));
    },
    Load_phone({ commit }, { data }) {
      Axios.post(`${config.publicPath}` + "setting/phone", { data })
        .then(response => {
          commit("set_setting_msg", response.data);
          commit("SETmessage", response.data);
          setTimeout(() => {
            commit("set_setting_msg", "");
            commit("SETmessage", "");
          }, 2000);
        })
        .catch(error => console.log(error));
    },
    Load_settings({ commit }) {
      Axios.get(`${config.publicPath}` + "setting/get")
        .then(response => {
          commit("set_setting", response.data);
        })
        .catch(error => console.log(error));
    },
    ST_delete_search({ commit }) {
      Axios.post(`${config.publicPath}` + "setting/delete/search")
        .then(response => {
          commit("set_setting_msg", response.data);
          commit("SETmessage", response.data);
          setTimeout(() => {
            commit("set_setting_msg", "");
            commit("SETmessage", "");
          }, 2000);
        })
        .catch(error => console.log(error));
    },
    ST_deactivate_account({ commit }) {
      Axios.post(`${config.publicPath}` + "setting/deactivate/account")
        .then(response => {
          commit("set_setting_msg", response.data);
          commit("SETmessage", response.data);
          setTimeout(() => {
            commit("set_setting_msg", "");
            commit("SETmessage", "");
          }, 2000);
        })
        .catch(error => console.log(error));
    },
    ST_password_check({ commit }, { password }) {
      Axios.post(`${config.publicPath}` + "setting/password/check", {
        password
      })
        .then(response => {
          commit("set_password_check", response.data);
        })
        .catch(error => console.log(error));
    },
    ST_password_update({ commit }, { password }) {
      Axios.post(`${config.publicPath}` + "setting/password/update", {
        password
      })
        .then(response => {
          commit("set_setting_msg", response.data);
          commit("SETmessage", response.data);
          setTimeout(() => {
            commit("set_setting_msg", "");
            commit("SETmessage", "");
          }, 2000);
        })
        .catch(error => console.log(error));
    }
  },
  getters: {
    get_privacy: state => {
      return state.privacy;
    },
    get_st_search: state => {
      return state.search_history;
    },
    get_st_msg: state => {
      return state.msg_set;
    },
    get_username_frame: state => {
      return state.username_frame;
    },
    get_email_frame: state => {
      return state.email_frame;
    },
    get_phone_frame: state => {
      return state.phone_frame;
    },
    get_password_check: state => {
      return state.password_check;
    }
  }
};
export default settings;
