import config from "@/api.js";
import Axios from "axios";
//import Date from 'date-and-time';
const getDefaultState = () => {
  return {
    api: "",
    api_error: false,
    internet_connection: true
  };
};
const api = {
  state: getDefaultState(),
  mutations: {
    SET_api_error(state, data) {
      state.api_error = data;
    },
    SET_data(state, data) {
      state.api = data;
      var _o = data.connected;
      if (_o === true) {
        state.api_error = false;
      }
    },
    SET_ic(state, data) {
      state.internet_connection = data;
      // console.log(data);
    },
    RESET: state => {
      Object.assign(state, getDefaultState());
    }
  },
  actions: {
    Checkapi({ commit }) {
      Axios.get(`${config.publicPath}` + "check")
        .then(response => {
          commit("SET_data", response.data);
        })
        .catch(error => {
          commit("SET_api_error", true);
          console.log(error);
        });
    }
  },
  getters: {
    getInitialLiner(state) {
      return state.api;
    },
    getapi_error: state => {
      return state.api_error;
    },
    getic: state => {
      return state.internet_connection;
    }
  }
};
export default api;
