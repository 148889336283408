import config from "@/api.js";
import Axios from "axios";
const getDefaultState = () => {
  return {
    msg: ""
  };
};
const lifeline_subject = {
  state: getDefaultState(),
  mutations: {
    SET_msgl(state, data) {
      state.msg = data;
    },
    RESET: state => {
      Object.assign(state, getDefaultState());
    }
  },
  actions: {
    Insertpast({ commit }, { name, type }) {
      Axios.post(`${config.publicPath}` + "insert/past", { name, type })
        .then(response => {
          commit("SET_msgl", response.data);
          commit("SETmessage", response.data);
          setTimeout(() => {
            commit("SET_msgl", "");
            commit("SETmessage", "");
          }, 2000);
        })
        .catch(error => console.log(error));
    },
    Insertpresent({ commit }, { name, type }) {
      Axios.post(`${config.publicPath}` + "insert/present", { name, type })
        .then(response => {
          commit("SET_msgl", response.data);
          commit("SETmessage", response.data);
          setTimeout(() => {
            commit("SET_msgl", "");
            commit("SETmessage", "");
          }, 2000);
        })
        .catch(error => console.log(error));
    },
    Insertfuture({ commit }, { name, type }) {
      Axios.post(`${config.publicPath}` + "insert/future", { name, type })
        .then(response => {
          commit("SET_msgl", response.data);
          commit("SETmessage", response.data);
          setTimeout(() => {
            commit("SET_msgl", "");
            commit("SETmessage", "");
          }, 2000);
        })
        .catch(error => console.log(error));
    },
    Insertsubject({ commit }, { name, type, meaning }) {
      Axios.post(`${config.publicPath}` + "insert/subject", {
        name,
        type,
        meaning
      })
        .then(response => {
          commit("SET_msgl", response.data);
          commit("SETmessage", response.data);
          setTimeout(() => {
            commit("SET_msgl", "");
            commit("SETmessage", "");
          }, 2000);
        })
        .catch(error => console.log(error));
    }
  },
  getters: {
    getlmsg: state => {
      return state.msg;
    }
  }
};
export default lifeline_subject;
