const getDefaultState = () => {
  return {
    date: [],
    year: [],
    country: [],
    month_length: null
  };
};
const dates = {
  state: getDefaultState(),
  mutations: {
    SET_year(state) {
      var l = 70;
      var point = 1940;
      for (var i = 0; i < l; i++) {
        var access = Math.round(point + i);
        state.year.push(access);
      }
    },
    SET_date(state, data) {
      state.date = [];
      if (
        data.month == 1 ||
        data.month == 3 ||
        data.month == 5 ||
        data.month == 7 ||
        data.month == 8 ||
        data.month == 10 ||
        data.month == 12
      ) {
        state.month_length = 32;
      } else if (data.month == 2) {
        var reminder = data.year % 4;
        if (reminder == 0) {
          state.month_length = 30;
        } else {
          state.month_length = 29;
        }
      } else {
        state.month_length = 31;
      }
      for (var i = 1; i < state.month_length; i++) {
        var access = Math.round(i);
        state.date.push(access);
      }
    },
    RESET: state => {
      Object.assign(state, getDefaultState());
    }
  },

  getters: {
    getyear: state => {
      return state.year;
    },
    getdate: state => {
      return state.date;
    },
    getcountry: state => {
      return state.country;
    }
  }
};
export default dates;
