import { render, staticRenderFns } from "./measures.vue?vue&type=template&id=6decccde&"
import script from "./measures.vue?vue&type=script&lang=js&"
export * from "./measures.vue?vue&type=script&lang=js&"
import style0 from "./measures.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports