const getDefaultState = () => {
  return {
    requestlength: {
      grewtale: 10,
      home: 10,
      userGrewtale: 10,
      userPast: 10,
      userPresent: 10,
      userFuture: 10,
      searchUser: 8,
      searchGrewtale: 8,
      searchPast: 8,
      searchPresent: 8,
      searchFuture: 8,
      searchWord: 8,
      comment: 8,
      message: 10,
      messageUser: 8
    },
    plotters: [
      { id: 1, sub: "Creative" },
      { id: 2, sub: "Demandable" },
      { id: 3, sub: "Excitable" },
      { id: 4, sub: "Experienceable" },
      { id: 5, sub: "Futuristic" },
      { id: 6, sub: "Helpful" },
      { id: 7, sub: "Innovative" },
      { id: 8, sub: "Knowledgeable" }
    ],
    number: [
      { id: 1 },
      { id: 2 },
      { id: 3 },
      { id: 4 },
      { id: 5 },
      { id: 6 },
      { id: 7 },
      { id: 8 },
      { id: 9 },
      { id: 10 }
    ],
    relations: [
      { id: 1, sub: "Family" },
      { id: 2, sub: "friend" },
      { id: 3, sub: "Knowing" },
      { id: 4, sub: "Colleaque" },
      { id: 5, sub: "Competitor" },
      { id: 6, sub: "Neighbor" }
    ],
    Standard: [
      { id: 1, value: "Low", short: "L" },
      { id: 2, value: "Medium", short: "M" },
      { id: 3, value: "High", short: "H" }
    ],

    Privacy: [
      { id: 1, value: "Public" },
      { id: 2, value: "Private" }
    ],
    comments: [
      { id: 1, value: "Comments", r: "comment" },
      { id: 2, value: "Ideas", r: "suggestions_ideas" },
      { id: 3, value: "Q&A", r: "q_a" }
    ],
    Report: {
      grewtale: [
        { id: 1, value: "Sexual content" },
        { id: 2, value: "Harmful or dangerous act" },
        { id: 3, value: "Hateful or abusive content" },
        { id: 4, value: "Violent or repulsive content" },
        { id: 5, value: "Spam or misleading" }
      ],
      comment: [
        { id: 1, value: "Unwanted commercial content or spam" },
        { id: 2, value: "Pornography or sexually explicit material" },
        { id: 3, value: "Child abuse" },
        { id: 4, value: "Hate speech or graphic violence" },
        { id: 5, value: "Harassment or bullying" }
      ]
    }
  };
};

const plotters = {
  state: getDefaultState(),

  getters: {
    getplotters: state => {
      return state.plotters;
    },
    getrelations: state => {
      return state.relations;
    },
    getstandard: state => {
      return state.Standard;
    },
    getprivacy: state => {
      return state.Privacy;
    },
    getnumber: state => {
      return state.number;
    },
    getcomments: state => {
      return state.comments;
    },
    getreport: state => {
      return state.Report;
    },
    getReqLength: state => {
      return state.requestlength;
    }
  }
};
export default plotters;
