const getDefaultState = () => {
  return {
    last_visitors: [],
    initials: ""
  };
};
const last_visitors = {
  state: getDefaultState(),
  mutations: {
    Loadfastlogin(state, data) {
      state.last_visitors.push(data);
    },
    delete_last_visitors(state, data) {
      var index = data.index;

      state.last_visitors.splice(index, 1);
    },

    Set_initial_asset(state, data) {
      state.initials = data;
    },
    SET_initial_null(state) {
      state.initials = "";
    }
  },

  getters: {
    getlogin: state => {
      return state.last_visitors;
    },
    getinitials: state => {
      return state.initials;
    }
  }
};
export default last_visitors;
