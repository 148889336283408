// Fyissh inc. Project

import config from "@/api.js";
import Axios from "axios";
import {
  grewtaleDataFormation,
  grewtaleMeasureAction,
  grewtaleContentSet
} from "@/js/grewtale.js";
const getDefaultState = () => {
  return {
    grewtales: [],
    grewtalesNext: null,
    grewtale: null,
    singlegrewnothing: false,
    grew_length: 0,
    grewn: false,
    a1: false,
    a2: false,
    a3: false
  };
};

const grewtale_c = {
  state: getDefaultState(),
  mutations: {
    GET_Grewtales_c(state, data) {
      if (data.data === "nothing") {
        state.grewn = true;
      } else {
        state.grew_length = state.grew_length + data.data.length;

        if (data.next === null) state.grewn = true;
        state.grewtalesNext = data.next;
        data = data.data;
        for (var i = 0; i < data.length; i++) {
          var obj = grewtaleDataFormation(data[i]);

          state.grewtales.push(obj);
        }
      }
    },

    //SET at First position

    GET_Grewtalesingle(state, data) {
      state.singlegrewnothing = false;
      if (data === "Nothing" || data === "nothing" || data === "Error") {
        state.singlegrewnothing = true;
      } else {
        for (var i = 0; i < data.length; i++) {
          var obj = grewtaleDataFormation(data[i]);
          state.grewtale = obj;
        }
      }
    },
    SET_Plotters_inside_single(state, { id, count }) {
      if (state.grewtale) {
        if (id === state.grewtale.id) {
          var actual_count = count + 1;
          var Active = state.grewtale.Active;
          var btn = state.grewtale.plotters[count].b;

          if (Active > 0 && Active < 9) {
            if (state.grewtale.Active === actual_count) {
              state.grewtale.plotters[count].b = btn - 1;
              state.grewtale.Active = 0;
            } else {
              state.grewtale.plotters[count].b = btn + 1;
              state.grewtale.plotters[Active - 1].b =
                state.grewtale.plotters[Active - 1].b - 1;
              state.grewtale.Active = actual_count;
            }
          } else {
            state.grewtale.plotters[count].b = btn + 1;
            state.grewtale.Active = actual_count;
          }
        }
      }
    },
    SET_Plotters_insideout_single(state, { id, count }) {
      if (state.grewtale) {
        if (id === state.grewtale.id) {
          // var Active = state.grewtales[index].Active;
          var btn = state.grewtale.plotters[count].b;
          state.grewtale.plotters[count].b = btn - 1;
          state.grewtale.Active = 0;
        }
      }
    },
    setGrewtaleContent(state, data) {
      grewtaleContentSet(state, data, "grewtales");
      if (state.grewtale !== null) {
        state.grewtale.content = data.content;
        state.grewtale.fullContentAvailibility = 1;
      }
    },
    SET_Plotters_inside(state, { id, count, process }) {
      grewtaleMeasureAction(state, id, count, "grewtales", process);
    },

    RESETcommentgrew(state) {
      state.grewtales = [];
      state.grewtalesNext = null;
      state.grew_length = 0;
      state.grewn = false;
    },
    RESETsinglegrew(state) {
      state.grewtale = [];
      state.singlegrewnothing = false;
    },
    Setfile_active_c(state, _data) {
      state.grewtales[_data.dynamic].file_active = true;
    },

    ca2(state, data) {
      state.a2 = data;
    },
    ca3(state, data) {
      state.a3 = data;
    },
    SEThogredel_c(state, data) {
      if (state.grewtale !== null && state.grewtale.id === data) {
        state.grewtale = null;
        state.singlegrewnothing = true;
      }
      for (var i = 0; i < state.grewtales.length; i++) {
        if (state.grewtales[i].id === data) {
          state.grewtales.splice(i, 1);
          break;
        }
      }
    },
    RESET: state => {
      Object.assign(state, getDefaultState());
    }
  },
  actions: {
    loadGrewtalesid({ commit, state }, { items, p_id }) {
      if (state.a2 === false) {
        commit("ca2", true);
        var length = 8,
          next = state.grewtalesNext;
        Axios.post(`${config.publicPath}` + "get/grewtale/p", {
          items,
          p_id,
          length,
          next
        })
          .then(response => {
            commit("GET_Grewtales_c", response.data);
          })
          .catch(error => console.log(error))
          .finally(() => {
            commit("ca2", false);
          });
      }
    },
    loadsinglegrewtale({ commit, state }, id) {
      if (state.a3 === false) {
        commit("ca3", true);

        Axios.post(`${config.publicPath}` + "get/last/post", { id })
          .then(response => {
            commit("GET_Grewtalesingle", response.data);
            // commit("GET_Grewtalesu1", response.data);
          })
          .catch(error => console.log(error))
          .finally(() => {
            commit("ca3", false);
          });
      }
    },
    plotters_c({ commit }, { index, id, count, active }) {
      Axios.post(`${config.publicPath}` + "plotter/inside", {
        index,
        id,
        count,
        active
      })
        .then(response => {
          commit("SET_Plotters", response.data);
          commit("SETmessage", response.data.msg);
          commit("SET_Plotters_inside_c", { index, id, count });
          setTimeout(() => {
            commit("SET_Plotters", "");
            commit("SETmessage", "");
          }, 1500);
        })
        .catch(error => console.log(error));
    },
    delete_plotters_c({ commit }, { index, id, count, active }) {
      Axios.post(`${config.publicPath}` + "plotter/remove", {
        index,
        id,
        count,
        active
      })
        .then(response => {
          commit("SETmessage", response.data);
          commit("SET_Plotters_insideout_c", { index, id, count });
          setTimeout(() => {
            commit("SET_Plotters", "");
            commit("SETmessage", "");
          }, 1500);
        })
        .catch(error => console.log(error));
    }
  },
  getters: {
    getgrewtales_c: state => {
      return state.grewtales;
    },
    getgrewtale_c: state => {
      return state.grewtale;
    },
    getgrewn_c: state => {
      return state.grewn;
    },
    getgrewlengthc: state => {
      return state.grew_length;
    },
    getsinglegrewnothing: state => {
      return state.singlegrewnothing;
    }
  }
};
export default grewtale_c;
