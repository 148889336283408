import config from "@/api.js";
const getDefaultState = () => {
  return {
    api: config.publicPath,
    cdn: config.cdnPath
  };
};
const links = {
  state: getDefaultState(),

  getters: {
    GETapilink: state => {
      return state.api;
    },
    GETcdnlink: state => {
      return state.cdn;
    }
  }
};
export default links;
