import config from "@/api.js";
import Axios from "axios";
const getDefaultState = () => {
  return {
    connectors: [],
    connectorsNext: null,
    msg: null,
    delete_msg: "",
    msg3: "",
    connectorno: false,
    a1: false
  };
};
const connectors = {
  state: getDefaultState(),
  mutations: {
    SET_Connectors(state, data) {
      if (data === "nothing") {
        state.connectorno = true;
      } else {
        if (data.next === null) state.connectorno = true;
        state.connectorsNext = data.next;
        data = data.data;
        for (var i = 0; i < data.length; i++) {
          var id = data[i].id;
          var first_name = data[i].first_name;
          var last_name = data[i].last_name;
          var profilepic = data[i].profilepic;
          var username = data[i].username;
          state.connectors.push({
            id: id,
            first_name: first_name,
            last_name: last_name,
            profilepic: profilepic,
            username: username
          });
        }
      }
    },
    remove_connectors(state, index) {
      // var id = state.connectors[index].id ;
      // var fulllength = state.connectors.length;
      // var lastlength = fulllength - (index + 1) ;
      // var prelength = index;

      state.connectors.splice(index, 1);
    },
    SET_msg1(state, data) {
      state.msg = data;
    },
    SET_msg2(state, data) {
      state.delete_msg = data;
    },

    SET_sucess(state, data) {
      if (state.msg) {
        state.request = data;
      }
    },
    connector_Status(state) {
      state.msg = null;
      state.msg3 = "";
    },
    suggestion_reset(state) {
      (state.connectors = []),
        (state.connectorsNext = null),
        (state.connectorno = false);
    },
    ca1(state, data) {
      state.a1 = data;
    },
    RESET: state => {
      Object.assign(state, getDefaultState());
    }
  },
  actions: {
    loadConnectors({ commit, state }, { id }) {
      if (state.a1 === false) {
        commit("ca1", true);
        var length = 8,
          next = state.connectorsNext;

        Axios.post(`${config.publicPath}` + "connectors/get", {
          id,
          length,
          next
        })
          .then(response => {
            commit("SET_Connectors", response.data);
          })
          .catch(error => console.log(error))
          .finally(() => {
            commit("ca1", false);
          });
      }
    },
    removeconnector({ commit }, { index }) {
      commit("remove_connectors", index);
    },
    Setrelation({ commit }, { id, relation_value }) {
      Axios.post(`${config.publicPath}` + "connectors/request", {
        id,
        relation_value
      })
        .then(response => {
          if (response.data.msg === "Requested") {
            commit("SET_msg1", 1);
          }
        })
        .catch(error => console.log(error));
    },
    Acceptrelation({ commit }, { id }) {
      Axios.post(`${config.publicPath}` + "connectors/accept", { id })
        .then(response => {
          if (response.data.msg === "Accepted") {
            commit("SET_msg1", 2);
          }
        })
        .catch(error => console.log(error));
    },
    Deleterelation({ commit }, { id1, id2 }) {
      Axios.post(`${config.publicPath}` + "connectors/delete", { id1, id2 })
        .then(response => {
          commit("SET_msg2", response.data);

          if (response.data === "Deleted") {
            commit("SET_msg1", 0);
          }
          setTimeout(() => {
            commit("SET_msg2", "");
          }, 1500);
        })
        .catch(error => console.log(error));
    }
  },
  getters: {
    getconnectors: state => {
      return state.connectors;
    },
    getconstatus: state => {
      return state.msg;
    },
    getcondeletemsg: state => {
      return state.delete_msg;
    },
    getconmsg3: state => {
      return state.msg3;
    },
    getconnectorno: state => {
      return state.connectorno;
    }
  }
};
export default connectors;
