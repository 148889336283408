module.exports = {
  realTimeQuerySetting(data) {
    var f = [];
    const trimQuery = data.trim();
    var subquery = trimQuery.split(" ");
    if (subquery.length === 1) f = subquery;
    else {
      for (var i = 0; i < subquery.length; i++) {
        var trimQueryObt = subquery[i].trim();
        f.push(trimQueryObt);
      }
    }
    return f;
  },
  whiteSpaceMaker(data) {
    var expes = /(&nbsp;+)/g;
    var f = data.replace(expes, " ");
    return f;
  },
  obConstruction(data) {
    if (data) {
      var t = data + "io";
    } else {
      t = "ol";
    }
    return t;
  }
};
