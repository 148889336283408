import config from "@/api.js";
import construction from "@/construction.js";
//import router from "@/router/index.js";
import Axios from "axios";
const getDefaultState = () => {
  return {
    bug: "",
    api: "",
    api_error: false,
    internet_connection: true,
    ch1: false,
    ch2: false,
    ch3: false,
    ch4: false,
    ch5: false,
    ch6: false,
    ch7: false,
    users: [],
    usersNext: null,
    pre_msg: [],
    messge_nothing: false,
    MsgRpy: "",
    MsgHlt: "",
    RpyMsgGot: "",
    activeindex: 0,
    RpyMsgpnl: "",
    inputheight: 40,
    sockethelper: "",
    usernothing: false,
    sortcount: 0
  };
};

const talk = {
  state: getDefaultState(),
  mutations: {
    //pre msgs

    SetPremsg(state, data) {
      state.pre_msg.push(data);
    },
    RmvPremsg(state, data) {
      state.pre_msg.splice(data, 1);
    },
    //pre msgs

    SetMsgRpy(state, data) {
      state.MsgRpy = data;
    },
    RmvMsgRpy(state) {
      state.MsgRpy = "";
    },
    SetRpyMsgPanel(state, data) {
      state.RpyMsgpnl = data;
    },
    //heightlight
    SetMsgHlt(state, data) {
      state.MsgHlt = data;
    },
    RmvMsgHlt(state) {
      state.MsgHlt = "";
    },
    //Reply get
    SetRpyGet(state, data) {
      state.users[state.activeindex].message[data.i].replymsg = data.data;
      state.RpyMsgGot = data.data;
    },

    //chat_sets

    SET_chat(state, data) {
      const time = construction.timer(data.created_at);
      (data.fromSelf = true),
        (data.send = false),
        (data.error = false),
        (data.seen = false),
        (data.id = ""),
        (data.day = time.response.day);
      data.time = time.response.time;
      state.users[state.activeindex].message.unshift(data);
    },
    ResMesApiErr(state) {
      for (var i = 0; i < state.users[state.activeindex].message.length; i++) {
        if (state.users[state.activeindex].message[i].id === "") {
          state.users[state.activeindex].message[i].error = true;
        }
      }
    },
    SocHelRmv(state) {
      state.sockethelper = "";
    },
    ResMesSet(state, data) {
      for (var j = 0; j < state.users.length; j++) {
        if (data.user2 == state.users.id) {
          for (var i = 0; i < state.users[j].message.length; i++) {
            if (data.selected_id === state.users[j].message[i].selected_id) {
              if (data.msg === "messaged") {
                state.users[j].message[i].send = true;
                state.users[j].message[i].id = data.id;

                //socket formation
                var _msg = state.users[j].message[i];
                var _socket = {
                  id: state.users[j].socket_id,
                  online: state.users[j].online
                };
                var _file = data.filename;
                const payload = { _msg, _socket, _file };
                state.sockethelper = payload;
              } else {
                state.users[j].message[i].error = true;
              }
              break;
            }
          }
        }
      }
    },
    SetGetChatData(state, data) {
      if (data.user_id == state.users[state.activeindex].id) {
        if (data.data === "nothing") {
          state.users[state.activeindex].nomoremessage = true;
        } else {
          if (data.next === null)
            state.users[state.activeindex].nomoremessage = true;
          state.users[state.activeindex].next = data.next;
          for (var i = 0; i < data.data.length; i++) {
            const payload = {
              file_type: data.data[i].file_type,
              file: data.data[i].file
            };
            const payload3 = data.data[i].sender;
            const payload4 = data.data[i].seen;
            const time = construction.timer(data.data[i].created_at);

            const pt = construction.chat_file(payload);
            const rt = construction._fromself(payload3);
            const _seen = construction._seen(payload4);

            data.data[i].send = true;
            data.data[i].error = false;
            data.data[i].seen = false;

            (data.data[i].file = pt.output),
              (data.data[i].reply = construction.replySet(data.data[i].reply)),
              (data.data[i].fromSelf = rt.fromSelf),
              (data.data[i].seen = _seen.response),
              (data.data[i].day = time.response.day),
              (data.data[i].time = time.response.time),
              state.users[state.activeindex].typing === false;
            state.users[state.activeindex].message.push(data.data[i]);
          }
        }
      }
    },
    SetGetChatUser(state, data) {
      if (data === "nothing") {
        state.usernothing = true;
      } else {
        if (data.next === null) state.usernothing = true;
        state.usersNext = data.next;
        data = data.data;
        for (var i = 0; i < data.length; i++) {
          var message = [];
          const payload = {
            file_type: data[i].file_type,
            file: data[i].file
          };
          const payload3 = data[i].sender;
          const payload4 = data[i].seen;
          const time = construction.timer(data[i].created_at);
          if (data[i].online_at !== null) {
            var _time = construction.timer(data[i].online_at);
          } else {
            _time = {
              response: {
                day: "",
                time: ""
              }
            };
          }
          const pt = construction.chat_file(payload);
          const rt = construction._fromself(payload3);
          const _seen = construction._seen(payload4);
          const c = {
            created_at: JSON.parse(data[i].created_at),
            message: data[i].message,
            id: data[i].message_id,
            iso: data[i].iso,
            status: data[i].status,
            reply: construction.replySet(data[i].reply),
            accelerator: data[i].accelerator,
            file: pt.output,
            file_type: data[i].file_type,
            url: data[i].url,
            fromSelf: rt.fromSelf,
            seen: _seen.response,
            day: time.response.day,
            time: time.response.time,
            send: true,
            error: false
          };
          message.unshift(c);
          state.users.push({
            id: data[i].id,
            first_name: data[i].first_name,
            last_name: data[i].last_name,
            profilepic: data[i].profilepic,
            online: data[i].online,
            socket_id: data[i].socket_id,
            typing: false,
            nomoremessage: false,
            message: message,
            day: _time.response.day,
            time: _time.response.time
          });
        }
      }
    },
    SetIndexId(state, data) {
      for (var i = 0; i < state.users.length; i++) {
        if (data == state.users[i].id) {
          state.activeindex = i;
          break;
        }
      }
    },
    SetIndexId2(state, data) {
      state.activeindex = data;
    },
    RemoveUser(state, data) {
      state.users.splice(data, 1);
    },
    SetNewToUsers(state, data) {
      var user = data[0];
      if (user.online_at !== null) {
        var _time = construction.timer(user.online_at);
      } else {
        _time = {
          response: {
            day: "",
            time: ""
          }
        };
      }
      (user.day = _time.response.day), (user.time = _time.response.time);
      user.message = [];
      user.typing = false;
      user.nomoremessage = false;

      state.users.push(user);
      state.activeindex = Math.round(state.users.length - 1);
    },
    // input height
    SETMsgInputHeight(state, data) {
      state.inputheight = data;
    },
    DltMsg(state, data) {
      for (var i = 0; i < state.users.length; i++) {
        if (state.users[i].id == data.user_id) {
          for (var j = 0; j < state.users[i].message.length; j++) {
            if (state.users[i].message[j].id == data.id) {
              state.users[i].message[j].status = "1";
              break;
            }
          }
          break;
        }
      }
    },
    my_seen_active(state, data) {
      state.users[data.u].message[data.i].seen = true;
    },
    chatR1(state, data) {
      state.ch1 = data;
      state.sortcount = state.sortcount + 1;
    },
    chatR2(state, data) {
      state.ch2 = data;
    },
    chatR3(state, data) {
      state.ch3 = data;
    },
    chatR4(state, data) {
      state.ch4 = data;
    },
    chatR5(state, data) {
      state.ch5 = data;
    },
    chatR6(state, data) {
      state.ch6 = data;
    },
    chatR7(state, data) {
      state.ch7 = data;
      state.sortcount = state.sortcount + 1;
    },
    RESET: state => {
      Object.assign(state, getDefaultState());
    },

    //real time set:-

    SOCKET_user_connect(state, data) {
      for (var i = 0; i < state.users.length; i++) {
        if (data.user_id == state.users[i].id) {
          state.users[i].socket_id = data.id;
          state.users[i].online = 1;
          break;
        }
      }
    },
    SOCKET_user_disconnect(state, data) {
      for (var i = 0; i < state.users.length; i++) {
        if (data.userID == state.users[i].id) {
          var _time = construction.timer(Date.now());
          state.users[i].socket_id = null;
          state.users[i].online = 0;
          state.users[i].typing = false;
          state.users[i].day = _time.response.day;
          state.users[i].time = _time.response.time;
          break;
        }
      }
    },
    SOCKET_seen_msg_transfer(state, data) {
      var id = localStorage.getItem("user");

      if (data.userID == id) {
        for (let i = 0; i < state.users.length; i++) {
          if (data.seen_user == state.users[i].id) {
            for (let j = 0; j < state.users[i].message.length; j++) {
              if (data.msg_id === state.users[i].message[j].id) {
                state.users[i].message[j].seen = true;

                break;
              }
            }
          }
        }
      } else {
        for (let i = 0; i < state.users.length; i++) {
          if (data.userID == state.users[i].id) {
            for (let j = 0; j < state.users[i].message.length; j++) {
              if (data.msg_id === state.users[i].message[j].id) {
                state.users[i].message[j].seen = true;

                break;
              }
            }
          }
        }
      }
    },
    Set_realtime_msg(state, data) {
      var id = localStorage.getItem("user");
      if (data.user.id == id) {
        var fromSelf = true;
        for (let i = 0; i < state.users.length; i++) {
          if (data.userID == state.users[i].id) {
            data.msg.fromSelf = fromSelf;
            state.users[i].message.unshift(data.msg);

            break;
          }
        }
      } else {
        fromSelf = false;
        var length = 1;
        this.commit("Set_Msg_Count", length);

        var round = false;
        var payload = { file: data.msg.fileurl, file_type: data.msg.file_type };
        const pt = construction.chat_file(payload);
        data.msg.file = pt.output;
        for (let i = 0; i < state.users.length; i++) {
          if (data.user.id == state.users[i].id) {
            data.msg.fromSelf = fromSelf;
            state.users[i].message.unshift(data.msg);
            round = true;
            break;
          }
        }
        if (round === false) {
          data.msg.fromSelf = fromSelf;
          data.user.message = [];
          data.user.typing = false;
          data.user.nomoremessage = true;
          data.user.message.push(data.msg);
          state.users.unshift(data.user);
        }
      }
    },
    SOCKET_typing_send(state, data) {
      for (var i = 0; i < state.users.length; i++) {
        if (data.id == state.users[i].id) {
          state.users[i].typing = data.type;

          break;
        }
      }
    }
  },
  actions: {
    SOCKET_send_msg({ commit, state }, data) {
      if (state.ch7 === false) {
        commit("chatR7", true);
        commit("Set_realtime_msg", data);

        commit("chatR7", false);
      }
    },
    SetChatData({ commit, state }, { formData }) {
      if (state.ch1 === false) {
        commit("chatR1", true);

        Axios.post(`${config.cdnPath}` + "chat/message/put", formData)
          .then(response => {
            commit("ResMesSet", response.data);
          })
          .catch(error => {
            commit("ResMesApiErr", error);
          });
        commit("chatR1", false);
      }
    },
    GetChatData({ commit, state }, { user2, next }) {
      if (state.ch2 === false) {
        commit("chatR2", true);
        var length = 20;

        Axios.post(`${config.publicPath}` + "chat/message/get", {
          user2,
          length,
          next
        })
          .then(response => {
            commit("SetGetChatData", response.data);
          })
          .catch(error => console.log(error))
          .finally(() => {
            commit("chatR2", false);
          });
      }
    },
    GetChatUser({ commit, state }, { id }) {
      if (state.ch3 === false) {
        commit("chatR3", true);
        var length = 10,
          next = state.usersNext;
        Axios.post(`${config.publicPath}` + "chat/message/user", {
          id,
          length,
          next
        })
          .then(response => {
            commit("SetGetChatUser", response.data);
          })
          .catch(error => console.log(error))
          .finally(() => {
            commit("chatR3", false);
          });
      }
    },
    GetChatUserNew({ commit, state }, id) {
      if (state.ch4 === false) {
        commit("chatR4", true);

        Axios.post(`${config.publicPath}` + "chat/message/user/new", { id })
          .then(response => {
            commit("SetNewToUsers", response.data);
          })
          .catch(error => console.log(error))
          .finally(() => {
            commit("chatR4", false);
          });
      }
    },
    ChatMsgDlt({ commit, state }, { id, user_id }) {
      if (state.ch5 === false) {
        commit("chatR5", true);

        Axios.post(`${config.publicPath}` + "chat/message/delete", { id })
          .then(response => {
            if (response.data === "Deleted") {
              commit("DltMsg", { id, user_id });
            }
          })
          .catch(error => console.log(error))
          .finally(() => {
            commit("chatR5", false);
          });
      }
    }
  },
  getters: {
    gettalkusers(state) {
      return state.users;
    },
    gettalkusernothing(state) {
      return state.usernothing;
    },
    gettalkindex(state) {
      return state.activeindex;
    },
    getchat3(state) {
      return state.ch3;
    },
    getchat2(state) {
      return state.ch2;
    },
    getchat4(state) {
      return state.ch4;
    },
    getchat1(state) {
      return state.ch1;
    },
    getchat5(state) {
      return state.ch5;
    },
    getchat7(state) {
      return state.ch7;
    },
    getpremsg(state) {
      return state.pre_msg;
    },
    getsortCount(state) {
      return state.sortcount;
    },
    getMsgRpy(state) {
      return state.MsgRpy;
    },
    getMsgHlt(state) {
      return state.MsgHlt;
    },
    getRpyMsgGot(state) {
      return state.RpyMsgGot;
    },
    getRpyMsgpnl(state) {
      return state.RpyMsgpnl;
    },
    getMsgInputheight(state) {
      return state.inputheight;
    },
    getMsgNot(state) {
      return state.messge_nothing;
    },
    getsockethelper(state) {
      return state.sockethelper;
    }
  }
};
export default talk;
