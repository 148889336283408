<template>
  <div>
    <div class="">
      <div class="fy-entry">
        <div
          v-if="progresspath === true"
          class="psf fy-top_0 fy-width_100p fy-progressive_bar fy-zi_4"
        >
          <vue-ins-progress-bar></vue-ins-progress-bar>
        </div>
        <api_error v-if="getapi_error === true"></api_error>
        <maintenance v-else-if="maintenance === true"></maintenance>
        <div v-else>
          <div><router-view /></div>
          <hover />
        </div>
      </div>
    </div>
    <div v-if="GETscreen === 'desktop'">
      <desktopCss />
    </div>
    <div v-else>
      <mobileCss />
    </div>
  </div>
</template>
<style>
@media (max-width: 1024px) {
  .fy_main_volume {
    margin-top: 16px;
  }
}
@media (min-width: 767px) and (max-width: 1895px) {
  .fy_main_volume {
    margin-top: 15px;
  }
  .fy-progressive_bar {
    height: 3px;
    box-shadow: 0px -1px 2px inset var(--fy-space-rve-15);
  }
}
</style>

<script>
import api_error from "@/components/error/api_error.vue";
import maintenance from "@/components/error/maintenance.vue";
import hover from "@/components/hover.vue";
import mobileCss from "@/components/css/mobile.vue";
import desktopCss from "@/components/css/desktop.vue";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      progress: false,
      progresspath: false,
      prevRoute: null,
      maintenance: false,
      point: 0
    };
  },
  components: {
    api_error,
    hover,
    desktopCss,
    mobileCss,
    maintenance
  },
  computed: {
    ...mapGetters([
      "getapi_error",
      "GETscreen",
      "GETwidth",
      "GETfade",
      "GETexternalInput",
      "GETSearchpanel",
      "GETinternetConnection",
      "getInitialLiner",
      "GETheight"
    ])
  },

  methods: {
    re() {
      window.scroll(0, 0);
    },
    resizeact() {
      this.$store.commit("screendetector");
    },

    async crypto(a) {
      try {
        const encrypt = {
          nonce: a,
          data: a
        };
        this.$store.commit("SETencrypt", encrypt);

        var links = document.getElementsByTagName("link");
        for (var i = 0; i < links.length; i++) {
          links[i].setAttribute("data-hashed", a);
          links[i].setAttribute("crossorigin", "anonymous");
          links[i].setAttribute("async", "1");
          links[i].setAttribute("nonce", a);
        }
        var styles = document.getElementsByTagName("style");
        for (i = 0; i < styles.length; i++) {
          styles[i].setAttribute("nonce", a);
        }
        var scripts = document.getElementsByTagName("script");
        for (i = 0; i < scripts.length; i++) {
          scripts[i].setAttribute("nonce", a);
        }
      } catch (e) {
        console.log(e);
      }
    }
  },

  created() {
    // localStorage.setItem("Management", Date.now());
    this.$store.dispatch("Checkapi");
    this.resizeact();

    this.$store.commit("SET_base_url");
    this.$insProgress.start();
  },
  mounted() {
    this.$router.beforeEach((to, from, next) => {
      // if (this.GETexternalInput && this.GETscreen === "mobile") {
      //   this.$store.commit("SETexternalInputBackController");
      //
      //  return false;
      // } else {
      //   if (
      //    this.GETSearchpanel === true &&
      //    this.GETscreen === "mobile" &&
      //    to.name === "User"
      //   )
      //     this.$store.commit("SET_Search_panel");
      //   if (this.GETscreen === "desktop") {
      //     this.$store.commit("fadeController", { c: true, route: this.$route });
      //   }
      this.$insProgress.start();
      this.progresspath = true;
      next();
      //  }
    });

    this.$router.afterEach(() => {
      this.$insProgress.finish();
      setTimeout(() => {
        if (this.GETscreen === "desktop") {
          this.$store.commit("fadeController", { c: false });
        }
      }, 50);
      setTimeout(() => {
        this.progresspath = false;
      }, 500);
    });

    this.$insProgress.finish();
    window.onresize = () => {
      this.resizeact();
    };
    window.onmousemove = e => {
      if (this.GETscreen === "desktop") {
        this.$store.commit("MousePositionSet", { y: e.clientY, x: e.clientX });
      }
    };
    window.onmousedown = e => {
      this.$store.commit("MouseClickSet", { y: e.clientY, x: e.clientX });
    };
    window.onclick = e => {
      this.$store.commit("SETClick", e);
    };
  },
  watch: {
    $route() {
      this.re();
    },

    getInitialLiner() {
      if (this.getInitialLiner) {
        if (this.getInitialLiner.maintenance === true) {
          this.maintenance = true;
        } else {
          this.crypto(this.getInitialLiner.crypto.nonce);
        }
      }
    }
  },
  beforeDestroy() {}
};
</script>
